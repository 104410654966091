import axios from 'axios';

export const apiUrl = (apiMethod) => {
  return process.env.REACT_APP_SERVER_URL + "/api/v1/" + apiMethod;
}

export const authUrl = (apiMethod) => {
  return process.env.REACT_APP_SERVER_URL + "/api/v1/" + apiMethod;
}

export const WSUrl = () => {
  return process.env.REACT_APP_SERVER_URL.replace(/^http/, 'ws')
    + '/cable'
    + '?token=' + localStorage.getItem('give-campaign-manager:token');
}

export const getUrlParameter = (sParam, location) => {
  location = location || window.location.search;
  var sPageURL = decodeURIComponent(location.substring(1));
  var sURLVariables = sPageURL.split('&');
  for (let i = 0; i < sURLVariables.length; i++) {
    var sParameterName = sURLVariables[i].split('=');
    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined ? true : sParameterName[1];
    }
  }
  return false;
};

export const sendRequest = (properties) => {
  const token = localStorage.getItem('give-campaign-manager:token');
  const url = properties.auth ? authUrl(properties.method) : apiUrl(properties.method);
  const type = properties.type.toUpperCase();
  let loading = false;
  if (!properties.noLoad) {
    triggerEvent('addLoad');
    loading = true;
  }
  axios({
    url,
    method: type,
    data: type === 'GET' ? null : (properties.data || properties.formData),
    params: type === 'GET' ? properties.data : null,
    headers: token ? {
      'Authorization': 'Bearer ' + token,
    } : {},
    onUploadProgress: e => {
      if (properties.progress) {
        properties.progress(e.loaded / e.total);
      }
    }
  })
    .then(response => {
      if (loading) {
        triggerEvent('removeLoad');
        loading = false;
      }
      if (response.status >= 200 && response.status < 400) {
        if (properties.success) {
          properties.success(response.data);
        }
      }
    })
    .catch(error => {
      if (loading) {
        triggerEvent('removeLoad');
        loading = false;
      }
      if (error.response) {
        if (error.response.status === 401) {
          triggerEvent('unauthorized');
        }
        if (error.response.status === 403) {
          triggerEvent('forbidden');
        }
        if (properties.error) {
          properties.error(error.response.data);
        }
      } else if (error.request) {
        if (properties.error) {
          properties.error('');
        }
      } else {
        if (properties.error) {
          properties.error('');
        }
      }
    })
}

export const triggerEvent = (name, data) => {
  let event;
  if (window.CustomEvent) {
    event = new CustomEvent(name, {detail: data});
  } else {
    event = document.createEvent('CustomEvent');
    event.initCustomEvent(name, true, true, data);
  }
  document.dispatchEvent(event);
}

export const triggerNativeEvent = (elem, name) => {
  var event = document.createEvent('HTMLEvents');
  event.initEvent(name, true, false);
  elem.dispatchEvent(event);
}

export const triggerClick = (elem) => {
  var event = document.createEvent('MouseEvents');
  event.initMouseEvent('click',true,true,window,0,0,0,0,0,false,false,false,false,0,null);
  elem.dispatchEvent(event);
}

export const eventOn = (name, handler, parent) => {
  parent = parent || document;
  if (handler) {
    parent.addEventListener(name, handler);
  }
}

export const eventOff = (name, handler, parent) => {
  parent = parent || document;
  if (handler) {
    parent.removeEventListener(name, handler);
  }
}

export const validateEmail = (email) => {
  var re = /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export const isIOS = () => {
  if (/Android|Mipad/i.test(navigator.userAgent)) {
    return false;
  }
  return (
    (!window.MSStream && /iPad|iPhone|iPod/i.test(navigator.userAgent)) ||
    ['iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
}
