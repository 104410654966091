export default {
  section: "Personal Team",
  config: {
    method: "payees/",
    title: "Payees",
    objectName: "Payee",
    adminOnly: true,
    search: {
      country: {
        type: "select",
        title: "Country",
        request: "collections/countries/",
        clearable: true,
      },
      status: {
        type: "select",
        title: "Status",
        options: [
          { id: 'verified', name: 'Verified' },
        ],
      },
    },
  },
  properties: {
    id: {
      default: false,
      type: "text",
      title: "#",
      preset: true,
      width: "60px",
    },
    name: {
      type: "text",
      title: "Name",
      width: "auto",
    },
    bank_account_name: {
      type: "text",
      title: "Account name",
    },
    bank_account_number: {
      type: "text",
      title: "Bank account number",
    },
    bank_name: {
      type: "text",
      title: "Bank name",
    },
    bank_code: {
      type: "text",
      title: "Bank code",
    },
    bank_branch_code: {
      type: "text",
      title: "Branch code",
    },
    bank_proof_document: {
      type: "file",
      external: true,
      title: "Proof document",
    },
    bank_swift_code: {
      type: "text",
      title: "SWIFT code",
    },
    country_other: {
      type: 'text',
      title: 'Other country',
    },
    country_id: {
      type: "select",
      title: "Country",
      request: "collections/countries/",
    },
    contact_email_1: {
      title: "Contact email 1",
      type: "text"
    },
    contact_email_2: {
      title: "Contact email 2",
      type: "text"
    },
    verified: {
      type: "checkbox",
      title: "Verified",
    },
  }
};
