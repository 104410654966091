import React from 'react';
import update from 'immutability-helper';
import { EditorState, convertToRaw, ContentState, Modifier } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import classnames from 'classnames';

import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import ImageUploader from './ImageUploader.js';
import VideoUploader from './VideoUploader.js';

import '../../sass/components/input/WysiwygInput.scss';

const DEFAULT_TOOLBAR_OPTIONS = {
  options: ['inline', 'link', 'list'],
  inline: {
    options: ['bold', 'italic', 'strikethrough'],
  },
  link: {
    options: ['link'],
  },
  list: {
    options: ['unordered', 'ordered'],
  },
};

const CUSTOM_TOOLBAR_OPTIONS = {
  options: ['blockType'],
  blockType: {
    inDropdown: false,
    options: ['H1', 'Blockquote'],
  },
};

class WysiwygInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      value: this.parseValue(props.object),
      uploadedImages: [],
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.object && !prevProps.object) {
      this.setState({
        value: this.parseValue(this.props.object),
      })
    }
  }

  focusEditor = () => {
    if (this.editor) {
      this.editor.focusEditor();
      console.log("1. Editor has the focus now");
    }
  };

  sendTextToEditor = (text) => {
    this.setState({value: this.insertText(text, this.state.value)});
    this.focusEditor();
  }

  insertText = (text, editorState) => {
    const currentContent = editorState.getCurrentContent(),
          currentSelection = editorState.getSelection();

    const data = text

    let { contentBlocks, entityMap } = htmlToDraft(data);

    const newContent = Modifier.replaceWithFragment(
        currentContent,
        currentSelection,
       ContentState.createFromBlockArray(contentBlocks, entityMap).getBlockMap()
     )

    const newEditorState = EditorState.push(editorState, newContent, 'insert-fragment');
    return EditorState.forceSelection(newEditorState, newContent.getSelectionAfter());
  }

  parseValue = (value) => {
    if (!value) {
      return EditorState.createEmpty();
    }
    if (typeof value === 'string') {
      return htmlToState(value);
    } else {
      return value;
    }
  }

  handleChange = (editorState) => {
    this.setState({value: editorState});
    this.props.onChange(this.props.objectKey, editorState);
  }

  onChangeImageHandler = (val) => {
    const videoFormats = ['mp3', 'mp4', 'webm']
    if (videoFormats.includes(val.replace(/\?.*$/, '').split('.').pop())) {
      this.sendTextToEditor(`<p></p><iframe src="${val}"></iframe><p></p>`)
    } else {
      this.sendTextToEditor(`<p></p><img src="${val}"/><p></p>`)
    }
  }

  extractYoutubeId = (url) => {
    let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
    let match = url.match(regExp);
    return match ? match[2] : null;
  }

   extractVimeoId = (url) => {
    let regExp = /^.+vimeo.com\/(.*\/)?([^#?]*)/;
    let match = url.match(regExp);
    return match ? match[2] || match[1] : null;
  }

  onChangeVideoHandler = (val) => {
    if (val.includes('cloudinary')) {
      this.sendTextToEditor(`<p></p><iframe src="${val}"></iframe><p></p>`)
    } else if (val.includes('youtube')) {
      let youtubeId = this.extractYoutubeId(val)
      let src=`https://www.youtube.com/embed/${youtubeId}?rel=0`;
      this.sendTextToEditor(`<p></p><iframe src="${src}"></iframe><p></p>`)
    } else if (val.includes('vimeo')) {
      let vimeoId = this.extractVimeoId(val);
      let src=`https://player.vimeo.com/video/${vimeoId}?color=ec7070&portrait=0`;
      this.sendTextToEditor(`<p></p><iframe src="${src}"></iframe><p></p>`)
    }
  }

  render = () => {
    const properties = this.props.properties;
    const preview = properties.preview;
    const customToolbar = properties.customToolbar;
    const imageUploadRequest = (properties.imageUploadRequest || '').replace(':id', this.props.parentObject.id);

    return (
      <div className='wysiwygInput'>
        <Editor
          editorState={this.state.value}
          toolbarHidden={properties.aggregated}
          toolbarCustomButtons={customToolbar &&
            [
              <ImageUploader
                onChangeImage={this.onChangeImageHandler}
                documents={this.props.parentObject.documents}
                imageUploadRequest={imageUploadRequest}
                preview={preview}
              />,
              <VideoUploader
                onChangeVideo={this.onChangeVideoHandler}
              />,
            ]
          }
          toolbar={
            update(customToolbar ? CUSTOM_TOOLBAR_OPTIONS : DEFAULT_TOOLBAR_OPTIONS, {
              $merge: properties.toolbar || {}
            })
          }
          readOnly={properties.disabled}
          wrapperClassName='wysiwygWrapper'
          toolbarClassName='wysiwygToolbar'
          editorClassName={classnames({
           'wysiwygEditor': true,
           'disabled': properties.disabled,
         })}
          placeholder={properties.placeholder}
          onEditorStateChange={this.handleChange}
        />

      </div>
    )
  }
}

export default WysiwygInput;

export const removeStyles = (value) => {
  let regExp = /height="[^"]*"|width="[^"]*"|alt=".*"|style=".*"|frameBorder=".*"/g
  let match = value.match(regExp);
  if (match) {
    match.map(function(val){
      value = value.replace(val, '');
      return value;
    });
  }
  return value;
}

export const handleWysiwygElements = (value) => {
  value = value.replaceAll('<p></p>','');
  value = value.replaceAll('<h1>','<div class="header">').replaceAll('</h1>','</div>');
  value = value.replaceAll(/<blockquote>/g,'<div class="quote">').replaceAll('</blockquote>','</div>');
  value = value.replaceAll('<p>','<div class="text">').replaceAll(/<\/p>/g,'</div>');

  const imageMatch = value.match(/\w*(<div class="image">[ \t\r\n]*)?<img .*?>/g);
  if (imageMatch) {
    imageMatch
      .filter(val => val.indexOf('<div class="image">') < 0)
      .map(val => {
        value = value.replace(val, `<div class="image">${val}</div>`);
        return value;
      });
  }

  const videoMatch = value.match(/\w*(<div class="video">[ \t\r\n]*)?<iframe .*?><\/iframe>/g);
  if (videoMatch) {
    videoMatch
      .filter(val => val.indexOf('<div class="video">') < 0)
      .map(val => {
        if (val.includes('youtube')) {
          const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
          const match = val.match(regExp);
          const youtubeId = match ? match[2] : null;
          const src=`/youtube?${youtubeId}`;
          value = value.replace(val, `<div class="video"><iframe src="${src}"></iframe></div>`);
        } else {
          value = value.replace(val, `<div class="video">${val}</div>`);
        }
        return value;
    });
  }

  value = removeStyles(value)
  return value;
}

export const stateToHtml = (state) => {

  if (typeof state === 'string') {
    state = handleWysiwygElements(state);

    return state;
  } else if (state) {
    let draft = draftToHtml(convertToRaw(state.getCurrentContent()));

    draft = handleWysiwygElements(draft);
    return draft;
  } else {
    return '';
  }
}

export const htmlToState = (html) => {
  if (!html || html.length === 0) {
    return EditorState.createEmpty();
  }

  let headerMatch = html.match(/<div class="header">(.*?)<\/div>/g);
  let quoteMatch = html.match(/<div class="quote">(.*?)<\/div>/g);
  let videoMatch = html.match(/<iframe .*?><\/iframe>/g);
  let videoDivMatch = html.match(/<div class="video">(.*?)<\/div>/g);

  if (videoDivMatch) {
    videoDivMatch.map(function(val){
      let content = val.replace('<div class="video">','').replace('</div>','');
      html = html.replace(val, `${content}`);
      return html;
    });
  }

  if (headerMatch) {
    headerMatch.map(function(val){
      let content = val.replace('<div class="header">','').replace('</div>','');
      html = html.replace(val, `<h1>${content}</h1>`);
      return html;
    });
  }

  if (quoteMatch) {
    quoteMatch.map(function(val){
      let content = val.replace('<div class="quote">','').replace('</div>','');
      html = html.replace(val, `<blockquote>${content}</blockquote>`);
      return html;
    });
  }

  if (videoMatch) {
    videoMatch.map(function(val){
      if (val.includes('youtube')) {
        let src = val.match(/src="([^"]+)"/g)[0];
        let match = src.match(/^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/);
        let youtubeId = match ? match[2] : src.split('?')[1];
        src=`https://www.youtube.com/embed/${youtubeId}?rel=0`;
        html = html.replace(val, `<p></p><iframe src="${src}"></iframe><p></p>`);
      }
      return val;
    });
  }

  const contentBlock = htmlToDraft(html);
  if (contentBlock) {
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    return EditorState.createWithContent(contentState);
  } else {
    return EditorState.createEmpty();
  }
}
