import React from 'react';

import Storage from '../helpers/Storage';
import { sendRequest, triggerEvent } from '../helpers/global.js';
import { facebookLogin } from '../helpers/facebook';

import '../sass/components/LoginView.scss';

export default class LoginView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {
        email: '',
        password: '',
      },
      errors: {},
    };
  }

  loginAction = (data) => {
    localStorage.setItem('give-campaign-manager:token', data.access_token);
    Storage.setData('user', data.user);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    let data = this.state.data;
    let errors = {};
    if (!data.email) {
      errors.email = 'Please enter Email';
    }
    if (!data.password) {
      errors.password = 'Please enter Password';
    }
    if (Object.keys(errors).length > 0) {
      this.setState({errors});
      return;
    }
    sendRequest({
      method: 'auth/login',
      auth: true,
      type: 'POST',
      data: {
        email: data.email,
        password: data.password,
      },
      success: (data) => {
        if (data.access_token) {
          this.loginAction(data);
        }
      },
      error: (data) => {
        if (data && data.errors) {
          if (data.errors === 403) {
            triggerEvent('showSnackbar', [{text: 'Your Account is not yet Approved', type: 'error'}]);
          } else {
            this.setState({errors: data.errors});
          }
        }
      }
    });
  }

  authFacebook = () => {
    this.setState({errors: {}});
    triggerEvent('addLoad');
    facebookLogin()
      .then(response => {
        sendRequest({
          type: 'POST',
          method: 'auth/facebook',
          data: {
            name: response.name,
            email: response.email,
            facebook_uid: response.id,
          },
          success: (data) => {
            triggerEvent('removeLoad');
            if (data.access_token) {
              this.loginAction(data);
            }
          },
          error: (data) => {
            triggerEvent('removeLoad');
            if (data && data.errors) {
              if (data.errors === 403) {
                triggerEvent('showSnackbar', [{text: 'Your Account is not yet Approved', type: 'error'}]);
              } else {
                this.setState({errors: data.errors});
              }
            }
          }
        });
      })
      .catch((err) => {
        triggerEvent('removeLoad');
      })
  }

  renderInput = (key, title, type = 'text') => {
    return (
      <div key={key}>
        <label htmlFor={key}>{title}</label>
        <input
          type={type}
          autoComplete={key}
          placeholder={this.state.errors[key] || ''}
          value={this.state.errors[key] ? '' : this.state.data[key]}
          onChange={e => this.setState({data: {...this.state.data, [key]: e.target.value}})}
          onFocus={() => this.setState({errors: {...this.state.errors, [key]: null}})}
        />
      </div>
    )
  }

  render = () => {
    return (
      <div className='loginView'>
        <div className='loginForm'>
          <div className='logo'/>
          <div className='socialButtons'>
            <button
              className='facebook'
              onClick={this.authFacebook}
            >Sign up with Facebook</button>
          </div>
          <div className='orSeparator'>
            <span>or</span>
          </div>
          {this.renderInput('email', 'Email', 'text')}
          {this.renderInput('password', 'Password', 'password')}
          <button
            onClick={this.handleSubmit}
          >Enter with Email</button>
        </div>
      </div>
    );
  }
}
