import React from 'react';
import classnames from 'classnames';
import CSSTransition from 'react-transition-group/CSSTransition';
import { Device } from 'twilio-client';
import ActionCable from 'actioncable';

import SelectInput from '../input/SelectInput.js';
import languages from './SpeechLanguageSupport'

import { WSUrl, eventOn, eventOff, sendRequest } from '../../helpers/global';

import '../../sass/components/common/PhoneCallPopup.scss';

class PhoneCallPopup extends React.Component {
  constructor(props) {
    super();
    this.state = {
      hidden: !props.show,
      show: false,
      phone: props.phone || '',
      hidePhone: false,
      data: props.data || {},
      callback: null,
      deviceReady: false,
      calling: false,
      status: null,
      languageCode: languages[0].id,
      microphoneType: null,
      speakerType: null,
      microphoneTypes: [],
      speakerTypes: []
    };
    this.cable = null;
    this.subscription = null;
  }

  componentDidMount = () => {
    if (this.props.global) {
      eventOn('showPhoneCall', this.showPhoneCall);
    }
    sendRequest({
      type: 'GET',
      method: 'twilio/call_token',
      noLoad: true,
      success: (data) => {
        Device.setup(data);
        Device.error(error => {
          console.log(error);
          this.setState({status: 'filed'});
        });
        Device.ready(device => {
          this.setState({deviceReady: true});
        });
        Device.connect((connection) => {
          this.connectChannel(connection.mediaStream.callSid);
          this.setState({calling: true});
          this.setState({status: 'processing'});
        });
      },
      error: (data) => {
      }
    });
  }

  componentWillUnmount = () => {
    if (this.props.global) {
      eventOff('showPhoneCall', this.showPhoneCall);
    }
    this.disconnectChannel();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.global) {
      if (prevProps.phone !== this.props.phone) {
        this.setState({phone: this.props.phone});
      }
    }
    if (this.state.deviceReady && this.state.deviceReady !== prevState.deviceReady) {
      let outputDevices = [];
      let inputDevices = [];
      Device.audio.availableOutputDevices.forEach((device, id) => {
        outputDevices.push({ id: id, name: device.label })
      });
      Device.audio.availableInputDevices.forEach((device, id) => {
        inputDevices.push({ id: id, name: device.label })
      });
      this.setState({
        microphoneTypes: inputDevices,
        speakerTypes: outputDevices,
        microphoneType: 'default',
        speakerType: 'default',
      });
    }
  }

  connectChannel = (callSid) => {
    this.disconnectChannel();
    this.cable = ActionCable.createConsumer(WSUrl());
    this.subscription = this.cable.subscriptions.create({
      channel: 'TwilioCallChannel',
      identifier: callSid,
    }, {
      connected: () => {
        console.log(`Connected to call with ${callSid} sid`);
      },
      received: (data) => {
        if (data.action === 'connected') {
          this.setState({status: 'connected'});
        }
      },
    });
  }

  disconnectChannel = () => {
    if (this.cable) {
      if (this.subscription) {
        this.subscription.unsubscribe();
        this.subscription = null;
      }
      this.cable.disconnect();
      this.cable = null;
    }
  }

  showPhoneCall = (e) => {
    const { callback = () => {}, phone = '', data = {}, hidePhone = false } = e.detail[0];
    this.setState({
      hidden: false,
      show: true,
      phone,
      hidePhone,
      data,
      callback,
    });
  }

  onOverlayClick = (e) => {
    /*if (e.target === e.currentTarget) {
      this.onCancel();
    }*/
  }

  onCancel = () => {
    if (this.props.global) {
      this.state.callback(false);
      this.setState({show: false});
    } else {
      this.props.callback(false);
    }
  }

  onCall = () => {
    if (this.state.languageCode) {
      const {data} = this.props.global ? this.state : this.props;
      Device.connect({
        ...data,
        phoneNumber: this.state.phone,
        languageCode: this.state.languageCode
      });
    }
  }

  onEndCall = () => {
    Device.disconnectAll();
    this.setState({ calling: false });
  }

  updateLanguageCode = (value) => {
    this.setState({ languageCode: value });
  }

  updateMicrophoneType = (value) => {
    this.setState({ microphoneType: value });
    Device.audio.setInputDevice(value);
  }

  updateSpeakerType = (value) => {
    this.setState({ speakerType: value });
    Device.audio.speakerDevices.set(value);
  }

  renderCalling = () => {
    return (
      <div className='popupWindow'>
        <div className='popupTitle'>Call fundraiser</div>
        <div className='callingStatus'>
          <div
            className={classnames({
              'callingStatusValue': true,
              'processing': this.state.status === 'processing',
              'success': this.state.status === 'success',
              'filed': this.state.status === 'filed',
            })}
          >{this.renderCallingStatus()}</div>
        </div>
        <div className='popupControls'>
          <div
            onClick={this.onEndCall}
            className='popupControl call endCall'
          >End call</div>
        </div>
      </div>
    )
  }

  renderCallingStatus = () => {
    if (this.state.status === 'processing') {
      return `Calling ${this.state.phone}...`;
    } else if (this.state.status === 'connected') {
      return 'Connected';
    } else if (this.state.status === 'filed') {
      return 'Call Failed';
    }
  }

  renderStartСall = () => (
    this.state.languageCode
      ? (
        <div
          onClick={this.onCall}
          className='popupControl call'
        >Call</div>
      ) : null
  );

  renderCallForm = () => {
    return (
      <div className='popupWindow'>
        <div className='closeButton' onClick={() => this.setState({ show: false })}/>
        <div className='popupTitle'>Call fundraiser</div>
        {this.state.deviceReady ?
          this.state.hidePhone ? null :
            <>
              <label>Phone number</label>
              <input
                value={this.state.phone}
                onChange={e => this.setState({phone: e.target.value})}
                placeholder='Phone number'
                className='phoneInput'
              />
            </>
        : null}
        <div className='popupControls'>
        <label>Language</label>
          <SelectInput
            object={this.state.languageCode}
            properties={{options: languages, placeholder: 'Conversation language'}}
            onChange={(k, value)  => this.updateLanguageCode(value)}
          />
          <div className='callSettings'>
            <div>
              <label>Microphone</label>
              <SelectInput
                object={this.state.microphoneType}
                properties={{options: this.state.microphoneTypes, placeholder: ''}}
                onChange={(k, value)  => this.updateMicrophoneType(value)}
              />
            </div>
            <div>
              <label>Speaker</label>
              <SelectInput
                object={this.state.speakerType}
                properties={{options: this.state.speakerTypes, placeholder: ''}}
                onChange={(k, value)  => this.updateSpeakerType(value)}
              />
            </div>
          </div>
          {this.state.deviceReady ? this.renderStartСall() : <span>Cannot call from this device</span>}
        </div>
      </div>
    )
  }

  render = () => {
    const { show } = this.props.global ? this.state : this.props;
    return (
      <div
        className={classnames({
          'phoneCallPopup': true,
          'hidden': this.state.hidden,
        })}
      >
        <CSSTransition
          in={show}
          timeout={350}
          classNames='fade'
          onEnter={() => this.setState({hidden: false})}
          onExited={() => this.setState({hidden: true})}
        >
          <div
            onClick={this.onOverlayClick}
            className='overlay'
          >
            {this.state.calling ? this.renderCalling() : this.renderCallForm()}
          </div>
        </CSSTransition>
      </div>
    )
  }
}

export default PhoneCallPopup;
