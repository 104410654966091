import React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { sendRequest, triggerEvent } from '../helpers/global.js';

import SelectInput from '../components/input/SelectInput.js';
import CheckboxInput from '../components/input/CheckboxInput.js';

import '../sass/components/DocumentsViewItem.scss';

const TYPES = [
  { id: 'medical', name: 'Medical' },
  { id: 'financial', name: 'Financial' },
  { id: 'media', name: 'Media' },
  { id: 'misc', name: 'Misc' },
]

const mapStoreToProps = (store) => {
  return {
    isMobile: store.setup.is_mobile,
  }
};

class DocumentsViewItem extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      fileName: '',
    }
    this.fileNameInput = null;
  }

  componentDidMount = () => {
  }

  onDelete = () => {
    const object = this.props.object;
    triggerEvent('showConfirmation', [{
      title: `Are you sure want to delete ${this.props.title} #${object.id}?`,
      confirmText: 'Delete',
      cancelText: 'Cancel',
      callback: confirm => {
        if (confirm && this.props.onDelete) {
          this.onDeleteConfirm();
        }
      }
    }]);
  }

  onDeleteConfirm = () => {
    const object = this.props.object;
    sendRequest({
      method: this.props.method + object.id,
      type: 'DELETE',
      success: (data) => {
        this.props.onDelete();
        triggerEvent('showSnackbar', [{text: `${this.props.title} deleted`, type: 'success'}]);
      },
      error: (data) => {
      }
    });
  }

  onChange = (key, value) => {
    const object = this.props.object;
    sendRequest({
      method: this.props.method + object.id,
      type: 'PUT',
      data: {
        [key]: value
      },
      success: (data) => {
        if (key === 'name') {
          this.props.onChange({
            ...object,
            name: data.name,
            file_url: data.file_url,
          });
        } else {
          this.props.onChange({...object, [key]: value});
        }
        triggerEvent('showSnackbar', [{text: 'Data saved', type: 'success'}]);
      },
      error: (data) => {
      }
    });
  }

  renderDate = () => {
    if (this.props.embedded) {
      return (
        moment.unix(this.props.object.created_at).format('DD.MM.YYYY HH:mm')
      )
    } else {
      return (
        moment.unix(this.props.object.created_at_formatted).format('DD.MM.YYYY HH:mm')
      )
    }
  }

  renderThumbnail  = () => {
    const { name, thumbnail_url }  = this.props.object;
    const url = () => {
      const pattern = /\.([0-9a-z]+)(?:[?#]|$)/i;
      const type = name && name.match(pattern);
      const static_types = ['pdf', 'xls', 'doc', 'mp4', 'mp3', 'txt', 'zip', 'xml'];
      const valid_types = ['jpeg', 'png', 'jpg', 'gif'];

      if (type && static_types.includes(type[1])) {
        return `/images/icons/${type[1]}.png`
      } else if (type && valid_types.includes(type[1])) {
        return thumbnail_url
      }
    };

    return (
      <div className='thumbnail' style={{backgroundImage: `url(${url()})`}}/>
    )
  }

  renderLead = () => {
    return (
      <div
        className='pageLink'
        onClick={() => this.props.history.push(`leads/${this.props.object.lead_list.id}`)}
      >{this.props.object.lead_list.name}</div>
    )
  }

  onClickDocument = (object) => {
    const imageTypes = ['jpeg', 'gif', 'png', 'jpg', 'svg'];
    const isImage = imageTypes.includes(object.file_url.replace(/\?.*$/, '').split('.').pop())
    if (isImage) {
      triggerEvent('openCarouselPopup', [{
        imageId: object.id,
        images: this.props.images
      }])
    } else {
      triggerEvent('openFramePopup', [{
        title: object.name,
        src: object.view_url
      }])
    }
  }

  renderFile = () => {
    const object = this.props.object;
    const name = object.name.substr(0, object.name.lastIndexOf('.'));
    const extention = object.name.substr(object.name.lastIndexOf('.'));
    if (this.state.edit) {
      return (
        <div className='fileCell'>
          <input
            type='text'
            value={this.state.fileName}
            onChange={e => this.setState({fileName: e.target.value})}
            ref={elem => this.fileNameInput = elem}
          />
          <div
            className='actionButton'
            onClick={() => this.setState({edit: false})}
          >
            <span className='material-icons'>close</span>
          </div>
          <div
            className='actionButton'
            onClick={() => {
              this.setState({edit: false});
              this.onChange('name', this.state.fileName + extention);
            }}
          >
            <span className='material-icons'>save</span>
          </div>
        </div>
      )
    } else {
      return (
        <div className='fileCell'>
          <div
            className='pageLink'
            onClick={() => {
              this.onClickDocument(object)
            }}
          >{object.name}</div>
          <div
            className='actionButton'
            onClick={() =>
              this.setState({edit: true, fileName: name}, () => {
                this.fileNameInput.focus();
              })
            }
          >
            <span className='material-icons'>create</span>
          </div>
          <div
            className='actionButton'
            onClick={() => window.open(object.file_url, '_blank')}
          >
            <span className='material-icons'>get_app</span>
          </div>
        </div>
      )
    }
  }

  renderFolder = () => {
    return (
      <SelectInput
        properties={{options: this.props.types || TYPES}}
        object={this.props.object.lead_document_type}
        onChange={(k, value) => this.onChange('lead_document_type', value)}
      />
    )
  }

  renderDelete = () => {
    return (
      <div className='actionButton' onClick={() => this.onDelete()}>
        <span className='material-icons'>delete</span>
      </div>
    )
  }

  renderCheckbox = () => {
    return (
      <CheckboxInput
        object={this.props.selected}
        onChange={(k, val) => this.props.onSelect(val)}
      />
    )
  }

  renderDesktop = () => {
    const object = this.props.object;
    if (this.props.embedded) {
      return (
        <tr className='documentsViewItem embedded'>
          <td width="50px">{this.renderCheckbox()}</td>
          <td width="150px">{this.renderDate()}</td>
          <td width="60px">{this.renderThumbnail()}</td>
          <td width="auto">{this.renderFile()}</td>
          {this.props.types && !this.props.types[0] ? null :
            <td width="190px">{this.renderFolder()}</td>
          }
          <td width="44px" style={{padding: 0}}>{this.renderDelete()}</td>
        </tr>
      )
    } else {
      return (
        <tr className='documentsViewItem'>
          <td><span className='textCell'>{object.id}</span></td>
          <td>{this.renderDate()}</td>
          <td>{this.renderLead()}</td>
          <td>{this.renderThumbnail()}</td>
          <td>{this.renderFile()}</td>
          <td>{this.renderFolder()}</td>
          <td style={{padding: 0}}>{this.renderDelete()}</td>
        </tr>
      )
    }
  }

  renderMobile = () => {
    const object = this.props.object;
    return (
      <div
        className={classnames({
          'documentsViewItem': true,
          'embedded': this.props.embedded,
          'card': true,
        })}
      >
        <table>
          <tbody>
            {this.props.embedded ?
              <tr>
                <td width='125px'></td>
                <td>{this.renderCheckbox()}</td>
              </tr>
              :
              <tr>
                <td width='125px'>#</td>
                <td>{object.id}</td>
              </tr>
            }
            <tr>
              <td width='125px'>Creation time</td>
              <td>{this.renderDate()}</td>
            </tr>
            {this.props.embedded ? null :
              <tr>
                <td width='125px'>Lead</td>
                <td>{this.renderLead()}</td>
              </tr>
            }
            <tr>
              <td width='125px'>File</td>
              <td>{this.renderFile()}</td>
            </tr>
            {this.props.embedded && this.props.types && !this.props.types[0] ? null :
              <tr>
                <td width='125px'>Folder</td>
                <td>{this.renderFolder()}</td>
              </tr>
            }
            <tr>
              <td width='125px'>Actions</td>
              <td style={{padding: 0}}>{this.renderDelete()}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  render = () => {
    if (this.props.isMobile) {
      return this.renderMobile();
    } else {
      return this.renderDesktop();
    }
  }
}

export default connect(mapStoreToProps)(withRouter(DocumentsViewItem));
