export default {

  // Notifications
  chats:            { section: "Notifications", config: { title: "Chats" } },
  notifications:    { section: "Notifications", config: { title: "Notifications" } },

  // Personal Team
  leads:            require('./leads.js').default,
  fundraisers:      require('./fundraisers.js').default,
  payouts:          require('./payouts.js').default,
  documents:        require('./documents.js').default,
  payees:           require('./payees.js').default,

  // Charity Team
  givikis:          require('./givikis.js').default,

  // Settings
  settings:         require('./settings.js').default,

  // Settings
  users:            require('./users.js').default,
  charities:        require('./charities.js').default,
  countries:        require('./countries.js').default,
  statistics:       require('./statistics.js').default,
  marketing_todos:  require('./marketing_todos.js').default,

  setting_changes:  { section: "Changes in settings", config: { title: "Changes in settings", adminOnly: true } },
}
