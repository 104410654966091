import React from 'react';
import '../../sass/components/input/WysiwygInput.scss';
import '../../sass/components/input/VideoUploader.scss';

class VideoUploader extends React.Component {
  constructor(props) {
   super();

   this.state = {
     show: false,
     embeddedLink: null,
   };
 }

 addVideoFromState = () => {
   this.props.onChangeVideo(this.state.embeddedLink);
   this.setState({show: false, embeddedLink: null});
 };

  doCollapse = () => {
    this.setState({show: false, embeddedLink: null});
  }

  showTab = () => {
    this.setState({show: true});
  }

  updateValue = event => {
    this.setState({embeddedLink: event.target.value});
  };

  rendeEmbeddedLinkModal = () => {
    return (
      <div
        className='rdw-embedded-modal'
      >
        <div className="rdw-embedded-modal-header">
          <span className="rdw-embedded-modal-header-option">
            Upload a Youtube or Vimeo video
          </span>
        </div>
        <div className="rdw-embedded-modal-link-section">
          <span className="rdw-embedded-modal-link-input-wrapper">
            <input
              className="rdw-embedded-modal-link-input"
              placeholder='Enter Link'
              onChange={this.updateValue}
              value={this.state.embeddedLink || ''}
              name="embeddedLink"
            />
          </span>
        </div>
        <span className="rdw-embedded-modal-btn-section">
          <button
            type="button"
            className="rdw-embedded-modal-btn"
            disabled={!this.state.embeddedLink}
            onClick={this.addVideoFromState}
          >
            Add
          </button>
          <button
            type="button"
            className="rdw-embedded-modal-btn"
            onClick={this.doCollapse}
          >
            Cancel
          </button>
        </span>
      </div>
    );
  };

  render() {
    return (
      <div
        aria-haspopup="true"
        aria-label="rdw-color-picker"
      >
        <div
          className='rdw-option-wrapper'
          onClick={this.showTab}
        >
        <img
          src='/images/videoIcon.svg'
          alt="videoIcon"
        />

        </div>
        {this.state.show ? this.rendeEmbeddedLinkModal() : null}
      </div>
    );
  }
}

export default VideoUploader;
