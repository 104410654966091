export default {
  section: "Settings",
  config: {
    method: "charities/",
    title: "Modify Adopting Charities",
    objectName: "Charity",
    disableAdd: true,
    disableDelete: true,
    adminOnly: true,
  },
  properties: {
    id: {
      default: false,
      type: "text",
      title: "#",
      preset: true,
      width: "60px",
    },
    created_at: {
      default: "",
      type: "datetime",
      title: "Created time",
      preset: true,
    },
    updated_at: {
      default: "",
      type: "datetime",
      title: "Modified Time",
      preset: true,
    },
    name: {
      type: "text",
      title: "Name",
      width: "auto",
    },
    todo: {
      default: [],
      type: "todo-list",
      disableCheck: true,
      title: "TODO list",
    },
    getting_started_template: {
      type: "wysiwyg",
      title: "Getting started template",
      toolbar: {
        options: ['inline', 'list'],
      }
    },
    completing_template: {
      type: "wysiwyg",
      title: "Completing template",
      toolbar: {
        options: ['inline', 'list'],
      }
    },
    default_case_supporter_id: {
      type: "select",
      title: "Default case supporter",
      request: "collections/users/",
      clearable: true,
    },
    default_case_manager_id: {
      type: "select",
      title: "Default case manager",
      request: "collections/users/",
      clearable: true,
    },
    rejection_reasons: {
      default: [],
      type: "todo-list",
      disableCheck: true,
      title: "Rejection reasons",
    },
    payee_ids: {
      default: [],
      type: "payees-list",
      disableCheck: true,
      title: "Payees",
    },
  }
};
