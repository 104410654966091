import React from 'react';
import classnames from 'classnames';

import '../../sass/components/input/CheckboxInput.scss';

export default class CheckboxInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleChange = (value) => {
    this.props.onChange(this.props.objectKey, value);
  }

  render = () => {
    const object = this.props.object;
    return (
      <div
        className={classnames({
          'checkboxInput': true,
          'small': this.props.small,
          'aggregatedLeadView': this.props.aggregatedLeadView,
        })}
      >
        <div
          className={`checkbox ${object ? 'checked' : ''}`}
          onClick={() => !this.props.disabled && this.handleChange(!object)}
        />
      </div>
    )
  }

}
