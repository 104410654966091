import React from 'react';

import FlexibleInput from './FlexibleInput.js';

import '../sass/components/GivikiFormInput.scss';

export default class GivikiFormInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  renderRequiredTitle = (title) => {
    return (
      `${title} <span class='required'>*</span>`
    )
  }

  render = () => {
    const { field, fieldKey, data, step, error, onChange } = this.props;
    let title = typeof field.title === 'function' ? field.title(data) : field.title;
    title = field.required ? this.renderRequiredTitle(title) : title
    const description = field.description;
    return (
      <div className={`givikiFormInput ${field.type}`}>
        {title ?
          <label dangerouslySetInnerHTML={{__html: title}}/>
        : null}
        {description ?
          <div className='fieldDescription' dangerouslySetInnerHTML={{__html: description}}/>
        : null}
        <FlexibleInput
          object={data[step][fieldKey]}
          parentObject={data[step]}
          onChange={onChange}
          objectKey={fieldKey}
          properties={field}
        />
        {error ?
          <div className='fieldError'>{error}</div>
        : null}
      </div>
    )
  }
}
