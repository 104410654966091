import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import './config';

import Storage from './helpers/Storage.js';
import App from './App';

import './sass/base.scss';
import './sass/animations.scss';

require('./css/index.css');
require('./css/material-icons.css');
require('./css/react-select.css');
require('react-datetime/css/react-datetime.css');
require('react-draft-wysiwyg/dist/react-draft-wysiwyg.css');

window.fbAsyncInit = () => {
  window.FB.init({
    appId      : process.env.REACT_APP_FACEBOOK_APP_ID,
    cookie     : true,
    xfbml      : true,
    version    : 'v5.0'
  });
};
(function(d, s, id) { // Load FB SDK asynchronously
  var js, fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) return;
  js = d.createElement(s); js.id = id;
  js.src = "https://connect.facebook.net/en_US/sdk.js";
  fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));

const init = async () => {
  try {
    await Promise.all([
      Storage.loadData('user'),
      Storage.loadData('participantData'),
    ])
  } catch (ex) {}
}

init().then(() => {
  ReactDOM.render(
    <Provider store={Storage.getStore()}>
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </Provider>,
    document.getElementById('content')
  )
});
