import React from 'react';
import { connect } from 'react-redux';

import { sendRequest, triggerEvent } from '../../helpers/global.js';

import DocumentsViewItem from '../DocumentsViewItem.js';
import SelectInput from '../input/SelectInput.js';
import CheckboxInput from '../input/CheckboxInput.js';

import '../../sass/components/input/DocumentsList.scss';

const mapStoreToProps = (store) => {
  return {
    isMobile: store.setup.is_mobile,
  }
};

const imageTypes = ['jpeg', 'gif', 'png', 'jpg', 'svg'];

class DocumentsList extends React.Component {

  constructor(props) {
    super(props);
    const types = props.properties ? props.properties.types : null;
    this.state = {
      types: types && types[0] ? types.slice() : null,
      selected: [],
      updateDocumentType: null,
    };
    this.input = null;
  }

  componentDidMount = () => {
  }

  updateData = (value) => {
    this.props.onChange(this.props.objectKey, value);
  }

  uploadFiles = (files) => {
    if (this.props.aggregatedLeadView) {
      triggerEvent('showUploadingPopup', [{ status: 'pending' }]);
    }
    let formData = new FormData();
    if (this.props.properties.uploadId) {
      formData.append(this.props.properties.uploadId, this.props.parentObject.id);
    }
    if (this.props.properties.marketing) {
      formData.append('marketing', this.props.properties.marketing);
    }
    if (this.state.types) {
      formData.append('lead_document_type', this.state.types[0].id);
    }
    files.forEach((file, i) => {
      formData.append(`files[${i}]`, file);
    });
    sendRequest({
      method: 'aggregated_documents/',
      type: 'POST',
      formData,
      success: (data) => {
        if (this.props.aggregatedLeadView) {
          triggerEvent('showUploadingPopup', [{ status: 'success' }]);
          setTimeout(() => {
            triggerEvent('hideUploadingPopup');
          }, 2000);
        }
        this.updateData([
          ...this.props.object,
          ...data,
        ])
      },
      error: (data) => {
        if (this.props.aggregatedLeadView) {
          triggerEvent('showUploadingPopup', [{ status: 'failed' }]);
          setTimeout(() => {
            triggerEvent('hideUploadingPopup');
          }, 2000);
        }
      }
    });
  }

  downloadDocuments = () => {
    const ids = this.state.selected.length > 0
      ? this.state.selected
      : (this.props.object || []).map(i => i.id);
    sendRequest({
      method: 'documents/download',
      type: 'GET',
      data: {
        ids,
      },
      success: (data) => {
        const link = document.createElement('a');
        link.href = data.url;
        link.download = true;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      error: (data) => {
      }
    });
  }

  deleteDocuments = () => {
    const ids = this.state.selected;
    sendRequest({
      method: 'documents',
      type: 'DELETE',
      data: {
        ids,
      },
      success: (data) => {
        this.updateData(
          this.props.object.filter(i => !ids.includes(i.id))
        )
      },
      error: (data) => {
      }
    });
  }

  updateDocuments = () => {
    const ids = this.state.selected;
    sendRequest({
      method: 'documents/document_type',
      type: 'PUT',
      data: {
        ids,
        lead_document_type: this.state.updateDocumentType,
      },
      success: (data) => {
        this.updateData(
          this.props.object.map(i => ({
            ...i,
            lead_document_type: ids.includes(i.id)
              ? this.state.updateDocumentType
              : i.lead_document_type
          }))
        );
      },
      error: (data) => {
      }
    });
  }

  renderAddButton = () => {
    return (
      <>
        {this.props.aggregatedLeadView
          ? <button
              className='uploadDocumentsButton'
              onClick={() => this.input.click()}
            >
              <div className='uploadIcon' />
              Upload documents
            </button>
          : <button
              className='addButton outline'
              onClick={() => this.input.click()}
            >
              Upload New
            </button>}
        <input
          type='file'
          multiple
          ref={input => this.input = input}
          onChange={e => this.uploadFiles([...e.target.files])}
        />
      </>
    )
  }

  renderDocument = (doc) => {
    const documents = (this.props.object || []);
    const images = documents.filter(doc => doc.file_url && imageTypes.includes(doc.file_url.replace(/\?.*$/, '').split('.').pop()));
    return (
      <DocumentsViewItem
        key={doc.id}
        embedded
        types={this.state.types || []}
        object={doc}
        images={images}
        method='documents/'
        title='Document'
        onChange={(updated) => {
          this.updateData(
            this.props.object.map(i => i.id === doc.id ? updated : i)
          )
        }}
        onDelete={() => {
          this.updateData(
            this.props.object.filter(i => i.id !== doc.id)
          )
        }}
        selected={this.state.selected.includes(doc.id)}
        onSelect={select =>
          this.setState({selected: select
            ? [...this.state.selected, doc.id]
            : this.state.selected.filter(i => i !== doc.id)
          })
        }
      />
    )
  }

  renderDownloadButton = () => {
    return (
      <button
        className='uploadLink outline'
        onClick={this.downloadDocuments}
      >
        Download {this.state.selected.length > 0 ? 'selected' : 'all'}
      </button>
    )
  }

  renderDeleteButton = () => {
    return (
      <button
        className='uploadLink outline'
        onClick={this.deleteDocuments}
      >
        Delete selected
      </button>
    )
  }

  renderUpdateButton = () => {
    return (
      <button
        className='uploadLink outline'
        onClick={() => {
          triggerEvent('showContentPopup', [{
            title: 'Update documents',
            content: <div key={Date.now()}>
              <label>Move to folder</label>
              <SelectInput
                properties={{
                  options: this.state.types
                }}
                onChange={(k, val) => this.setState({updateDocumentType: val})}
              />
            </div>,
            buttonText: 'Save',
            callback: result => {
              if (result) {
                this.updateDocuments();
              }
              return true;
            },
          }]);
        }}
      >
        Update selected
      </button>
    )
  }

  renderSelectAll = () => {
    const documents = (this.props.object || []);
    const selected = documents.reduce((res, doc) =>
      res && this.state.selected.includes(doc.id)
    , true);
    return (
      <CheckboxInput
        object={selected}
        onChange={(k, val) => this.setState({
          selected: val ? documents.map(i => i.id) : []
        })}
      />
    )
  }

  renderDocumentsList = () => {
    const documents = (this.props.object || []);
    if (this.props.aggregatedLeadView) {
      return;
    } else if (documents.length) {
      return (
        <div className='listContainer'>
          <div className='listSelect'>
            {this.renderSelectAll()}
            <span>Select all</span>
          </div>
          {this.props.isMobile
            ? documents.map(this.renderDocument)
            : <table>
                <tbody>
                  {documents.map(this.renderDocument)}
                </tbody>
              </table>}
        </div>
      )
    } else if (!documents.length && !this.props.aggregatedLeadView) {
      return <div className='listPlaceholder'>No documents uploaded</div>;
    }
  }

  renderDocumentsCount = () => {
    const documents = (this.props.object || []);
    return <div className='documentsUploadLabel'>Documents Uploaded: {documents.length}</div>;
  }

  render = () => {
    const documents = (this.props.object || []);

    return (

      <div className='documentsList'>
        {this.renderDocumentsList()}

        <div className='documentActions'>

          {this.props.properties.uploadLink ?
            <div className='uploadLinks left'>
              {documents.length ? this.renderDownloadButton() : null}
              {this.state.selected.length > 0 ? this.renderDeleteButton() : null}
              {this.state.selected.length > 0 && (this.state.types || []).length > 0 ?
                this.renderUpdateButton()
              : null}
            </div>
          : null}

          {this.props.properties.disableUpload ? null : this.renderAddButton()}
        </div>

        {this.props.aggregatedLeadView && documents.length ? this.renderDocumentsCount() : null}

      </div>
    )
  }
}

export default connect(mapStoreToProps)(DocumentsList);
