import React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import SwitchInput from './input/SwitchInput.js';

import { sendRequest } from '../helpers/global.js';
import Storage from '../helpers/Storage.js';

import '../sass/components/NotificationsView.scss';

const mapStoreToProps = (store) => {
  return {
    notifications: store.data.notifications || {
      notifications: [],
      unread_count: 0,
    },
  }
};

class NotificationsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userObject: {...Storage.getData('user')},
    }
  }

  componentDidMount = () => {
    this.checkUndread();
  }

  componentDidUpdate = (prevProps, prevState) => {
  }

  switchSound = (key, value) => {
    sendRequest({
      type: 'POST',
      method: 'me/settings/',
      data: {
        notifications_sound: value,
      },
      success: (data) => {
        Storage.setData('user', data);
        this.setState({userObject: data});
      }
    });
  }

  checkUndread = () => {
    sendRequest({
      type: 'POST',
      method: 'notifications/check',
      noLoad: true,
      success: (data) => {
        Storage.setData('notifications', {
          notifications: this.props.notifications.notifications.map(item => ({
            ...item,
            checked: true,
          })),
          unread_count: 0,
        });
      }
    });
  }

  renderNotificationItem = (item, index) => {
    return (
      <a
        key={item.id}
        href={item.path}
      >
        <div
          className={
            classnames({
              'notificationItem': true,
              'unread': !item.checked,
            })
          }

        >
          <div className='notificationContent'>
            <div className='notificationId'>
              #{this.props.notifications.notifications.length - index}
              {item.beneficary ?  ` · ${item.beneficary}` : null}
            </div>
            <div className='notificationBody' dangerouslySetInnerHTML={{__html: item.title}}/>
            <div className='notificationDate'>{moment.unix(item.created_at).fromNow()}</div>
          </div>
        </div>
      </a>
    )
  }


  render = () => {
    let notifications = this.props.notifications;
    return (
      <>
        <div className='soundBlock'>
          <span className='label'>Sound</span>
          <SwitchInput
            object={this.state.userObject.notifications_sound}
            onChange={this.switchSound}
          />
        </div>
        <div className='notificationsView'>
          <div className='card notificationsList'>
            {notifications.notifications.length > 0
              ? notifications.notifications.map(this.renderNotificationItem)
              : <div className='notificationsPlaceholder'>No notifications yet</div>
            }
          </div>
        </div>
      </>
    )
  }
}

export default connect(mapStoreToProps)(withRouter(NotificationsView));
