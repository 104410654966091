import React from 'react';
import { withRouter } from 'react-router-dom';

import TextInput from '../components/input/TextInput.js';
import HeaderView from '../components/HeaderView.js';

import { sendRequest, triggerEvent } from '../helpers/global.js';
import Storage from '../helpers/Storage.js';

import '../sass/components/AddMissingLeadView.scss';

class AddMissingLeadView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      giveAsiaId: null
    };
  }

  requestMissingLead = () => {
    sendRequest({
      type: 'POST',
      method: `leads/${this.state.giveAsiaId}/add_missing`,
      success: (data) => {
        triggerEvent('showSnackbar', [{text: 'New lead has been added.', type: 'success'}]);
        this.props.history.push('/leads');
      },
      error: (data) => {
        if (data.error_message) {
          triggerEvent('showSnackbar', [{text: data.error_message, type: 'error'}]);
        } else if (data.error) {
          triggerEvent('showSnackbar', [{text: data.error, type: 'error'}]);
        }
      }
    });
  }

  onLogout = (unauthorized) => {
    if (!unauthorized) {
      sendRequest({
        type: 'POST',
        method: 'me/logout',
      });
    }
    Storage.setData('user', null);
    localStorage.removeItem('give-campaign-manager:token');
  }

  render = () => {
    return (
      <div className='addMissingLeadView'>
        <HeaderView
          onLogout={this.onLogout}
          header='Add Missing Lead'
        />
        <label>GIVE.asia ID</label>
        <TextInput
          object={this.state.giveAsiaId}
          properties={{type: 'number'}}
          onChange={(k, value) => this.setState({ giveAsiaId: value })}
        />
        <button
          onClick={this.requestMissingLead}
          disabled={!this.state.giveAsiaId}
        >Load</button>
      </div>
    );
  }
}

export default withRouter(AddMissingLeadView);
