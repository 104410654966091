import React from 'react';
import update from 'immutability-helper';

import { connect } from 'react-redux';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';

import appConfig from '../config/';
import FlexibleInput from './FlexibleInput';
import ChatView from '../components/ChatView.js';
import HeaderView from '../components/HeaderView.js';
import TabsView from '../components/common/TabsView';
import BottomTabView from '../components/common/BottomTabView';
import TodoList from '../components/input/TodoList.js';
import DocumentsList from '../components/input/DocumentsList.js';

import { sendRequest, triggerEvent } from '../helpers/global.js';
import Storage from '../helpers/Storage.js';

import '../sass/components/AggregatedView.scss';

const mapStoreToProps = (store) => {
  return {
    isMobile: store.setup.is_mobile,
    user: store.data.user,
  }
};

class AggregatedView extends React.Component {
  constructor(props) {
    super(props);
    const dafaultBottomTab = this.getDafaultBottomTab();
    this.state = {
      data: null,
      showChat: !this.props.isMobile || dafaultBottomTab === 'chat',
      bottomTab: dafaultBottomTab,
      participant: null,
      newMessagesCount: 0,
      showPulse: false,
      objectType: null,
      activeKeyboard: false,
      errors: {}
    };
  }

  paramIdentifier = () => {
    return this.props.match.params.identifier;
  }

  componentDidMount = () => {
    const url = this.props.location.pathname.substr(1).split('/')[0];
    const objectType = url === 'campaign' ? 'leads' : 'givikis';
    sendRequest({
      method: `aggregated_${objectType}/${this.paramIdentifier()}`,
      type: 'GET',
      success: (data) => {
        this.setState(
          {
            data,
            objectType
          }
        );
      },
      error: (data) => {
      }
    });
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.location.hash !== this.props.location.hash) {
      const tab = this.getDafaultBottomTab();
      this.switchBottomTab(tab);
    }
  }

  getDafaultBottomTab = () => {
    return this.props.location.hash ? this.props.location.hash.replace('#','') : 'case';
  }

  onMessageAdded = (messageData) => {
    if (!this.props.isMobile || this.state.bottomTab === 'chat') {
      this.readMessages();
      return;
    }
    const { user } = this.props;
    const url = this.props.location.pathname.substr(1).split('/')[0];
    const objectType = url === 'campaign' ? 'leads' : 'givikis';
    const authorId = messageData.message.author.user_id;
    if (user && authorId !== user.id) {
      this.showNewMessageNotification(messageData);
    } else if (objectType === 'leads' && this.state.participant !== messageData.message.chat_participant_id) {
      this.showNewMessageNotification(messageData);
    }
  }

  showNewMessageNotification = (messageData) => {
    const text = messageData.message.file ? messageData.message.file.name : messageData.message.content;
    const link = `${this.props.location.pathname}#chat`;
    this.setState({ newMessagesCount: this.state.newMessagesCount + 1 });
    triggerEvent('showNotification', [{
      title: 'New Chat message',
      text: text,
      type: 'newMessage',
      link: link
    }]);
  }

  onShowKeyboard = (value) => {
    this.setState({ activeKeyboard: value });
  }

  switchBottomTab = (tab) => {
    this.setState({bottomTab: tab, showChat: tab === 'chat'});
    this.props.history.replace(`${this.props.location.pathname}#${tab}`);
    if (tab === 'chat') {
      this.readMessages();
    }
  }

  onLogout = (unauthorized) => {
    if (!unauthorized) {
      sendRequest({
        type: 'POST',
        method: 'me/logout',
      });
    }
    Storage.setData('user', null);
    localStorage.removeItem('give-campaign-manager:token');
  }

  handleValueChange = (key, value) => {
    this.setState(update(this.state, {
      data: {
        [key]: {$set: value},
      },
      errors: {
        [key]: {$set: null},
      },
    }));
  }

  handleSwitchBottomTab = (val) => {
    if (val) {
      this.switchBottomTab('chat');
    } else {
      this.switchBottomTab('case');
    }
  }

  readMessages = () => {
    sendRequest({
      type: 'POST',
      method: `chat_participants/${this.state.participant}/read_messages`,
      success: (data) => {
        this.setState({newMessagesCount: 0});
      },
    });
  };

  scrollIntoError = () => {
    const keys = Object.keys(this.state.errors);
    if (keys[0]) {
      const elem = document.querySelector(`#input-${keys[0]}`);
      if (elem) {
        elem.scrollIntoView({behavior: 'smooth', block: 'center'});
      }
    }
  }

  renderAggregatedLead = () => {
    return (
      <div className='inputsBlock'>
        <div className='blockSection card aggregatedLeadCard'>{this.renderAggregatedLeadMain()}</div>
        <div className='blockSection card aggregatedLeadCard'>{this.renderAggregatedLeadDocuments()}</div>
        <div className='blockSection card aggregatedLeadCard'>{this.renderAggregatedLeadStory()}</div>
      </div>
    )
  }

  renderAggregatedLeadMain = () => {
    return (
      <div className='mainSection'>
        <div className='aggregatedLeadRow firstRow'>
          {this.renderFundraiserColumn('Case Id', 'id')}
          {this.renderFundraiserColumn('Beneficiary', 'name')}
        </div>
        <div className='aggregatedLeadLabel'>Campaign Status</div>
        <div className={`aggregatedLeadStatus ${this.state.data['case_status']}`}>
          {this.state.data && this.state.data['case_status']}
        </div>
        {this.renderDirectionSection(
          'chat',
          'Chat with our case supporter',
          'Ask questions or share more details about your story',
        )}
        {this.renderDirectionSection(
          'upload',
          'Upload documents for verification',
          'We will need more documents to verify your story',
        )}
      </div>
    )
  }

  renderAggregatedLeadDocuments = () => {
    return (
      <div className='documentSection'>
        <div className='aggregatedLeadHeader'>Upload supporting documents</div>
        <DocumentsList
          object={this.state.data['documents']}
          objectKey='documents'
          onChange={this.handleValueChange}
          parentObject={this.state.data}
          properties={{uploadId: 'lead_id'}}
          aggregatedLeadView
        />
        <TodoList
          object={this.state.data['todo']}
          aggregatedLeadView
          properties={{disableEdit: true, aggregated: true}}
        />
      </div>
    )
  }

  renderAggregatedLeadStory = () => {
    return (
      <div className='storySection'>
        <div className='aggregatedLeadHeader'>Your story</div>
        <div className='aggregatedLeadRow'>
          {this.renderFundraiserColumn('Beneficiary', 'name')}
          {this.renderFundraiserColumn('Medical Condition', 'medical_condition')}
        </div>
        <div className='aggregatedLeadRow'>
          {this.renderFundraiserColumn('Country Of Treatment', 'country')}
          {this.renderFundraiserColumn('Payee', 'payee_name')}
        </div>
        <div className='aggregatedLeadRow'>
          <div className='aggregatedLeadColumn'>
            <div className='aggregatedLeadLabel'>Story</div>
            <div
              className='aggregatedLeadValue'
              dangerouslySetInnerHTML={{__html: this.state.data && this.state.data['brief_story']}}
            />
          </div>
        </div>
      </div>
    )
  }

  renderDirectionSection = (icon, label, direction) => {
    return (
      <div className='directionSection'>
        <div className={`icon ${icon}`} />
        <div className='directionValue'>
          <div className='directionLabel'>{label}</div>
          <div className='direction'>{direction}</div>
        </div>
      </div>
    )
  }

  renderFundraiserColumn = (title, dataKey) => {
    const value = this.state.data && this.state.data[dataKey];
    return (
      <div className='aggregatedLeadColumn'>
        <div className='aggregatedLeadLabel'>{title}</div>
        <div className='aggregatedLeadValue'>{value}</div>
      </div>
    )
  }

  renderAggregatedGiviki = () => {
    return (
      <div className='inputsBlock'>
        {this.renderInstruction()}
      </div>
    )
  }

  renderInstruction = () => {
    return (
      <div className='blockSection card'>
        <div className='sectionTitle'>Instruction</div>
        <div className="instruction itemContent">
          We are working hard to make your fundraising campaign a success.
          To help us, please follow the instructions below in the todo
          section so we can verify your story and help you get more
          donations faster.
        </div>
      </div>
    )
  }

  renderCaseView = (type) => {
    if (type === 'leads') {
      return this.renderAggregatedLead()
    } else if (type === 'givikis') {
      return this.renderAggregatedGiviki()
    }
  }

  renderInput = (key) => {
    const { objectType } = this.state;
    const pageConfig = appConfig[objectType];
    const properties = {...pageConfig.properties};
    let inputProperties = (properties || {})[key];
    inputProperties['aggregated'] = true;
    if (!inputProperties) {
      return null;
    }
    if (inputProperties.showCondition && !inputProperties.showCondition(this.state.data)) {
      return null;
    }
    let groupHidden = inputProperties.preset && !this.state.data.id;
    let labelTop = inputProperties.labelTop;
    let inputDisabled = inputProperties.preset;
    if (inputProperties.createOnly) {
      inputDisabled = true;
    }
    if (inputProperties.modifyCondition && !inputProperties.modifyCondition(this.state.data)) {
      inputDisabled = true;
    }
    return (
      <div
        key={key}
        id={`input-${key}`}
        className={classnames({
          'inputContainer': true,
          'hidden': groupHidden,
          'labelTop': labelTop,
        })}
      >
        {key !== 'todo' ?
          <label
            className={classnames({
              'labelArea': key === 'brief_story'
            })}
          >
            {inputProperties.title}
          </label>
        : null}
        <div className='inputElem'>
          <FlexibleInput
            object={this.state.data && this.state.data[key]}
            parentObject={this.state.data}
            onChange={this.handleValueChange}
            disabled={inputDisabled}
            objectKey={key}
            properties={inputProperties}
            onValidation={val => this.setState({errors: {...this.state.errors, ...val}})}
          />
          {this.state.errors && this.state.errors[key] ?
            <div className='inputError'>{this.state.errors && this.state.errors[key]}</div>
          : null}
        </div>
      </div>
    );
  }

  renderAggregatedLeadTabs = () => {
    const { bottomTab } = this.state;
    return (
      <BottomTabView
        active={bottomTab === 'chat'}
        itemCount={this.state.newMessagesCount || 0}
        header='Chat'
        headerIcon='chat'
        onSwitchTab={(val) => this.handleSwitchBottomTab(val)}
      />
    );
  }

  renderNavTabs = (type) => {
    const { bottomTab } = this.state;
    if (this.state.activeKeyboard) {
      return;
    } else if (type === 'leads' && bottomTab === 'case') {
      return this.renderAggregatedLeadTabs();
    } else if (type === 'givikis') {
      return (
        <TabsView
          tabs={[
            { key: 'case', title: 'Case' },
            { key: 'chat', title: 'Chat', itemCount:  this.state.newMessagesCount || 0 },
          ]}
          tab={this.state.bottomTab}
          onTabChange={tab => this.switchBottomTab(tab)}
          compact
        />
      );
    }
  }

  render = () => {
    const { data, objectType } = this.state;
    if (!data) {
      return null;
    }
    return (
      <div
        className={classnames({
          'aggregatedView': true,
          'aggregatedLead': objectType === 'leads',
        })}
      >
        {objectType === 'leads'
          ? <HeaderView
              withLogo
              onLogout={this.onLogout}
            />
          : null}
        {this.renderCaseView(objectType)}
        <div>
          <div
            className={classnames({
              'chatSidebar': true,
              'closed': !this.state.showChat,
              'sidebarWithActiveKeyboard': this.state.activeKeyboard,
            })}
          >
            {this.props.isMobile ? this.renderAggregatedLeadTabs() : null}
            <ChatView
              embedded
              aggregatedLead={objectType === 'leads'}
              aggregatedGiviki={objectType === 'givikis'}
              identifier={data.chat_identifier}
              onShowKeyboard={this.onShowKeyboard}
              onMessageAdded={this.onMessageAdded}
              onRegisterParticipant={data => this.setState({ participant: data })}
              onLoadNewMessages={data => this.setState({newMessagesCount: data.length || 0})}
              onReadMessages={data => this.setState({newMessagesCount: 0})}
              keepUnread={this.props.isMobile && this.state.bottomTab !== 'chat'}
            />
          </div>
        </div>
        {this.props.isMobile && this.state.bottomTab === 'case' ?
          <div
            className={classnames({
              'bottomTabs': true,
              'top': objectType === 'leads' && this.state.bottomTab === 'chat',
            })}
          >
            {this.state.activeKeyboard ? null : this.renderNavTabs(objectType)}
          </div>
        : null}
      </div>
    )
  }
}

export default connect(mapStoreToProps)(withRouter(AggregatedView));
