import React from 'react';
import classnames from 'classnames';

import '../../sass/components/input/TextInput.scss';

import { sendRequest } from '../../helpers/global';

export default class TextInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
    this.input = null;
  }

  handleChange = (e) => {
    if (this.props.onChange) {
      this.props.onChange(this.props.objectKey, e.target.value);
    }

    if (this.props.properties.validation) {
      this.validateInput(e.target.value)
    }
  }

  validateInput = (val) => {
    const key = this.props.objectKey

    let formData = new FormData();
    formData.append('id', this.props.parentObject.id);
    formData.append('field', key);
    formData.append(key, val);

    sendRequest({
      type: 'POST',
      method: this.props.properties.validation,
      data: formData,
      noLoad: true,
      success: (data) => {
        if (this.props.onValidation) {
          this.props.onValidation(data)
        }
      },
      error: (data) => {
      }
    });
  }

  componentDidMount = () => {
  }

  renderTextInput = () => {
    const properties = this.props.properties;
    let value = this.props.object;
    if ([null, undefined].indexOf(value) > -1 && this.props.onChange) {
      value = '';
    }
    return (
      <input
        type={properties.type}
        value={value}
        className={classnames({
          'centered': properties.sufix,
        })}
        onChange={this.handleChange}
        readOnly={this.props.disabled}
        placeholder={this.props.placeholder || properties.placeholder}
        autoComplete={this.props.objectKey}
        ref={input => {
          this.input = input;
          if (this.props.inputRef) {
            this.props.inputRef(this.input);
          }
        }}
      />
    )
  }

  renderTextareaInput = () => {
    const properties = this.props.properties;
    let value = this.props.object;
    if ([null, undefined].indexOf(value) > -1 && this.props.onChange) {
      value = '';
    }
    return (
      <textarea
        value={value}
        onChange={this.handleChange}
        readOnly={this.props.disabled}
        placeholder={this.props.placeholder || properties.placeholder}
        ref={input => {
          this.input = input;
          if (this.props.inputRef) {
            this.props.inputRef(this.input);
          }
        }}
      />
    )
  }

  render = () => {
    const properties = this.props.properties;
    return (
      <div
        className={classnames({
          'textInput': true,
          'simplified': properties.simplified,
          'disabled': this.props.disabled,
          'aggregatedLeadView': this.props.aggregatedLeadView,
        })}
      >
        <div className='textInputContainer'>
          {properties.prefix ? <div className='prefix'>{properties.prefix}</div> : null}
          {['text', 'number', 'password'].indexOf(properties.type) > -1 ? this.renderTextInput() : null}
          {properties.type === 'textarea' ? this.renderTextareaInput() : null}
          {properties.sufix ? <div className='sufix'>{properties.sufix}</div> : null}
        </div>
      </div>
    )
  }
}
