import React from 'react';
import classnames from 'classnames';
import update from 'immutability-helper';
import { withRouter } from "react-router-dom";

import appConfig from '../config/';
import { sendRequest, triggerEvent } from '../helpers/global.js';

import FlexibleInput from './FlexibleInput';
import { stateToHtml } from './input/WysiwygInput';

import '../sass/components/ObjectEditView.scss';

class ObjectEditView extends React.Component {

  constructor(props) {
    super(props);
    this.state = this.getDefaultState(props);
  }

  paramId = () => {
    const id = this.props.match.params.id;
    return id === 'create' ? '' : id;
  }

  getDefaultState = (props) => {
    const pageConfig = appConfig[props.configKey];
    const properties = {...pageConfig.properties};
    const config = {...pageConfig.config};
    let object = {};
    Object.keys(properties).forEach(key => {
      if (props.object && props.object[key]) {
        object[key] = props.object[key];
      } else {
        if (Array.isArray(properties[key].default)) {
          object[key] = properties[key].default.slice();
        } else if (typeof properties[key].default === "object") {
          object[key] = Object.assign({}, properties[key].default);
        } else {
          object[key] = properties[key].default;
        }
      }
    });
    let state = {
      object: object,
      properties: properties,
      config: config,
      errors: {},
    }
    return state;
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.configKey) {
      if (this.props.location !== prevProps.location) {
        this.setState(this.getDefaultState(this.props), () => {
          this.requestInitialData();
        });
      }
    }
  }

  updateStateWithData = (data) => {
    const properties = this.state.properties;
    let object = {...this.state.object, ...data};
    Object.keys(properties).forEach(key => {
      if (object[key] === undefined || object[key] === null) {
        object[key] = properties[key].default || null;
      }
    });
    this.setState({ object, properties });
  }

  componentDidMount = () => {
    this.requestInitialData();
  }

  requestInitialData = () => {
    if (!this.paramId()) {
      return;
    }

    sendRequest({
      method: this.state.config.method + this.paramId(),
      type: "GET",
      success: (data) => {
        this.updateStateWithData(data);
      },
      error: (data) => {
      }
    });
  }

  handleSubmit = () => {
    let formData = new FormData();
    Object.keys(this.state.object).forEach(key => {
      const value = this.state.object[key];
      const properties = this.state.properties[key];
      if (!properties) {
      } else if (value === null || value === undefined) {
        if (properties.type === 'select') {
          formData.append(key, '');
        }
      } else if (['image', 'file'].indexOf(properties.type) > -1) {
        if (typeof value !== 'string') {
          formData.append(key, value);
        }
      } else if (properties.type === 'wysiwyg') {
        if (!properties.disabled) {
          formData.append(key, stateToHtml(value));
        }
      } else if (properties.type === 'todo-list') {
        formData.append(key, JSON.stringify(value));
      } else if (properties.type === 'multi-select') {
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value);
      }
    });
    sendRequest({
      method: this.state.config.method + this.paramId(),
      type: this.paramId() ? "PUT" : "POST",
      formData,
      success: (data) => {
        if (data.id) {
          this.updateStateWithData(data);
          this.props.history.push(`/${this.props.configKey}`);
          triggerEvent('showSnackbar', [{text: `${this.state.config.objectName} saved`}]);
        } else if (data.errors) {
          this.setState({errors: data.errors}, this.scrollIntoError);
        }
      },
      error: (data) => {
        if (data.errors) {
          this.setState({errors: data.errors}, this.scrollIntoError);
        }
      }
    });
  }

  handleValueChange = (key, value) => {
    this.setState(update(this.state, {
      object: {
        [key]: {$set: value},
      },
      errors: {
        [key]: {$set: null},
      },
    }));
  }

  handleChange = (value) => {
    this.updateStateWithData(value);
  }

  scrollIntoError = () => {
    const keys = Object.keys(this.state.errors);
    if (keys[0]) {
      const elem = document.querySelector(`#input-${keys[0]}`);
      if (elem) {
        elem.scrollIntoView({behavior: 'smooth', block: 'center'});
      }
    }
  }

  renderInput = (key) => {
    const inputProperties = this.state.properties[key];
    if (!inputProperties) {
      return null;
    }
    if (!inputProperties.type || ["none", "avatar"].indexOf(inputProperties.type) > -1) {
      return null;
    }
    if (inputProperties.showCondition && !inputProperties.showCondition(this.state.object)) {
      return null;
    }
    let groupHidden = inputProperties.preset && !this.state.object.id;
    let labelTop = inputProperties.labelTop
    const areas = ['brief_story', 'documents', 'conversation_files', 'agreement_files', 'todo']
    let inputDisabled = inputProperties.preset;
    if (inputProperties.createOnly) {
      inputDisabled = true;
    }
    if (inputProperties.modifyCondition && !inputProperties.modifyCondition(this.state.object)) {
      inputDisabled = true;
    }
    if (this.state.config.readOnly || this.state.object.deleted) {
      inputDisabled = true;
    }
    return (
      <div
        key={key}
        id={`input-${key}`}
        className={classnames({
          'inputContainer': true,
          'hidden': groupHidden,
          'labelTop': labelTop,
        })}
      >
        <label
          className={classnames({
            'labelArea': areas.includes(key)
          })}
        >
          {inputProperties.title}
        </label>
        <div className='inputElem'>
          <FlexibleInput
            object={this.state.object[key]}
            parentObject={this.state.object}
            onChange={this.handleValueChange}
            disabled={inputDisabled}
            objectKey={key}
            properties={inputProperties}
            onValidation={val => this.setState({errors: {...this.state.errors, ...val}})}
          />
          {this.state.errors[key] ?
            <div className='inputError'>{this.state.errors[key]}</div>
          : null}
        </div>
      </div>
    );
  }

  render = () => {
    const { modifyCondition, readOnly, bottomControls = [], editView } = this.state.config;
    let actionButton = <button onClick={this.handleSubmit}>Save</button>;
    if (readOnly || this.state.object.deleted || this.state.config.hideSave) {
      actionButton = null;
    }
    if (modifyCondition && !modifyCondition(this.state.object)) {
      actionButton = null;
    }

    return (
      <div className='objectEditView'>

        {editView ? <>
          {React.createElement(editView, {
            item: this.state.object,
            onValueChange: this.handleValueChange,
            onChange: this.handleChange,
            onError: errors => this.setState({errors}, this.scrollIntoError),
            renderInput: this.renderInput,
            reload: this.requestInitialData,
          })}
          <div className="left-btn">
            <div className='buttonContainer left'>
              {actionButton}
            </div>
          </div>

          </> :
          <div className='card'>
            <div className='inputsBlock'>
              {Object.keys(this.state.properties).map(this.renderInput)}
            </div>
            <div className='buttonContainer'>
              {actionButton}
            </div>
          </div>
        }

        {bottomControls.map((control, i) =>
          React.createElement(control, {
            key: i,
            item: this.state.object,
          })
        )}

      </div>
    );
  }
}

export default withRouter(ObjectEditView);
