import React from 'react';

import { sendRequest, triggerEvent } from '../helpers/global.js';

import TextInput from '../components/input/TextInput.js';
import SelectInput from '../components/input/SelectInput.js';

import '../sass/components/PayoutFormView.scss';

const COUNTRIES = [
  { id: 'Singapore', name: 'Singapore' },
  { id: 'Malaysia', name: 'Malaysia' },
  { id: 'Indonesia', name: 'Indonesia' },
  { id: 'Hong Kong', name: 'Hong Kong' },
  { id: 'Philippines', name: 'Philippines' },
  { id: 'Vietnam', name: 'Vietnam' },
  { id: 'Thailand', name: 'Thailand' },
  { id: 'India', name: 'India' },
  { id: 'Other', name: 'Other' },
];

export default class PayoutFormView extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      errors: {},
      files: [],
    };
  }

  paramIdentifier = () => {
    return this.props.match.params.identifier;
  }

  componentDidMount = () => {
    sendRequest({
      method: `payout_forms/${this.paramIdentifier()}`,
      type: 'GET',
      success: (data) => {
        this.setState({data});
      },
      error: (data) => {
      }
    });
  }

  checkPayoutValid = () => {
    let errors = {};
    ['country', 'swift_code', 'account_name', 'account_number', 'amount'].forEach(key => {
      if (!this.state.data[key]) {
        errors[key] = 'Cannot be blank';
      }
    });
    if (Number(this.state.data.amount) > Number(this.state.data.lead.balance)) {
      errors.amount = 'Exceeds balance';
    }
    if ((this.state.data.documents || []).length === 0) {
      errors.documents = 'No documents attached';
    }
    this.setState({errors});
    return Object.keys(errors).length === 0;
  }

  onSave = () => {
    if (!this.checkPayoutValid()) {
      return;
    }
    let data = {};
    ['country', 'swift_code', 'account_name', 'account_number', 'amount'].forEach(key => {
      if (this.state.data[key]) {
        data[key] = this.state.data[key]
      }
    });
    sendRequest({
      method: `payout_forms/${this.paramIdentifier()}`,
      type: 'POST',
      data,
      success: (data) => {
        this.setState({data});
        if (data.status === 'completed') {
          triggerEvent('showSnackbar', [{text: 'Payout data completed', type: 'success'}]);
        }
      },
      error: (data) => {
        if (data && data.errors) {
          this.setState({errors: data.errors});
        }
      }
    });
  }
  
  onDeleteFile = (file, index) => {
    this.setState({files:
      this.state.files.filter(f => f !== file)
    });
  }

  uploadFiles = () => {
    let formData = new FormData();
    this.state.files.forEach((file, i) => {
      formData.append(`files[${i}]`, file);
    });
    sendRequest({
      method: `payout_forms/${this.paramIdentifier()}/documents`,
      type: 'POST',
      formData,
      success: (data) => {
        this.setState({
          files: [],
          data: {
            ...this.state.data,
            documents: [
              ...this.state.data.documents,
              ...data,
            ]
          }
        });
        triggerEvent('showSnackbar', [{text: 'Files uploaded', type: 'success'}]);
      },
      error: (data) => {
      }
    });
  }

  renderTextInput = (key, title) => {
    return (
      <div className='inputContainer'>
        <label>{title}</label>
        <TextInput
          properties={{type: 'text'}}
          disabled={this.state.data.status !== 'initial'}
          object={this.state.data[key]}
          onChange={(k, value) => 
            this.setState({
              data: {...this.state.data, [key]: value},
              errors: {
                ...this.state.errors,
                [key]: null,
              }
            })
          }
        />
        {this.state.errors[key] ?
          <div className='inputError'>{this.state.errors[key]}</div>
        : null}
      </div>
    )
  }

  renderSelectInput = (key, title, options) => {
    return (
      <div className='inputContainer'>
        <label>{title}</label>
        <SelectInput
          properties={{options}}
          disabled={this.state.data.status !== 'initial'}
          object={this.state.data[key]}
          onChange={(k, value) => 
            this.setState({
              data: {...this.state.data, [key]: value},
              errors: {
                ...this.state.errors,
                [key]: null,
              }
            })
          }
        />
        {this.state.errors[key] ?
          <div className='inputError'>{this.state.errors[key]}</div>
        : null}
      </div>
    )
  }

  renderAddButton = () => {
    return (
      <>
        <div
          className='addButton'
          onClick={() => this.input.click()}
        >
          <span className='material-icons'>add</span>
        </div>
        <input
          type='file'
          multiple
          ref={input => this.input = input}
          onChange={e => this.setState({files: [
            ...this.state.files,
            ...e.target.files,
          ]})}
        />
      </>
    )
  }

  renderUploadButton = () => {
    return (
      <button
        className='shadow uploadButton'
        disabled={!this.state.files.length}
        onClick={this.uploadFiles}
      >Upload</button>
    )
  }

  renderDocumentsList = () => {
    return (
      <div className='inputContainer filesContainer'>
        <label>Approving documents</label>
        {this.state.data.documents.map((file, index) => 
          <div key={index} className='fileItem'>
            <div
              className='fileName uploaded'
              onClick={() => window.open(file.file_url, '_blank')}
            >{file.name}</div>
          </div>
        )}
        {this.state.files.map((file, index) =>
          <div key={index} className='fileItem'>
            <div className='fileName'>{file.name}</div>
            <div
              className='fileAction'
              onClick={() => this.onDeleteFile(file, index)}
            >
              <span className='material-icons'>delete</span>
            </div>
          </div>
        )}
        {this.state.errors['documents'] ?
          <div className='inputError'>{this.state.errors['documents']}</div>
        : null}
        {this.renderAddButton()}
        {this.renderUploadButton()}
      </div>
    )
  }

  renderFormContent = () => {
    const balance = Number(this.state.data.lead.balance).toFixed(2);
    return (
      <div className='formContent'>
        {this.renderSelectInput('country', 'Country', COUNTRIES)}
        {this.renderTextInput('swift_code', 'SWIFT Code')}
        {this.renderTextInput('account_name', 'Bank Account Name')}
        {this.renderTextInput('account_number', 'Bank Account Number')}
        {this.renderTextInput('amount', 'Amount to withdraw')}
        {this.state.data.status === 'initial' ?
          <span className='balanceInfo'>Balance available: {balance}</span>
        : null}
        {this.renderDocumentsList()}
        {this.state.data.status === 'initial' ?
          <button onClick={this.onSave}>Save</button>
        : null}
      </div>
    )
  }

  render = () => {
    const data = this.state.data;
    return (
      <div className='payoutFormView'>
        {data ?
          <div className='formContainer'>
            <div className='formHeader'>
              Payout for {data.lead && data.lead.name}
            </div>
            {this.renderFormContent()}
          </div>
        : null}
      </div>
    );
  }
}
