import React from 'react';
import { withRouter } from "react-router-dom";

import '../../sass/components/input/PageLink.scss';

class PageLink extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount = () => {
  }

  render = () => {
    const properties = this.props.properties;
    let object = this.props.object;
    if (!object) {
      return null;
    }
    const objectName = object ? object.name === undefined ? object : object.name : '';
    if (properties.external) {
      return (
        <a
          className='pageLink'
          href={object.url || object}
          target='_blank'
          rel='noopener noreferrer'
        >
          <span>{objectName}</span>
        </a>
      )
    } else {
      object = object || this.props.parentObject;
      return (
        <a
          className='pageLink'
          href={properties.page.replace(':id', object.id)}
        >
          <span>{objectName}</span>
        </a>
      )
    }
  }
}

export default withRouter(PageLink);
