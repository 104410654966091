import React from 'react';

import { sendRequest, triggerEvent } from '../../helpers/global.js';

import '../../sass/components/editControls/PayoutBottomControlView.scss';

class PayoutBottomControlView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount = () => {
  }

  componentDidUpdate = (prevProps, prevState) => {
  }

  approvePayout = () => {
    const item = this.props.item;
    sendRequest({
      method: `payouts/${item.id}/approve`,
      type: 'POST',
      success: (data) => {
        triggerEvent('showSnackbar', [{text: 'Payout approved', type: 'success'}]);
        this.props.onChange({
          ...item,
          status: 'approved',
        })
      },
      error: (data) => {
      }
    });
  }

  rejectPayout = () => {
    const item = this.props.item;
    sendRequest({
      method: `payouts/${item.id}/reject`,
      type: 'POST',
      success: (data) => {
        triggerEvent('showSnackbar', [{text: 'Payout rejected', type: 'success'}]);
        this.props.onChange({
          ...item,
          status: 'rejected',
        })
      },
    error: (data) => {
      }
    });
  }

  render = () => {
    const item = this.props.item;
    if (!item || item.status !== 'pending') {
      return null;
    }
    return (
      <div className='payoutBottomControlView'>
        <div className='buttonsContainer'>
          <button onClick={this.approvePayout}>
            Approve
          </button>
          <button onClick={this.rejectPayout}>
            Reject
          </button>
        </div>
      </div>
    )
  }
}

export default PayoutBottomControlView;
