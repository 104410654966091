export default {
  section: "Personal Team",
  config: {
    method: "fundraisers/",
    title: "Search Fundraisers",
    objectName: "Fundraiser",
    disableAdd: true,
    disableDelete: true,
    search: {
      give_asia_id: {
        type: "text",
        title: "GIVE.asia ID",
      },
      email: {
        type: "text",
        title: "Email",
      },
      name: {
        type: "text",
        title: "Name",
      },
    },
  },
  properties: {
    avatar: {
      type: "avatar",
      width: "50px",
    },
    id: {
      default: false,
      type: "text",
      title: "#",
      preset: true,
      width: "60px",
    },
    give_asia_id: {
      type: "text",
      title: "GIVE.asia ID",
      preset: true,
    },
    give_asia_created_at: {
      default: "",
      type: "datetime",
      title: "Date created",
      preset: true,
      width: "150px",
    },
    updated_at: {
      default: "",
      type: "datetime",
      title: "Modified Time",
      preset: true,
    },
    facebook_id: {
      type: "text",
      title: "Facebook ID",
      preset: true,
    },
    photo_url: {
      type: "image",
      title: "Photo",
      preset: true,
    },
    email: {
      type: "text",
      title: "Email",
      width: "auto",
    },
    name: {
      type: "text",
      title: "Name",
      width: "auto",
    },
    profile_url: {
      type: "text",
      title: "Profile URL",
    },
    facebook_verified: {
      type: "checkbox",
      title: "Facebook verified",
      width: "80px",
      showCondition: () => false,
    },
    leads_link: {
      type: "page-link",
      page: "leads?fundraiser_id=:id",
      title: "Leads",
      width: "auto",
      showCondition: () => false,
    },
    profile_url_list: {
      type: "page-link",
      title: "Profile",
      external: true,
      width: "54px",
      showCondition: () => false,
    },

  }
};
