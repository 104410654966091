export default {
  contact: {
    contact_name: {
      type: 'text',
      title: 'What is your name?',
      required: true
    },
    contact_email: {
      type: 'text',
      title: 'What is email address we can contact you with',
      description: 'This should be the same email address you use to register your GIVE.asia user account',
      required: true
    },
    contact_phone: {
      type: 'number',
      title: 'What is your phone number we can contact you with?',
      required: true
    },
  },

  organization: {
    organization_name: {
      type: 'text',
      title: 'Which charity organization or non-profit are you representing?',
      required: true
    },
    country_id: {
      type: 'select',
      title: 'In which country is your organization operating?',
      description: 'This should be where your charity bank account is as well',
      request: 'giviki_forms/countries',
      required: true
    },
    country_other: {
      type: 'text',
      title: 'Your country',
      showCondition: (data) => !!data.organization.show_other_country,
      required: true
    },
    currency: {
      type: 'select',
      title: 'Which default currency would your organisation like to receive donations in?',
      options: [
        { id: 'singapore_cur', name: 'Singapore dollars' },
        { id: 'hong_kong_cur', name: 'Hong Kong dollars' },
        { id: 'malaysia_cur', name: 'Malaysian ringgits' },
        { id: 'indonesia_cur', name: 'Indonesian rupiahs' },
        { id: 'vietnam_cur', name: 'Vietnam dongs' },
        { id: 'thailand_cur', name: 'Thailand bahts' },
        { id: 'usa_cur', name: 'USA dollars' },
        { id: 'myanmar_cur', name: 'Myanmar kyats' },
        { id: 'laos_cur', name: 'Lao kips' },
        { id: 'cambodia_cur', name: 'Cambodian riels' },
        { id: 'taiwan_cur', name: 'Taiwan dollars' },
        { id: 'other_cur', name: 'Other' },
      ],
      required: true
    },
    currency_other: {
      type: 'text',
      title: 'Your currency',
      showCondition: (data) => data.organization.currency === 'other_cur',
      required: true
    },
    organization_registered: {
      type: 'select',
      title: 'Is your organisation a registered charity or non-profit in this country?',
      options: [
        { id: true, name: 'Yes'},
        { id: false, name: 'No'},
      ],
      required: true
    },
    document_proof: {
      type: 'file',
      title: 'Please upload a document as proof of your organization\'s charity status in this country',
      description: 'The document should contain stamp or signature from relevant government authorities',
      showCondition: (data) => data.organization.organization_registered === true,
      required: true
    },
    registered_on_other_platform: {
      type: 'select',
      title: 'Has your organisation registered on any other certification body or fundraising platform, such as https://www.giving.sg/ or https://www.globalgiving.org/?',
      options: [
        { id: true, name: 'Yes'},
        { id: false, name: 'No'},
      ],
      required: true
    },
    other_platform: {
      type: 'text',
      title: 'Which platform it has registered on?',
      showCondition: (data) => data.organization.registered_on_other_platform === true,
      required: true
    },
    tax_deduction: {
      type: 'select',
      title: 'Can your organization offer tax deduction to donors?',
      options: [
        { id: true, name: 'Yes'},
        { id: false, name: 'No'},
      ],
      required: true
    },
    min_donation: {
      type: 'number',
      title: 'Please enter the minimum amount (in selected currency) that a donor needs to donate to receive tax deduction from your organization.',
      description: 'Enter 0 if you provide tax deduction for any amount',
      showCondition: (data) => data.organization.tax_deduction === true,
      required: true
    },
    giviki_category_ids: {
      type: 'multi-select',
      title: 'What is the main categories and causes of your organization (you may select up to 3)',
      request: 'giviki_forms/categories_options/',
      required: true,
      limit: 3,
    },
    staff_size: {
      type: 'select',
      title: 'What is the size of your charity?',
      request: 'giviki_forms/staff_options/',
      required: true
    },
  },

  brand: {
    website_subdomain: {
      type: 'text',
      prefix: 'https://',
      sufix: '.give.asia',
      title: 'What is the URL that you would like to have for your branded website?',
      description: 'Here is the format that you should follow:<br/>https://redcross.give.asia<br/>https://acres.give.asia',
      required: true
    },
    logo: {
      type: 'image',
      title: (data) => `Please upload the logo of ${data.organization.organization_name || 'your organization'} that we can use on your branded website.<br/> The logo must be in JPG/PNG format only.`,
      description: 'The logo must be in JPG/PNG format only',
      required: true
    },
    banner: {
      type: 'image',
      title: (data) => `Please upload a banner image for ${data.organization.organization_name || 'your organization'} that we can use on the homepage of the branded website we are building.<br/> The banner image must be in JPG/PNG format only and recommende to be 1400x500 pixels`,
      description: 'The banner image must be in JPG/PNG format only and recommended to be 1400x500 pixels',
      required: true
    },
    organization_email: {
      type: 'text',
      title: (data) => `Which email ${data.organization.organization_name || 'your organization'} can be reached at?`,
      required: true
    },
    organization_phone: {
      type: 'number',
      title: (data) => `Which phone number ${data.organization.organization_name || 'your organization'} can be reached at?`,
      required: true
    },
    youtube_url: {
      type: 'text',
      title: (data) => `Please provide a link to a Youtube video (if you have one) that gives an introduction to ${data.organization.organization_name || 'your organization'} that we can use on the homepage of your branded website.`,
      check: (data) => true,
    },
    organization_description: {
      type: 'textarea',
      title: (data) => `Please provide a short write-up about ${data.organization.organization_name || 'your organization'} that we can use on your branded website to introduce your organization to new users`,
      required: true
    },
    organization_website: {
      type: 'text',
      prefix: 'https://',
      title: (data) => `Please provide a link to the main website of ${data.organization.organization_name || 'your organization'}.`,
      required: true
    },
    facebook_url: {
      type: 'text',
      title: 'Please provide a link to your Facebook page (if you have one).',
      check: (data) => true,
    },
    twitter_url: {
      type: 'text',
      title: 'Please provide a link to your Twitter page (if you have one).',
      check: (data) => true,
    },
    brand_color_hex: {
      type: 'text',
      title: 'What is the default color code that you would like to have on your website?',
      description: 'You can use this website to find the color code - http://html-color-codes.info/colors-from-image/<br/><br/>For example,<br/>SCS (https://singaporecancersociety.give.asia/) branded website\'s color code is #634180<br/><br/>',
      required: true
    },
    agreement_accepted: {
      type: 'select',
      title: 'I hereby declare that all statements made in this application are true, complete and correct to the best of my knowledge and belief. Also, I declare that this application is submitted by the authorized person named above for and on behalf of the organization named above. I accept that this application is subject to approval by GIVEASIA PTE LTD.',
      options: [
        { id: true, name: 'Yes'},
        { id: false, name: 'No'},
      ],
      check: (data) => data.brand.agreement_accepted === true,
      description: `
        I have read, understand and agree to the terms and conditions of the Partner Organizations User Agreement which can be found at <a href="https://give.asia/POUA.pdf" target="_blank" rel="noopener noreferrer">POUA</a><br/><br/>
        Security: Every effort will be made to maintain the strictest confidence concerning any material, plans or policies divulged to us in the course of our relation with the Customer.<br/><br/>
        Ownership: Customer expressly acknowledges and agrees that the ownership of GIVE.asia platform belongs to GIVEASIA PTE LTD and GIVEASIA PTE LTD will be making modifications to GIVE.asia platform to provide the Customer the services described in previous sections. The intellectual property rights arising from modifications made to GIVE.asia platform shall vest in GIVE.asia platform. The Software, the Documentation and all intellectual property rights of whatever nature in GIVE.asia platform, are at all times, and shall remain, the property of GIVEASIA PTE LTD.<br/><br/>
        Non-Exclusivity: The Customer recognises and agrees that GIVE.ASIA may currently provide, may have provided in the past, and may in the future provide, similar services to other firms directly competing with the Customer.<br/><br/>
      `,
      required: true
    },
  },

  bank: {
    bank_account_name: {
      type: 'text',
      title: 'Account name',
      description: 'The name of the bank account holder shall match your organisation name',
      required: true
    },
    bank_account_number: {
      type: 'text',
      title: 'Bank account number',
      required: true
    },
    bank_name: {
      type: 'text',
      title: 'Bank name',
      required: true
    },
    bank_code: {
      type: 'text',
      title: 'Bank code',
      required: true
    },
    bank_branch_code: {
      type: 'text',
      title: 'Branch code',
      required: true
    },
    bank_swift_code: {
      type: 'text',
      title: 'SWIFT code',
      required: true
    },
    bank_proof_document: {
      type: 'file',
      title: (data) => `Please upload a proof of ${data.organization.organization_name || 'your organization'}'s bank account, such as a bank statement.`,
      description: 'This document should at least contain the account name and account number indicated above',
      required: true
    },
    financial_statement: {
      type: 'file',
      title: (data) => `Please upload a copy of ${data.organization.organization_name || 'your charity'}'s latest financial statement or annual report`,
      required: true
    },
    how_know: {
      type: 'select',
      title: 'Last question, how did you hear about GIVE.asia?',
      request: 'giviki_forms/how_know_options/',
      required: true
    },
    how_know_details: {
      type: 'text',
      title: 'Provide more details',
      required: (data) => data.bank.how_know === 4, // 'Other' how_know option
      showCondition: (data) => data.bank.how_know === 4, // 'Other' how_know option
    },
  },
};
