import React from 'react';
import { sendRequest } from '../../helpers/global';
import SelectInput from './SelectInput.js';

import '../../sass/components/input/PayeesList.scss';

class PayeesList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      payees: []
    };
  }

  componentDidMount = () => {
    sendRequest({
      method: 'collections/payees/',
      type: 'GET',
      success: (data) => {
        this.setState({ payees: data });
      },
      error: (data) => {
      }
    });
  }

  handleChange = (value) => {
    this.props.onChange(this.props.objectKey, value);
  }

  addItem = (value) => {
    this.handleChange([
      ...this.props.object || [],
      null
    ])
  }

  updateItem = (index, value) => {
    const object = this.props.object || [];
    if (index > -1) {
      let updated = object.slice();
      updated[index] = value;
      this.handleChange(updated);
    }
  }

  deleteItem = (index) => {
    const object = this.props.object || [];
    if (index > -1) {
      let updated = object.slice();
      updated.splice(index, 1);
      this.handleChange(updated);
    }
  }

  renderListItem = (item, index) => {
    const { payees } = this.state;
    const selectedOption = item && payees.find(payee => payee.id === item);
    let options = payees.filter(payee => !this.props.object.includes(payee.id));
    if (selectedOption) {
      options.push(selectedOption);
    }
    return (
      <div key={index} className='listItem'>
        <SelectInput
          object={item}
          properties={{options: options || []}}
          onChange={(k, value) => this.updateItem(index, value)}
        />
        <div
          className='removeButton'
          onClick={() => this.deleteItem(index)}
        >
          <span className='material-icons'>close</span>
        </div>
      </div>
    )
  }

  render = () => {
    const object = this.props.object || [];
    return (
      <div className='payeesList'>
        <div className='listContainer'>
          {object.map(this.renderListItem)}
        </div>
        <button
          className='outline'
          onClick={this.addItem}
        >Add</button>
      </div>
    )
  }
}

export default PayeesList;
