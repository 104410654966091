const DOC_TYPES = [
  { id: 'medical', name: 'Medical' },
  { id: 'financial', name: 'Financial' },
  { id: 'media', name: 'Media' },
  { id: 'misc', name: 'Misc' },
];

export default {
  section: "Charity Team",
  config: {
    method: "givikis/",
    title: "Charity Sign-ups",
    objectName: "Giviki",
    disableAdd: true,
    editView: require("../components/editControls/GivikiEditView").default,
    search: {
      status: {
        type: "select",
        title: "Case status",
        request: "givikis/statuses",
        default: 2,
      },
      user_id: {
        type: "select",
        title: "Case Supporter",
        request: "collections/users/",
        clearable: true,
      },
      country: {
        type: "select",
        title: "Country",
        request: "collections/countries/",
        clearable: true,
      },
      organization_name: {
        type: "text",
        title: "Charity name",
      },
    },
  },
  properties: {
    id: {
      default: false,
      type: "text",
      title: "#",
      preset: true,
      width: "60px",
    },
    contact_email: {
      type: "text",
      title: "Email",
      validation: "givikis/validate",
    },
    charity_name: {
      type: "text",
      title: "Charity name",
      width: "auto",
    },
    created_at_formatted: {
      type: "date",
      title: "Date created",
      width: "110px",
    },
    contact_name: {
      type: "text",
      title: "Applicant",
      width: "auto",
    },
    country_list: {
      type: "text",
      title: "Country",
      width: "auto",
    },
    country_id: {
      type: "select",
      title: "Country",
      request: "collections/countries/",
    },
    country_other: {
      type: 'text',
      title: 'Other country',
    },
    user_id: {
      type: "select",
      title: "Case Supporter",
      listEdit: true,
      method: "givikis/:id/user",
      clearable: true,
      width: "180px",
      storageKey: 'users',
    },
    status: {
      type: "select",
      title: "Case status",
      options: [
        { id: "partially_filled", name: "Partially filled" },
        { id: "pending", name: "Pending" },
        { id: "approved", name: "Approved" },
        { id: "rejected", name: "Rejected" },
        { id: "pending_approval", name: "Pending approval" },
      ],
      width: "auto",
      method: "givikis/:id/status",
    },
    todo: {
      type: "todo-list",
      title: "Checklist",
      disableEdit: true,
      labelTop: true
    },
    contact_phone: {
      type: "text",
      title: "Phone",
      validation: "givikis/validate",
    },
    time_to_contact: {
      type: "text",
      title: "Time to contact",
    },

    organization_name: {
      type: "text",
      title: "Charity name",
    },
    currency: {
      type: "select",
      title: "Currency",
      options: [
        { id: 'singapore_cur', name: 'Singapore dollars' },
        { id: 'hong_kong_cur', name: 'Hong Kong dollars' },
        { id: 'malaysia_cur', name: 'Malaysian ringgits' },
        { id: 'indonesia_cur', name: 'Indonesian rupiahs' },
        { id: 'vietnam_cur', name: 'Vietnam dongs' },
        { id: 'thailand_cur', name: 'Thailand bahts' },
        { id: 'usa_cur', name: 'USA dollars' },
        { id: 'myanmar_cur', name: 'Myanmar kyats' },
        { id: 'laos_cur', name: 'Lao kips' },
        { id: 'cambodia_cur', name: 'Cambodian riels' },
        { id: 'taiwan_cur', name: 'Taiwan dollars' },
        { id: 'other_cur', name: 'Other' },
      ],
    },
    currency_other: {
      type: "text",
      title: "Currency",
      showCondition: object => object.currency === 'other_cur',
    },
    organization_registered: {
      type: "select",
      title: "Non-profit",
      options: [
        { id: true, name: "Yes"},
        { id: false, name: "No"},
      ]
    },
    document_proof: {
      type: "file",
      external: true,
      title: "Charity status proof",
    },
    registered_on_other_platform: {
      type: "select",
      title: "Registered on other platform",
      options: [
        { id: true, name: "Yes"},
        { id: false, name: "No"},
      ]
    },
    other_platform: {
      type: "text",
      title: "Other platform",
      showCondition: object => object.registered_on_other_platform === true,
    },
    tax_deduction: {
      type: "select",
      title: "Tax deduction",
      options: [
        { id: true, name: "Yes"},
        { id: false, name: "No"},
      ]
    },
    min_donation: {
      type: "text",
      title: "Min donation",
    },
    giviki_category_ids: {
      type: "multi-select",
      title: "Categories",
      request: "giviki_forms/categories_options/",
      limit: 3,
    },
    staff_size: {
      type: "select",
      title: "Size of charity",
      storageKey: 'staffOptions',
    },

    website_subdomain: {
      type: "text",
      title: "Website subdomain",
    },
    charity_slug: {
      type: "text",
      title: "Charity URL",
    },
    logo: {
      type: "image",
      title: "Logo"
    },
    banner: {
      type: "image",
      title: "Banner",
    },
    organization_email: {
      type: "text",
      title: "Email",
    },
    organization_phone: {
      type: "text",
      title: "Phone",
    },
    organization_description: {
      type: "textarea",
      title: "Description",
      labelTop: true
    },
    organization_website: {
      type: "text",
      title: "Website",
    },
    youtube_url: {
      type: "text",
      title: "Youtube URL",
    },
    facebook_url: {
      type: "text",
      title: "Facebook URL",
    },
    twitter_url: {
      type: "text",
      title: "Twitter URL",
    },
    brand_color_hex: {
      type: "text",
      title: "Brand color",
    },

    bank_account_name: {
      type: "text",
      title: "Account name",
    },
    bank_account_number: {
      type: "text",
      title: "Bank account number",
    },
    bank_name: {
      type: "text",
      title: "Bank name",
    },
    bank_code: {
      type: "text",
      title: "Bank code",
    },
    bank_branch_code: {
      type: "text",
      title: "Branch code",
    },
    bank_swift_code: {
      type: "text",
      title: "SWIFT code",
    },
    bank_proof_document: {
      type: "file",
      external: true,
      title: "Proof document",
    },
    how_know: {
      type: "select",
      title: "How know",
      storageKey: 'howKnowOptions',
    },
    how_know_details: {
      type: 'text',
      title: 'Provide more details',
      required: object => object.how_know === 4, // 'Other' how_know option
      showCondition: object => object.how_know === 4, // 'Other' how_know option
    },
    notification_alert: {
      type: "none",
    },
    page_link: {
      type: "page-link",
      page: "/givikis/:id",
      width: "auto",
    },
    chat_identifier: {
      type: "none",
    },
    financial_statement: {
      type: "file",
      title: "Financial Document",
    },
    verifier_id: {
      type: "select",
      title: "Case Manager",
      clearable: true,
      storageKey: 'users',
    },
    remarks: {
      type: "textarea",
      title: "Remarks",
    },
    documents: {
      default: [],
      type: "documents-list",
      title: "Documents",
      uploadId: "giviki_id",
      uploadLink: (object, type) => `/library/${type}/${object.identifier}`,
      uploadLinkTitle: (type) => `${(DOC_TYPES.find(i => i.id === type) || {}).name || ''}Upload Link`,
      types: DOC_TYPES,
    },
  }
};
