import React from 'react';
import classnames from 'classnames';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import Storage from '../../helpers/Storage.js';
import { sendRequest, triggerEvent } from '../../helpers/global.js';

import TabsView from '../common/TabsView';
import LogsList from '../input/LogsList.js';
import PayoutsList from '../input/PayoutsList.js';
import ChatView from '../ChatView.js';
import HeaderView from '../../components/HeaderView.js';
import appConfig from '../../config/';
import { stateToHtml } from '../input/WysiwygInput';

import '../../sass/components/editControls/LeadEditView.scss';

const TABS = [
  {
    key: 'step_1',
    title: 'Step 1',
    sections: [
      {
        title: 'Fundraiser',
        fields: [
          'fundraiser', 'relationship_with_beneficiary', 'phone', 'fundraiser_email',
          'time_to_contact', 'send_start_agreement', 'call_fundraiser',
          /*'send_end_agreement',*/
        ]
      },
      {
        title: 'Beneficiary name',
        fields: ['beneficiary_name']
      },
      {
        title: 'Beneficiary',
        fields: [
          'movement_name', 'country', 'name', 'amount_target', 'brief_story', 'medical_condition'
        ]
      },
      {
        title: 'Documents',
        fields: [
          'documents', 'conversation_files', 'agreement_files',
          /*
          make click to open preview in same screen with ability for slider scroll
          */
        ]
      },
      {
        title: 'Admin checklist',
        fields: [
          'charity_id', 'todo'
        ]
      },
      {
        title: 'Verifier',
        fields: [
          'user_id', 'verifier_id', 'remarks',
        ]
      },
    ],
  },
  {
    key: 'step_2',
    title: 'Step 2',
    sections: [
      {
        title: 'Interview',
        fields: [
          'fundraiser_name', 'interview_remarks',
        ]
      },
      {
        title: 'Beneficiary',
        fields: [
          'country', 'name', 'brief_story', 'medical_condition'
        ]
      },
    ]
  },
  {
    key: 'step_3',
    title: 'Step 3',
    showCondition: () => (Storage.getData('user') || {}).role === 'admin',
    sections: [
      {
        title: 'Content team',
        fields: [
          'name', 'brief_story', 'facebook_url', 'campaign_url', 'campaign_short_url'
        ]
      },
      {
        title: 'Creative team',
        fields: [
          'youtube_url', 'campaign_banner', 'campaign_gallery'
        ]
      },
      {
        title: 'Marketing team',
        fields: [
          'marketing_todo'
        ]
      }
    ]
  },
  {
    key: 'step_4',
    title: 'Step 4',
    showCondition: () => (Storage.getData('user') || {}).role === 'admin',
    sections: [
      {
        fields: [
          'payee_id', 'payout_reference_number', 'payouts_list', 'payout_subtotal',
          'balance', 'payout_button'
        ],
        columns: [
          'amount_raised', 'currency', 'donation_count', 'refunded_amount', 'ads_budget',
          'refunded_donation_count', 'ads_refund', 'payouts_last_updated_at',
          'stripe_fee_value'
        ]
      },
    ]
  },
  {
    key: 'logs',
    icon: 'subject',
    sections: [
      {
        fields: [
          'logs_list',
        ]
      }
    ]
  },
  {
    key: 'analytics',
    icon: 'trending_up',
    showCondition: () => (Storage.getData('user') || {}).role === 'admin',
    sections: [
      {
        title: 'Campaign',
        fields: [
          'currency', 'count_of_donors', 'amount_target_value', 'offline_amount',
          'stripe_fee',
        ]
      },
      {
        title: 'Ads',
        fields: ['ads_spent', 'ads_balance']
      },
    ],
  },
];

const mapStoreToProps = (store) => {
  return {
    isMobile: store.setup.is_mobile,
    user: store.data.user,
  }
};

class LeadEditView extends React.Component {
  constructor(props) {
    super(props);
    const dafaultBottomTab = this.getDafaultBottomTab();
    this.state = {
      tab: TABS[0].key,
      changes: [],
      files: [],
      agreements: [],
      showChat: !this.props.isMobile || dafaultBottomTab === 'chat',
      bottomTab: dafaultBottomTab,
      participant: null,
      newMessagesCount: 0,
      activeKeyboard: false,
      cancelReason: null,
      movement: null,
    };
    this.emailInput = null;
    this.reasonInput = null;
  }

  componentDidMount = () => {
    this.requestUsers();
    this.requestData();
    this.checkGiveAsiaChanges();
  }

  componentDidUpdate = (prevProps, prevState) => {
    const curItem = this.props.item || {};
    const prevItem = prevProps.item || {};

    if (curItem.id && curItem.id !== prevItem.id) {
      this.requestData();
      this.checkGiveAsiaChanges();
      if (!prevItem.id) {
        const tab = this.props.location.hash.replace(/#/g, '');
        if (tab) {
          this.switchTab(tab);
        }
      }
    } else if (curItem.charity_id && curItem.charity_id !== prevItem.charity_id) {
      sendRequest({
        method: `collections/charities/${curItem.charity_id}/todo`,
        type: 'GET',
        success: (data) => {
          this.props.onValueChange('todo', data || []);
        },
        error: (data) => {
        }
      });
    }
    if (prevState.tab !== this.state.tab && this.state.tab === 'step_3') {
      this.requestMarketingTodos();
    } else if (prevState.tab !== this.state.tab && this.state.tab === 'logs') {
      this.requestChanges();
    }
  }

  getDafaultBottomTab = () => {
    return this.props.location.hash ? this.props.location.hash.replace('#','') : 'case';
  }

  toggleMenu = () => {
    this.setState({ showMenu: !this.state.showMenu })
  }

  requestUsers = () => {
    sendRequest({
      method: `collections/users`,
      type: 'GET',
      success: (data) => {
        Storage.setData('users', data);
      },
      error: (data) => {
      }
    });
  }

  requestData = () => {
    const item = this.props.item;
    if (!item || !item.id) {
      return;
    }
    const tab = this.state.tab;
    if (tab === 'logs') {
      this.requestChanges();
    }
    if (!item.marketing_todo && tab === 'step_3') {
      this.requestMarketingTodos();
    }
    if (item.movement_id) {
      this.requestMovement(item.movement_id);
    }
  }

  requestMovement = (id) => {
    sendRequest({
      method: `movements/${id}`,
      type: 'GET',
      success: (data) => {
        this.setState({ movement: data });
      },
      error: (data) => {
      }
    });
  }

  requestChanges = () => {
    const item = this.props.item;
    if (!item || !item.id) {
      return;
    }
    sendRequest({
      method: `leads/${item.id}/changes`,
      type: 'GET',
      success: (data) => {
        this.setState({changes: data});
      },
      error: (data) => {
      }
    });
  }

  requestMarketingTodos = () => {
    const item = this.props.item;
    if (!item || !item.id) {
      return;
    }
    sendRequest({
      method: `collections/marketing_todos`,
      type: 'GET',
      success: (data) => {
        this.props.onValueChange('marketing_todo', data || []);
      },
      error: (data) => {}
    });
  }

  onMessageAdded = (data) => {
    if (this.props.isMobile && this.state.bottomTab === 'chat') {
      this.readMessages();
      return;
    }
    const authorId = data['message']['author']['user_id'];
    if (authorId !== this.props.user.id) {
      this.setState({ newMessagesCount: this.state.newMessagesCount + 1 });
    }
  }

  onShowKeyboard = (value) => {
    setTimeout(() => {
      this.setState({ activeKeyboard: value });
    }, 500);
  }

  switchBottomTab = (tab) => {
    this.setState({bottomTab: tab, showChat: tab === 'chat'});
    this.props.history.replace(`${this.props.location.pathname}#${tab}`);
    if (tab === 'chat') {
      this.readMessages();
    }
  }

  switchTab = (tab) => {
    this.setState({showMenu: false});

    if (TABS.map(tab => tab.key).indexOf(tab) < 0) {
      return;
    }
    let step1Errors = {};
    let step2Errors = {};
    let step3Errors = {};
    if (tab === 'step_2') {
      if (!this.props.item['verifier_id']) {
        step1Errors['verifier_id'] = 'This field is required';
      }
      /*
      [
        'relationship_with_beneficiary', 'phone', 'country', 'brief_story',
        'medical_condition', 'name', 'charity_id', 'user_id', 'verifier_id',
        'time_to_contact'
      ].forEach(key => {
        if (!this.props.item[key]) {
          step1Errors[key] = 'This field is required';
        }
      });
      ['todo'].forEach(key => {
        if (this.props.item[key] && this.props.item[key].filter(item => !item.checked).length) {
          step1Errors[key] = 'All checklist items is required';
        }
      });
      */
      if (Object.keys(step1Errors).length) {
        if (this.state.tab === 'step_1') {
          this.props.onError(step1Errors);
        } else if (!Object.keys(step1Errors).length) {
          this.setState({tab: 'step_1'});
          this.props.onError(step1Errors);
        }
        return;
      }
      this.updateCampaign('step_1', 'complete_step');
    }
    if (tab === 'step_3') {
      /*
      [
        'fundraiser_name', 'remark', 'country', 'name', 'brief_story', 'medical_condition'
      ].forEach(key => {
        if (!this.props.item[key]) {
          step2Errors[key] = 'This field is required';
        }
      });
      */
      if (Object.keys(step2Errors).length) {
        if (this.state.tab === 'step_2') {
          this.props.onError(step2Errors);
        } else if (!Object.keys(step2Errors).length) {
          this.setState({tab: 'step_2'});
          this.props.onError(step2Errors);
        }
        return;
      }
      this.updateCampaign('step_2', 'complete_step');
    }
    if (tab === 'step_4') {
      /*
      [
        'name', 'brief_story', 'facebook_url', 'campaign_url', 'campaign_short_url',
        'youtube_url'
      ].forEach(key => {
        if (!this.props.item[key]) {
          step3Errors[key] = 'This field is required';
        }
      });
      ['marketing_todo'].forEach(key => {
        if (this.props.item[key] && this.props.item[key].filter(item => !item.checked).length) {
          step3Errors[key] = 'All checklist items is required';
        }
      });
      */
      if (Object.keys(step3Errors).length) {
        if (this.state.tab === 'step_3') {
          this.props.onError(step3Errors);
        } else if (!Object.keys(step3Errors).length) {
          this.setState({tab: 'step_3'});
          this.props.onError(step3Errors);
        }
        return;
      }

      this.updateCampaign('step_3', 'complete_step');
    }
    if (tab === 'logs') {
      this.updateCampaign('step_4', 'complete_step');
    }
    this.setState({tab});
  }

  changeStatus = (status) => {
    if (status === 'pending_approval' && !this.props.item['verifier_id']) {
      triggerEvent('showSnackbar', [{text: 'No Case Manager assigned.', type: 'error'}]);
      return;
    }
    const item = this.props.item;
    sendRequest({
      method: `leads/${item.id}/status`,
      type: 'POST',
      data: {
        case_status: status,
        cancel_reason: this.state.cancelReason
      },
      success: (data) => {
        this.props.onValueChange('case_status', status);
        this.updateGiveAsia(status);
        triggerEvent('showSnackbar', [{text: `Lead ${status}`}]);
        if (status === 'pending') {
          this.switchTab('step_1');
        }
      },
      error: (data) => {
      }
    });
  }

  rejectLead = () => {
    triggerEvent('showRejectCampaign', [{
      callback: result => {
        if (result) {
          this.setState({ cancelReason: result }, () => this.changeStatus('rejected'));
        }
      },
      campaign: this.props.item,
      type: 'lead'
    }]);
  }

  updateGiveAsia = (status) => {
    const item = this.props.item;
    const users = Storage.getData('users');
    const caseSupporter = users.find(user => user.id === this.props.item['user_id']);
    const caseManager = users.find(user => user.id === this.props.item['verifier_id']);
    let formData = new FormData();
    formData.append('campaign_title', this.props.item['name']);
    formData.append('campaign_story', stateToHtml(this.props.item['brief_story']));
    formData.append('case_status', status);
    if (caseSupporter) {
      formData.append('case_supporter_email', caseSupporter.email);
    }
    if (caseManager) {
      formData.append('case_manager_email', caseManager.email);
    }
    if (status === 'pending_approval') {
      formData.append('pre_approved_at', moment().unix());
    } else if (status === 'published') {
      formData.append('published_at', moment().unix());
    }

    sendRequest({
      type: 'PUT',
      method: `leads/${item.id}/update_give_asia`,
      data: formData,
      success: (data) => {
      },
      error: (data) => {
      }
    });
  }

  checkGiveAsiaChanges = () => {
    if (this.props.item.changed_by === 'api') {
      triggerEvent('showSnackbar', [{text: `This company has been edited due to changes in GIVE.asia. You can undo these changes if needed.`}]);
    }
  }

  approveCampaign = () => {
    const item = this.props.item;
    const tabIndex = TABS.findIndex(tab => tab.key === this.state.tab);
    const nextTab = TABS[tabIndex + 1] && TABS[tabIndex + 1].key;
    sendRequest({
      type: 'PUT',
      method: `leads/${item.id}/approve_campaign`,
      success: (data) => {
        triggerEvent('showSnackbar', [{text: `Lead published`}]);
        this.props.onValueChange('case_status', 'published');
        this.switchTab(nextTab);
      },
      error: (data) => {
      }
    });
  }

  updateCampaign = (step, updateType) => {
    if (this.props.item.id) {
      const item = this.props.item;
      const key = window.location.pathname.substr(1).split('/')[0];
      const pageConfig = appConfig[key];
      const properties = {...pageConfig.properties};

      let formData = new FormData();
      const sections = (TABS.find(t => t.key === step) || {}).sections || [];
      const fields = sections.reduce((sum, section) => [...sum, ...section.fields], []);

      fields.forEach(key => {
        let value = this.props.item[key];
        if (!properties[key]) {
        } else if (value === null || value === undefined) {
          if (properties[key]['type'] === 'select') {
            formData.append(key, '');
          }
        } else if (['image', 'file'].indexOf(properties[key]['type']) > -1) {
          if (typeof value !== 'string') {
            formData.append(key, value);
          }
        } else if (properties[key]['type'] === 'wysiwyg') {
          if (!properties[key].disabled) {
            formData.append(key, stateToHtml(value));
          }
        } else if (properties[key]['type'] === 'todo-list') {
          formData.append(key, JSON.stringify(value));
        } else if (properties[key]['type'] === 'multi-select') {
          formData.append(key, JSON.stringify(value));
        } else {
          formData.append(key, value);
        }
      });

      sendRequest({
        method: `leads/${item.id}`,
        type: 'PUT',
        formData,
        success: (data) => {
          this.props.onChange(data);
          if (updateType === 'save') {
            if (data.id) {
              triggerEvent('showSnackbar', [{text: 'Lead saved'}]);
            }
          } else if (data.status === 'pending_approval' && step === 'step_1') {
            this.changeStatus('pending_approval');
          }
        },
        error: (data) => {
          triggerEvent('showSnackbar', [{text: data.error_message, type: 'error'}]);
        }
      });

    } else {
      triggerEvent('showSnackbar', [{text: 'Your Campaign does not exist or is invalid.', type: 'error'}]);
    }
  }

  rollbackData = () => {
    const item = this.props.item;
    sendRequest({
      type: 'POST',
      method: `leads/${item.id}/rollback_data`,
      success: (data) => {
        this.props.reload();
      },
      error: (data) => {
      }
    });
  }

  sendAgreement = (type, email, reason) => {
    const item = this.props.item;
    sendRequest({
      method: `leads/${item.id}/agreement`,
      type: 'POST',
      data: {
        email,
        agreement_type: type,
        donation_reason: reason,
      },
      success: (data) => {
        triggerEvent('showNotification', [{title: 'You have been sent agreement to sign.'}]);
      },
      error: (data) => {
        if (data.error) {
          triggerEvent('showSnackbar', [{text: data.error, type: 'error'}]);
        }
      }
    });
  }

  onSendAgreement = (type) => {
    const item = this.props.item;
    triggerEvent('openSendAgreementPopup', [{
      id: item.id,
      type: type,
      email: item['fundraiser_email'],
      entity: 'leads'
    }])
  }

  makePhoneCall = () => {
    const item = this.props.item;
    triggerEvent('showPhoneCall', [{
      phone: item.phone,
      data: { lead_id: item.id },
    }]);
  }

  openAggregated = () => {
    const item = this.props.item;
    window.open(`/campaign/${item.identifier}`);
  }

  requestPayout = () => {
    const item = this.props.item;
    const tab = window.open();
    sendRequest({
      method: `leads/${item.id}/request_payout`,
      type: 'POST',
      success: (data) => {
        tab.location = `/payout_form/${data.identifier}`;
      },
      error: (data) => {
        tab.close();
      }
    });
  }

  readMessages = () => {
    sendRequest({
      type: 'POST',
      method: `chat_participants/${this.state.participant}/read_messages`,
      success: (data) => {
        this.setState({newMessagesCount: 0});
      },
    });
  };

  showMediaText = (index, type) => {
    let files = [...this.state.files];
    let file = {...files[index]};
    const show_text = file[type] ? null : true
    file[type] = show_text;
    files[index] = file;
    this.setState({files});
  }

  publish = () => {
    this.approveCampaign();
    this.updateGiveAsia('published');
  }

  renderInput = (key) => {
    const item = this.props.item;
    if (key === 'send_start_agreement') {
      const haveCompleting = (item.agreements_statuses || {}).completing;
      if (!haveCompleting) {
        return (
          <div className='buttonContainer'>
            <button className='outline' onClick={() => this.onSendAgreement('getting_started')}>
              Send Getting Started agreement
            </button>
          </div>
        )
      }
    } else if (key === 'send_end_agreement') {
      const haveCompleting = (item.agreements_statuses || {}).completing;
      if (!haveCompleting) {
        return (
          <div className='buttonContainer'>
            <button className='outline' onClick={() => this.onSendAgreement('completing')}>
              Send Completing agreement
            </button>
          </div>
        )
      }
    } else if (key === 'call_fundraiser') {
      return (
        <div className='buttonContainer'>
          <button className='outline' onClick={this.makePhoneCall}>
            Call Fundraiser
          </button>
        </div>
      )
    } else if (key === 'payouts_list') {
      const payouts = this.props.item.payouts || [];
      return (
        <PayoutsList
          object={payouts}
          objectKey='payouts'
          onChange={this.props.onValueChange}
        />
      )
    } else if (key === 'logs_list') {
      const changes = this.state.changes || [];
      if (changes.length) {
        return (
          <LogsList object={changes}/>
        )
      }
    } else if (key === 'payout_button') {
      if (item.balance) {
        return (
          <div className='buttonContainer'>
            <button className='outline' onClick={this.requestPayout}>
              Create payout request
            </button>
          </div>
        )
      }
    } else if (key === 'movement_name') {
      if (!this.state || !this.state.movement || !this.state.movement.name) return;
      const value = this.state.movement.name || '';
      return (
        <div>
          <div id="input-movement_name" className="inputContainer">
            <label>Movement</label>
            <div className="inputElem">
              <div className="textInput disabled">
                <div className="textInputContainer">
                  <input type="text" readOnly defaultValue={value} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return this.props.renderInput(key);
    }
  }

  renderTabContent = () => {
    const sections = TABS.find(t => t.key === this.state.tab).sections || [];
    return (
      <div className={`inputsBlock ${this.state.tab}`}>

      {this.props.item.rollback_possibility ?
        <div className="blockSection card rollbackSection">
          <p>This campaign was changed by api. Click the button to rollback</p>
          <button
            className='outline'
            onClick={this.rollbackData}
          >
            Rollback
          </button>
        </div>
      : null}

        {sections.map((section, index) =>
          <div key={index} className='blockSection card'>
            {section.title ?
              <div className='sectionTitle'>{section.title}</div>
            : null}
            <div className='columns'>
              {(section.columns || []).map(key =>
                <div className='column' key={key}>{this.renderInput(key)}</div>
              )}
            </div>
            {(section.fields || []).map(key =>
              <div key={key}>{this.renderInput(key)}</div>
            )}
          </div>
        )}
      </div>
    )
  }

  onLogout = (unauthorized) => {
    if (!unauthorized) {
      sendRequest({
        type: 'POST',
        method: 'me/logout',
      });
    }
    Storage.setData('user', null);
    localStorage.removeItem('give-campaign-manager:token');
  }

  renderApproveButton = () => {
    const tabIndex = TABS.findIndex(tab => tab.key === this.state.tab);
    const nextTab = TABS[tabIndex + 1] && TABS[tabIndex + 1].key;
    const status = this.props.item.case_status;
    const { user } = this.props;
    if (status === 'pending_approval' && user.role !== 'case_supporter') {
      return (
        <button className='completeStep' onClick={() => this.publish()}>
          Approve
        </button>
      )
    } else if (status === 'pending') {
      return (
        <button
          className='completeStep'
          onClick={() => {
            this.switchTab(nextTab);
            this.changeStatus('pending_approval');
          }}
        >
          Submit for Approval
        </button>
      )
    }
  }

  renderCancelButton = () => {
    const status = this.props.item.case_status;
    const { user } = this.props;
    if (status === 'rejected') {
      return (
        <button
          className='outline outline1 outlineReject'
          onClick={() => this.changeStatus('pending')}
        >Re-open</button>
      )
    } else if (status === 'pending_approval' && user.role !== 'case_supporter') {
      return (
        <button
          className='outline outline1 outlineReject'
          onClick={() => this.changeStatus('pending')}
        >Decline</button>
      )
    } else if (status === 'published') {
       return (
         <button
           className='outline outline1 outlineReject'
           onClick={() => this.changeStatus('pending')}
         >Un-publish</button>
       )
    } else {
      return (
        <button
          className='outline outline1 outlineReject'
          onClick={() => this.rejectLead()}
        >Reject</button>
      )
    }
  }

  render = () => {
    const item = this.props.item;
    const header = `Campaign ${item.id || ''}`;
    const tabs = TABS.filter(tab =>
      !tab.showCondition || tab.showCondition()
    );
    return (
      <div className='leadEditView'>
        <HeaderView
          onLogout={this.onLogout}
          header={header}
          tagsLink={this.props.item.give_asia_url}
          tagsTitle={this.props.item.name}
          tagsContent={this.props.item.case_status}
          showBackLink={true}
        />
        <div className='quickActions'>
          Quick Action: <a href={this.props.item.give_asia_url} target='_blank' rel='noopener noreferrer' className='pageTitle'>Open Campaign Page</a>
        </div>
        <TabsView
          tabs={tabs}
          tab={this.state.tab}
          onTabChange={this.switchTab}
          compact
        />
        {this.renderTabContent()}
        <div
          className={classnames({
            'chatSidebar': true,
            'closed': !this.state.showChat,
            'sidebarWithActiveKeyboard': this.state.activeKeyboard
          })}
        >
          <ChatView
            embedded
            identifier={item.chat_identifier}
            onMessageAdded={this.onMessageAdded}
            onRegisterParticipant={data => this.setState({ participant: data })}
            onLoadNewMessages={data => this.setState({newMessagesCount: data.length || 0})}
            onReadMessages={data => this.setState({newMessagesCount: 0})}
            keepUnread={this.props.isMobile && this.state.bottomTab !== 'chat'}
            onShowKeyboard={this.onShowKeyboard}
          />
        </div>
        {this.props.isMobile ?
          <div className='bottomTabs'>
            {this.state.activeKeyboard
              ? null
              : <TabsView
                  tabs={[
                    { key: 'case', title: 'Case' },
                    { key: 'chat', title: 'Chat', itemCount:  this.state.newMessagesCount || 0 },
                  ]}
                  tab={this.state.bottomTab}
                  onTabChange={tab => this.switchBottomTab(tab)}
                  compact
                />}
          </div>
        : null}

        <div className="left-btn">
          <div className='buttonContainer left'>
            <button onClick={() => this.updateCampaign(this.state.tab, 'save')}>
              Save
            </button>
          </div>
        </div>

        <div className="right-btn">
          <div className='buttonContainer right'>
            {this.renderApproveButton()}
            {this.renderCancelButton()}
          <div>
        </div>

        </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStoreToProps)(withRouter(LeadEditView));
