import React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import { sendRequest, triggerEvent } from '../helpers/global.js';

import appConfig from '../config/';
import SelectInput from './input/SelectInput.js';

import '../sass/components/ListElem.scss';

const mapStoreToProps = (store) => {
  return {
    isMobile: store.setup.is_mobile,
    user: store.data.user,
  }
};

class GivikiListElem extends React.Component {

  constructor(props) {
    super(props);
    var state = {
      object: this.getDefaultObjectState(),
      properties: appConfig[props.configKey].properties,
      config: appConfig[props.configKey].config,
    };
    this.state = state;
    this.changeTimeout = null;
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.configKey !== prevProps.configKey) {
      this.setState({
        properties: appConfig[this.props.configKey].properties,
        config: appConfig[this.props.configKey].config,
        object: this.getDefaultObjectState()
      })
    } else if (this.props.object !== prevProps.object) {
      this.setState({object: this.getDefaultObjectState()});
    }
  }

  getDefaultObjectState = () => {
    let object = {};
    const properties = appConfig[this.props.configKey].properties;
    const keys = Object.keys(properties);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if ((typeof this.props.object[key] !== "undefined") && (this.props.object[key] !== null)) {
        object[key] = this.props.object[key];
      } else {
        if (Array.isArray(properties[key].default)) {
          object[key] = properties[key].default.slice();
        } else if (typeof properties[key].default === "object") {
          object[key] = Object.assign({}, properties[key].default);
        } else {
          object[key] = properties[key].default;
        }
      }
    }
    return object;
  }

  handleDelete = () => {
    this.props.onDelete(this.state.object)
  }

  saveChanges = (key, value) => {
    const itemConfig = this.state.properties[key];
    if (itemConfig.method) {
      sendRequest({
        method: itemConfig.method.replace(':id', this.state.object.id),
        type: "PUT",
        data: {
          [key]: value
        },
        success: (data) => {
          triggerEvent('showSnackbar', [{text: 'Data saved', type: 'success'}]);
        },
        error: (data) => {
        }
      });
    }
  }

  renderSelectInput = (key) => {
    const properties = this.state.properties[key] || {};
    const options = (this.props.options || {})[key] || [];
    return (
      <SelectInput
        properties={{options, clearable: properties.clearable}}
        object={this.state.object[key]}
        onChange={(k, value) => {
          this.props.onChange(this.state.object.id, key, value);
          this.saveChanges(key, value);
        }}
      />
    )
  }

  render = () => {
    const { properties } = this.state;
    const { id, organization_name, contact_name, country_list, notification_alert, status } = this.state.object;
    return (
      <div className='listElem card'>
        <div className='header'>
          <div
            className='page-link'
            onClick={() => window.open(properties.page_link.page.replace(':id', id || ''), '_blank')}
          >
            <span className='clickable'>{id}</span>
          </div>
          <div className={classnames({
            'updateIndicator': true,
            'new': notification_alert.new,
          })}>
            {notification_alert.new
              ? 'New'
              : `Last update ${moment.unix(notification_alert.updated).format('DD.MM.YYYY HH:mm')}`
            }
          </div>
        </div>
        <div className='content'>
          <div className='contentColumn'>
            <div className='infoBlock'>
              <label>{properties.organization_name.title}</label>
              <div
                  className='infoValue page-link'
              >
                <span>{organization_name}</span>
              </div>
            </div>
            <div className='infoBlock'>
              <label>{properties.contact_name.title}</label>
              <div
                className='infoValue page-link'
              >
                <span>{contact_name}</span>
              </div>
            </div>
            <div className='infoBlock'>
              <label>{properties.status.title}</label>
              {this.props.user.role === 'admin'
                ? this.renderSelectInput('status')
                : <div className='infoValue page-link'>
                    <span>{properties.status.options.find(option => option.id === status).name}</span>
                  </div>}
            </div>
          </div>
          <div className='contentColumn'>
            <div className='infoBlock'>
              <label>{properties.country_list.title}</label>
              <div className='infoValue'>
                {country_list}
              </div>
            </div>
            <div className='infoBlock'>
              <label>{properties.user_id.title}</label>
              {this.renderSelectInput('user_id')}
            </div>
          </div>
          <div className='contentColumn'>
            <div
              className='actionButton'
              onClick={() => window.open(properties.page_link.page.replace(':id', id || ''), '_blank')}
            >
              <span className='material-icons'>exit_to_app</span>
            </div>
            <div
                className='actionButton'
                onClick={() => this.handleDelete()}
            >
              <span className='material-icons'>delete</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStoreToProps)(withRouter(GivikiListElem))
