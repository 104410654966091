import React from 'react';
import classnames from 'classnames';

import '../../sass/components/common/BottomTabView.scss';

class BottomTabView extends React.Component {
  constructor(props) {
    super();
    this.state = {
    }
  }

  render = () => {
    return (
      <div
        className={classnames({
          'bottomTabView': true,
          'active': this.props.active,
        })}
        onClick={() => this.props.onSwitchTab(true)}
      >
        <div className='tab left'>
          {this.props.active
            ? <div
                className='icon close'
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.props.onSwitchTab(false)
                }}
              />
            : null}
        </div>
        <div className='tab center'>
          {!this.props.active ? <div className={`icon ${this.props.headerIcon}`} /> : null}
          <div className='tabHeader'>{this.props.header}</div>
        </div>
        <div className='tab right'>
          {!this.props.active && this.props.itemCount
            ? <div className='itemCount'>{this.props.itemCount}</div>
            : null}
        </div>
      </div>
    )
  }

}

export default BottomTabView;
