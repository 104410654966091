export default {
  config: {
    type: "settings",
    method: "me/settings/",
    title: "Account Settings",
  },
  properties: {
    email: {
      default: "",
      type: "text",
      title: "Email",
    },
    name: {
      default: "",
      type: "text",
      title: "Name",
    },
    image: {
      default: "",
      type: "image",
      title: "Image",
    },
    password: {
      default: "",
      type: "password",
      title: "Password",
    },
    phone: {
      default: "",
      type: "text",
      title: "Phone",
    },
  }
};
