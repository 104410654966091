import React from 'react';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import { withRouter } from "react-router-dom";

import appConfig from '../config/';
import { sendRequest, triggerEvent } from '../helpers/global.js';

import SearchView from './SearchView';
import GivikiListElem from './GivikiListElem';
import Pagination from './common/Pagination';

import '../sass/components/ListView.scss';

const ITEMS_PER_PAGE = 10;

const mapStoreToProps = (store) => {
  return {
    isMobile: store.setup.is_mobile,
  }
};

class GivikiListView extends React.Component {

  constructor(props) {
    super(props);
    this.state = this.getDefaultState(props);
  }

  getDefaultState = (props) => {
    const properties = appConfig[props.configKey].properties;
    const config = appConfig[props.configKey].config;
    let search = {};
    // Default search interval
    if (config.search) {
      Object.keys(config.search).forEach((key) => {
        search[key] = config.search[key].default || null;
      });
    }
    this.props.history.location.search.replace(/^\?/, '').split('&').forEach(p => {
      const pair = p.split('=');
      if (pair[0]) {
        search[pair[0]] = pair[1] || true;
      }
    });
    const page = (Number(this.props.location.hash.replace(/\D/g, '')) || 1) - 1;
    return {
      objects: [],
      properties,
      config, 
      options: {},
      search,
      page,
      maxPage: 0,
    };
  }

  componentDidMount = () => {
    if (!this.state.config.search) {
      this.requestData();
    }
    this.requestOptions();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.configKey !== prevProps.configKey) {
      this.setState(this.getDefaultState(this.props), () => {
        this.requestData();
        this.requestOptions();
      })
    }
  }

  requestOptions = () => {
    let options = Object.assign({}, this.state.options);
    Object.keys(this.state.properties).forEach(key => {
      if (this.state.properties[key].width && this.state.properties[key].type === "select") {
        if (this.state.properties[key].request) {
          sendRequest({
            type: "GET",
            method: this.state.properties[key].request,
            success: (data) => {
              this.setState({options:
                update(this.state.options, {
                  [key]: {$set: data}
                })
              });
            },
            error: (data) => {}
          });
        } else if (this.state.properties[key].options) {
          options[key] = this.state.properties[key].options;
        }
      }
    });
    this.setState({options});
  }

  requestData = () => {
    const search = this.state.search;
    const searchParams = Object.keys(search)
      .filter(key => key && search[key] !== null && search[key] !== undefined && search[key] !== '')
      .map(key => `${key}=${search[key]}`)
      .join('&');
    this.props.history.replace(`${this.props.location.pathname}?${searchParams}#${this.state.page + 1}`);
    sendRequest({
      method: this.state.config.method,
      data: {
        paginate: true,
        offset: this.state.page * ITEMS_PER_PAGE,
        ...search,
      },
      type: "GET",
      success: (data) => {
        const page = this.state.page;
        const maxPage = Math.ceil(data.count / ITEMS_PER_PAGE);
        this.setState({
          objects: data.objects,
          maxPage: maxPage,
          page: Math.min(page, maxPage),
        });
      },
      error: (data) => {
      }
    });
  }

  onObjectDelete = (object) => {
    triggerEvent('showConfirmation', [{
      title: `Are you sure want to delete ${this.state.config.objectName} #${object.id}?`,
      confirmText: 'Delete',
      cancelText: 'Cancel',
      callback: confirm => {
        if (confirm) {
          this.onDeleteConfirm(object);
        }
      }
    }]);
  }

  onDeleteConfirm = (object) => {
    sendRequest({
      method: this.state.config.method + object.id,
      type: "DELETE",
      success: (data) => {
        this.requestData(this.state);
      },
      error: (data) => {
      }
    });
  }

  onSearch = (search, initial) => {
    let data = this.state.search;
    Object.keys(search).forEach(key => {
      if (search[key] === undefined || search[key] === null) {
        data[key] = null;
      } else {
        data[key] = search[key];
      }
    });
    this.setState({page: initial ? this.state.page : 0, search: data}, () => {
      this.requestData();
    });
  }

  onObjectCreate = (e) => {
    this.props.history.push(`/${this.props.configKey}/create`);
  }

  onObjectChange = (id, key, value) => {
    const index = this.state.objects.findIndex(i => i.id === id);
    this.setState({objects:
      update(this.state.objects, {
        [index]: {
          [key]: {$set: value}
        }
      })
    });
  }

  renderPagination = () => {
    if (this.state.maxPage <= 1) {
      return null;
    }
    return (
      <Pagination
        page={this.state.page + 1}
        maxPage={this.state.maxPage}
        onPageChange={page => {
          this.setState({page: page - 1}, this.requestData);
        }}
      />
    )
  }

  renderSearch = () => {
    if (this.state.config.search) {
      return (
        <SearchView
          initialValue={this.state.search}
          config={this.state.config.search}
          onSearch={this.onSearch}
        />
      )
    }
    return null;
  }

  renderObjects = () => {
    return (
      this.state.objects.map(object =>
        <GivikiListElem
          key={object.id}
          configKey={this.props.configKey}
          onDelete={this.onObjectDelete}
          onChange={this.onObjectChange}
          options={this.state.options}
          object={object}
        />
      )
    )
  }

  render = () => {
    let config = this.state.config;

    let addButton = <div
      className='createButton'
      onClick={this.onObjectCreate}
    >
      <span className='material-icons'>add</span>
    </div>
    if (config.modifyCondition && !config.modifyCondition()) {
      addButton = null;
    }
    if (config.disableAdd) {
      addButton = null;
    }

    return (
      <div className='listView'>
        {this.renderSearch()}
        {this.renderObjects()}
        {this.renderPagination()}
        {addButton}

      </div>
    );
  }
}

export default connect(mapStoreToProps)(withRouter(GivikiListView));
