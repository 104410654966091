import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import appConfig from '../config/';
import { sendRequest } from '../helpers/global.js';

import DocumentsViewItem from './DocumentsViewItem';
import SearchView from './SearchView';
import Pagination from './common/Pagination';

import '../sass/components/DocumentsView.scss';

const ITEMS_PER_PAGE = 20;

const mapStoreToProps = (store) => {
  return {
    isMobile: store.setup.is_mobile,
  }
};

class DocumentsView extends React.Component {

  constructor(props) {
    super(props);
    this.state = this.getDefaultState(props);
  }

  getDefaultState = (props) => {
    const properties = appConfig[props.configKey].properties;
    const config = appConfig[props.configKey].config;
    let search = {};
    // Default search interval
    if (config.search) {
      Object.keys(config.search).forEach((key) => {
        search[key] = config.search[key].default || null;
      });
    }
    this.props.history.location.search.replace(/^\?/, '').split('&').forEach(p => {
      const pair = p.split('=');
      search[pair[0]] = pair[1] || true;
    });
    return {
      objects: [],
      properties,
      config,
      search,
      page: 0,
      maxPage: 0,
    };
  }

  componentDidMount = () => {
    if (!this.state.config.search) {
      this.requestData();
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.configKey !== prevProps.configKey) {
      this.setState(this.getDefaultState(this.props), () => {
        this.requestData();
      })
    }
  }

  requestData = () => {
    sendRequest({
      method: this.state.config.method,
      data: {
        paginate: true,
        offset: this.state.page * ITEMS_PER_PAGE,
        ...this.state.search,
      },
      type: "GET",
      success: (data) => {
        const page = this.state.page;
        const maxPage = Math.ceil(data.count / ITEMS_PER_PAGE);
        this.setState({
          objects: data.objects,
          maxPage: maxPage,
          page: Math.min(page, maxPage),
        });
      },
      error: (data) => {
      }
    });
  }

  onSearch = (search) => {
    let data = this.state.search;
    Object.keys(search).forEach(key => {
      if (search[key] === undefined || search[key] === null) {
        data[key] = null;
      } else {
        data[key] = search[key];
      }
    });
    this.setState({page: 0, search: data}, () => {
      this.requestData();
    });
  }

  renderPagination = () => {
    if (this.state.maxPage <= 1) {
      return null;
    }
    return (
      <Pagination
        page={this.state.page + 1}
        maxPage={this.state.maxPage}
        onPageChange={page => 
          this.setState({page: page - 1}, this.requestData)
        }
      />
    )
  }

  renderSearch = () => {
    if (this.state.config.search) {
      return (
        <SearchView
          config={this.state.config.search}
          onSearch={this.onSearch}
        />
      )
    }
    return null;
  }

  renderObjects = () => {
    return (
      this.state.objects.map(object =>
        <DocumentsViewItem
          key={object.id}
          object={object}
          method={this.state.config.method}
          title={this.state.config.objectName}
          onChange={(updated) => {
            this.setState({objects:
              this.state.objects.map(i => i.id === object.id ? updated : i)
            })
          }}
          onDelete={() => {
            this.setState({objects:
              this.state.objects.filter(i => i.id !== object.id)
            })
          }}
        />
      )
    )
  }

  render = () => {
    return (
      <div className='documentsView'>

        {this.renderSearch()}

        {this.props.isMobile
          ? this.renderObjects()
          : <div className="card">
            <table>
              <thead>
                <tr>
                  <th width="60px">#</th>
                  <th width="150px">Creation time</th>
                  <th width="auto">Lead</th>
                  <th width="60px"></th>
                  <th width="260px">File</th>
                  <th width="190px">Folder</th>
                  <th width="44px"></th>
                </tr>
              </thead>
              <tbody>
                {this.renderObjects()}
              </tbody>
            </table>
          </div>
        }

        {this.renderPagination()}

      </div>
    );
  }
}

export default connect(mapStoreToProps)(withRouter(DocumentsView));
