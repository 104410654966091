import React from 'react';
import { withRouter } from 'react-router-dom';
import { sendRequest } from '../helpers/global.js';

import LogsList from './input/LogsList.js';

class SettingChanges extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      changes: []
    };
  }

  componentDidMount = () => {
    this.requestChanges();
  }

  requestChanges = () => {
    sendRequest({
      method: 'setting_changes',
      type: 'GET',
      success: (data) => {
        this.setState({ changes: data });
      },
      error: (data) => {
      }
    });
  }

  render = () => {
    const { changes } = this.state;
    return (
      <div className='settingChanges'>
        <LogsList object={changes} withEntity />
      </div>
    );
  }
}

export default (withRouter(SettingChanges))
