export default {
  section: "Personal Team",
  config: {
    method: "payouts/",
    title: "Search Payouts",
    objectName: "Payout",
    disableAdd: true,
    readOnly: true,
    bottomControls: [
      require('../components/editControls/PayoutBottomControlView').default,
    ],
    search: {
      status: {
        type: "select",
        title: "Status",
        request: "payouts/statuses/",
        clearable: true,
      },
      lead_id: {
        type: "select",
        title: "Lead",
        request: "leads/by_name",
        async: true,
        clearable: true,
      },
    },
  },
  properties: {
    id: {
      default: false,
      type: "text",
      title: "#",
      preset: true,
      width: "60px",
    },
    lead: {
      type: "page-link",
      page: "/leads/:id",
      title: "Lead",
      preset: true,
    },
    lead_list: {
      type: "page-link",
      page: "/leads/:id",
      title: "Lead",
      width: "auto",
      showCondition: () => false,
    },
    identifier: {
      type: "text",
      title: "Identifier",
      preset: true,
    },
    status: {
      type: "text",
      title: "Status",
      width: "auto",
      preset: true,
    },
    country: {
      type: "text",
      title: "Country",
      preset: true,
    },
    swift_code: {
      type: "text",
      title: "SWIFT code",
      preset: true,
    },
    account_name: {
      type: "text",
      title: "Bank Account Name",
      preset: true,
    },
    account_number: {
      type: "text",
      title: "Bank Account Number",
      preset: true,
    },
    amount: {
      type: "text",
      title: "Amount to withdraw",
      width: "auto",
      preset: true,
    },
    documents: {
      default: [],
      type: "documents-list",
      title: "Approving documents",
      uploadId: "payout_id",
      uploadLink: object => `/payout_form/${object.identifier}`,
      uploadLinkTitle: () => 'Payout form',
    },

  }
};
