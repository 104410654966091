import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';

import { sendRequest, triggerEvent, eventOn } from './helpers/global.js';
import Storage from './helpers/Storage.js';
import appConfig from './config/';
import NotificationsController from './helpers/NotificationsController';

import LoginView from './components/LoginView.js';
import HeaderView from './components/HeaderView.js';
import LoadIndicator from './components/LoadIndicator';
import SelectOptionPopup from './components/common/SelectOptionPopup';
import ConfirmationPopup from './components/common/ConfirmationPopup';
import PhoneCallPopup from './components/common/PhoneCallPopup';
import ContentPopup from './components/common/ContentPopup';
import FramePopup from './components/common/FramePopup';
import NotificationPopup from './components/common/NotificationPopup';
import Snackbar from './components/common/Snackbar';
import CarouselPopup from "./components/common/CarouselPopup";
import RejectCampaignPopup from './components/common/RejectCampaignPopup';
import UploadingPopup from './components/common/UploadingPopup';

import ObjectListView from './components/ObjectListView.js';
import ObjectEditView from './components/ObjectEditView.js';

import DocumentsView from './components/DocumentsView.js';
import DashboardView from './components/DashboardView.js';
import SettingsView from './components/SettingsView.js';
import UploadFilesView from './components/UploadFilesView.js';
import PayoutFormView from './components/PayoutFormView.js';
import VideoRoomView from './components/VideoRoomView.js';
import ChatView from './components/ChatView.js';
import NotificationsView from './components/NotificationsView.js';
import AggregatedView from './components/AggregatedView.js';
import ChatListView from './components/ChatListView.js';
import GivikiFormView from './components/GivikiFormView.js';
import LeadListView from './components/LeadListView.js';
import GivikiListView from './components/GivikiListView.js';
import MarketingTodoList from './components/MarketingTodoList';
import SettingChanges from './components/SettingChanges';
import SendAgreementPopup from './components/SendAgreementPopup.js';
import AddMissingLeadView from './components/AddMissingLeadView.js';

const adminListRoutes = Object.keys(appConfig).map(key => `/${key}`);
const adminEditRoutes = Object.keys(appConfig).map(key => `/${key}/:id`);

const mapStoreToProps = (store) => ({
  user: store.data.user,
  isMobile: store.setup.is_mobile,
});

const AdminRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => {
    if (!rest.user) {
      if (process.env.REACT_APP_GIVE_ASIA_URL) {
        window.location = process.env.REACT_APP_GIVE_ASIA_URL;
      }
      return;
    }
    const key = props.location.pathname.substr(1).split('/')[0];
    if (!appConfig[key] && ['notifications'].indexOf(key) < 0) return <Redirect to='/leads'/>
    if (appConfig[key].config.adminOnly && rest.user.role !== 'admin') return <Redirect to='/leads'/>
    return <Component {...props} configKey={key}/>
  }}/>
)

const LoginRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    !rest.user ? <Component {...props}/> : <Redirect to='/leads'/>
  )}/>
)

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showLoadIndicator: false,
    };
    this.loadCount = 0;

    eventOn('addLoad', this.addLoad);
    eventOn('removeLoad', this.removeLoad);
    eventOn('unauthorized', this.onUnauthorized);
    eventOn('forbidden', this.onForbidden);

    eventOn('resize', (e) => {
      Storage.setSetup('is_mobile', window.innerWidth <= 768);
    }, window);
    Storage.setSetup('is_mobile', window.innerWidth <= 768);
  }

  componentDidUpdate = (prevProps, prevState) => {
  }

  addLoad = () => {
    this.loadCount++;
    if (this.state.showLoadIndicator !== (this.loadCount > 0)) {
      this.setState({showLoadIndicator: this.loadCount > 0});
    }
  }

  removeLoad = () => {
    this.loadCount--;
    if (this.state.showLoadIndicator !== (this.loadCount > 0)) {
      this.setState({showLoadIndicator: this.loadCount > 0});
    }
  }

  onLogout = (unauthorized) => {
    if (!unauthorized) {
      sendRequest({
        type: 'POST',
        method: 'me/logout',
      });
    }
    Storage.setData('user', null);
    localStorage.removeItem('give-campaign-manager:token');
  }

  onUnauthorized = () => {
    triggerEvent('showSnackbar', [{text: 'Your access token has been expired. Please re-login.', type: 'error'}]);
    this.onLogout(true);
  }

  onForbidden = () => {
    triggerEvent('showSnackbar', [{text: 'You must be an admin to access this page.', type: 'error'}]);
    this.props.history.goBack();
  }

  componentDidMount = () => {
    if (this.props.user) {
      sendRequest({
        method: 'me/',
        type: 'GET',
        success: (data) => {
          Storage.setData('user', data);
        },
        error: (data) => {
        }
      });
    }
  }

  render = () => {
    const user = this.props.user;
    const pathname = this.props.location.pathname + this.props.location.hash;
    let hideUI = false;
    let hideScrollBar = false;

    if (pathname.includes('aggregated')) {
      hideUI = false
    } else if (pathname.includes('leads/') || pathname.includes('campaign') || pathname.includes('givikis/')) {
      hideUI = true
    } else if (!user || pathname === '/giviki_form') {
      hideUI = true
    }

    if (pathname.includes('#chat') && this.props.isMobile) {
      hideScrollBar = true;
    }

    return (
      <div>
        {hideUI ? null : <HeaderView
          onLogout={this.onLogout}
        />}
        <div className={classnames({
          'adminContent': true,
          'customScrollBar': true,
          'showUI': !hideUI,
          'showScrollBar': !hideScrollBar,
        })}>
          <Switch>
            <Route path='/chats/:identifier' component={ChatView}/>
            <Route path='/video_rooms/:identifier' component={VideoRoomView}/>
            <Route path='/payout_form/:identifier' component={PayoutFormView}/>
            <Route path='/library/:type/:identifier' component={UploadFilesView}/>
            <Route path='/campaign/:identifier' component={AggregatedView}/>
            <Route path='/aggregated_givikis/:identifier' component={AggregatedView}/>
            <Route path='/giviki_form' component={GivikiFormView}/>
            <AdminRoute user={user} path='/leads/add_missing' component={AddMissingLeadView}/>
            <AdminRoute user={user} path='/chats' component={ChatListView}/>
            <AdminRoute user={user} path='/notifications' component={NotificationsView}/>
            <AdminRoute user={user} path='/settings' component={SettingsView}/>
            <AdminRoute user={user} path='/statistics' component={DashboardView}/>
            <AdminRoute user={user} path='/documents' component={DocumentsView}/>
            <AdminRoute user={user} path={adminEditRoutes} component={ObjectEditView}/>
            <AdminRoute user={user} path='/leads' component={LeadListView}/>
            <AdminRoute user={user} path='/givikis' component={GivikiListView}/>
            <AdminRoute user={user} path='/marketing_todos' component={MarketingTodoList}/>
            <AdminRoute user={user} path='/setting_changes' component={SettingChanges}/>
            <AdminRoute user={user} path={adminListRoutes} component={ObjectListView}/>
            <LoginRoute user={user} path='/admin' component={LoginView}/>
            <AdminRoute user={user} path='/' component={LoginView}/>
          </Switch>
        </div>

        <LoadIndicator show={this.state.showLoadIndicator}/>
        <NotificationsController/>
        <ConfirmationPopup global/>
        <SelectOptionPopup global/>
        <PhoneCallPopup global/>
        <ContentPopup global/>
        <FramePopup global/>
        <CarouselPopup global/>
        <SendAgreementPopup global/>
        <RejectCampaignPopup global/>
        <UploadingPopup global/>
        <NotificationPopup/>
        <Snackbar/>
      </div>
    )
  }
}

export default connect(mapStoreToProps)(withRouter(App));
