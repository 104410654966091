import React from 'react';
import classnames from 'classnames';
import CSSTransition from 'react-transition-group/CSSTransition';
import TextInput from './input/TextInput.js';
import { sendRequest, triggerEvent, eventOn, eventOff, validateEmail } from '../helpers/global.js';

import '../sass/components/SendAgreementPopup.scss';

class SendAgreementPopup extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      hidden: !props.show,
      show: true,
      email: null,
      type: null,
      entity: null
    };
  }

  componentDidMount = () => {
    if (this.props.global) {
      eventOn('openSendAgreementPopup', this.openSendAgreementPopup);
    }
  }

  componentWillUnmount = () => {
    if (this.props.global) {
      eventOff('openSendAgreementPopup', this.openSendAgreementPopup);
    }
  }

  openSendAgreementPopup = (e) => {
    const { type, id, email, entity } = e.detail[0];

    this.setState({
      hidden: false,
      show: true,
      type,
      id,
      email,
      entity
    });
  }

  close = () => {
    this.setState({hidden: true})
  }

  handleChange = (k, value) => {
    this.setState({email: value})
  }

  sendAgreement = () => {
    const emailValid = validateEmail(this.state.email)

    if (!emailValid) {
      triggerEvent('showSnackbar', [{text: 'Email is invalid', type: 'error'}]);
    } else {
      const method = `${this.state.entity}/${this.state.id}/agreement`;
      sendRequest({
        method: method,
        type: 'POST',
        data: {
          email: this.state.email,
          agreement_type: this.state.type,
          donation_reason: this.state.reason,
        },
        success: (data) => {
          this.setState({ hidden: true });
          triggerEvent('showNotification', [{title: 'You have been sent agreement to sign.'}]);
        },
        error: (data) => {
          if (data.error) {
            triggerEvent('showSnackbar', [{text: data.error, type: 'error'}]);
          }
        }
      });
    }
  }

  render = () => {
    const { show, type, email } = this.props.global ? this.state : this.props;

    return (
      <div
        className={classnames({
          'contentPopup': true,
          'sendAgreementPopup': true,
          'hidden': this.state.hidden,
        })}
      >

        <CSSTransition
          in={show}
          timeout={350}
          classNames='fade'
          onEnter={() => this.setState({hidden: false})}
          onExited={() => this.setState({hidden: true})}
        >
          <div
            onClick={this.onOverlayClick}
            className='overlay'
          >
            <div className='popupWindow'>
              <div className='popupTitle'>Enter email</div>
              <div className='closeButton' onClick={() => this.close()}/>
              <div className='contentContainer'>

              {type === 'completing' ?
                <TextInput
                  properties={{type: 'text'}}
                  placeholder='Donation reason'
                  object={this.state.reason}
                  onChange={(k, value) => this.setState({reason: value})}
                />
              : null}

                <TextInput
                  properties={{type: 'text'}}
                  placeholder='Email'
                  object={email}
                  onChange={(k, value) => this.handleChange(k, value)}
                />

                <span
                  className='emailNotice'
                >
                  Only one email address; comma separated emails are not supported
                </span>
              </div>
              <div className='popupControls'>
                <button
                  onClick={() => this.sendAgreement()}
                  disabled={!this.state.email}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </CSSTransition>
      </div>
    )
  }
}

export default SendAgreementPopup;
