import React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import update from 'immutability-helper';

import { sendRequest } from '../helpers/global.js';

import ChatView from './ChatView.js';

import '../sass/components/ChatListView.scss';

class ChatListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      selected: null,
      chats: [],
    }
  }

  componentDidMount = () => {
    sendRequest({
      method: 'chats',
      type: 'GET',
      success: (data) => {
        this.setState({chats: data});
      },
      error: (data) => {
      }
    });
  }

  componentDidUpdate = (prevProps, prevState) => {
  }

  onMessageAdded = (data) => {
    const index = this.state.chats.findIndex(chat => chat.identifier === this.state.selected);
    this.setState({chats:
      update(this.state.chats, {
        [index]: {
          last_message: {$set: data.message}
        }
      })
    });
  }

  renderChatItem = (chat) => {
    const lastMessage = chat.last_message;
    const lastDate = lastMessage ? moment.unix(lastMessage.created_at) : null;
    return (
      <div
        key={chat.identifier}
        className='chatItem'
        onClick={() => this.setState({selected: chat.identifier})}
      >
        <div className='chatTitle'>{chat.title}</div>
        <div className='chatMessage'>
          {lastMessage ?
            lastMessage.file ? lastMessage.file.name : lastMessage.content
          : ''}
        </div>
        <div className='chatTime'>
          {lastDate ?
            lastDate.isSame(moment(), 'd')
            ? lastDate.format('HH:mm')
            : lastDate.isSame(moment(), 'year')
              ? lastDate.format('DD.MM')
              : lastDate.format('YYYY')
          : ''}
        </div>
      </div>
    )
  }

  render = () => {
    const search = this.state.search;
    const chats = this.state.chats.filter(chat =>
      !search || (chat.title || '').toLocaleLowerCase().match(search.toLocaleLowerCase())
    )
    return (
      <div
        className={classnames({
          'chatListView': true,
          'chatSelected': !!this.state.selected,
        })}
      >
        <div className='chatListContinaer'>
          <div className='chatListSection'>
            <div className='searchBox'>
              <div className='searchIcon'>
                <span className='material-icons'>search</span>
              </div>
              <input
                type='text'
                value={search}
                onChange={e => this.setState({search: e.target.value})}
              />
            </div>
            <div className='chatList'>
              {chats.map(this.renderChatItem)}
            </div>
          </div>
          <div className='chatContentSection'>
            {this.state.selected ?
              <ChatView
                embedded
                identifier={this.state.selected}
                onMessageAdded={this.onMessageAdded}
                onClose={() => this.setState({selected: null})}
                chatLists
              />
            : null}
          </div>
        </div>
      </div>
    )
  }
}

export default ChatListView;
