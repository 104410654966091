import React from 'react';
import classnames from 'classnames';

import { eventOn, eventOff } from '../../helpers/global';

import '../../sass/components/common/CaptureAudio.scss';
import 'balloon-css';

class CaptureAudio extends React.Component {
  constructor(props) {
    super();
    this.state = {
      show: false,
      callback: null,
      recording: false,
      recorded: false,
      starting: false,
      playing: false,
      startEvent: props.startEvent,
      audioData: new Uint8Array(0),
    };
    this.container = null;
    this.canvas = null;
    this.stream = null;
    this.mediaRecorder = null;
    this.recordedBlobs = null;
    this.onChangeAudio = null;
    this.onChangeVoiceRecodring = null;
    this.audio = null;
    this.disabled = null;
  }

  componentDidMount = () => {
    eventOn(this.state.startEvent, this.captureAudio);
  }

  componentWillUnmount = () => {
    this.stopAudio();
    eventOff(this.state.startEvent, this.captureAudio);
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.canvas) {
      this.drawCanvas();
    }
  }

  captureAudio = (e) => {
    const { callback } = e.detail[0];
    this.setState({
      show: true,
      callback,
      recording: false,
      recorded: false,
      starting: false,
      playing: false,
    }, () => {
      this.startAudio()
    });
  }

  startAudio = () => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
      this.stream = stream;
      this.recorder = new MediaRecorder(stream)
      this.bindAudio();
    });
  }

  bindAudio = (stream) => {
    this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
    this.analyser = this.audioContext.createAnalyser();
    this.dataArray = new Uint8Array(this.analyser.frequencyBinCount);
    this.source = this.audioContext.createMediaStreamSource(stream);
    this.source.connect(this.analyser);
    this.rafId = requestAnimationFrame(this.audioTick);
  }

  audioTick = () => {
    this.analyser.getByteTimeDomainData(this.dataArray);
    this.setState({ audioData: this.dataArray });
    this.rafId = requestAnimationFrame(this.audioTick);
  }

  stopAudio = () => {
    if (this.recorder && this.recorder.stream) {
      this.recorder.stream.getTracks().forEach(i => i.stop())
    }
    if (this.rafId) {
      cancelAnimationFrame(this.rafId);
    }
    if (this.analyser) {
      this.analyser.disconnect();
    }
    if (this.source) {
      this.source.disconnect();
    }
  }

  onRecordingSwitch = () => {
    if (!this.props.disabled) {
      if (this.state.recording) {
        this.recorder.stop();
      } else {
        this.startRecording();
      }
    }
  }

  startRecording = () => {
    this.setState({
      recording: true,
      recorded: false,
      starting: false,
    }, () => {
      this.startMicRecording();
    });
  }

  startMicRecording = () => {
    this.recordedBlobs = [];

    navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
      this.recorder = new MediaRecorder(stream);
      this.stream = stream;
      this.props.onChangeVoiceRecodring(true);
      this.bindAudio(stream);

      this.recorder.addEventListener('stop', event => {
        console.log('Recorded Blobs: ', this.recordedBlobs);
        this.setState({
          recording: false,
          recorded: true,
        }, () => {
          this.showRecorded();
        });
      });
      this.recorder.addEventListener('dataavailable', event => {
        if (event.data && event.data.size > 0) {
          this.recordedBlobs.push(event.data);
        }
      });
      this.recorder.start();
    });
  }

  showRecorded = () => {
    const type = this.recordedBlobs[0].type;
    const superBuffer = new Blob(this.recordedBlobs, {type: type});
    this.recordedRef.src = window.URL.createObjectURL(superBuffer);
    this.setState({audio: this.recordedRef.src});
    this.props.onChangeAudio(this.recordedRef.src);
    this.props.onChangeVoiceRecodring(false);
    this.stopAudio();
  }

  drawCanvas = () => {
    if (!this.state.recorded) {
      const audioData = this.state.audioData;
      const canvas = this.canvas;
      const height = canvas.height;
      const width = canvas.width;
      const context = canvas.getContext('2d');
      let x = 0;
      const sliceWidth = (width * 1.0) / audioData.length;
      context.lineWidth = 2;
      context.strokeStyle = '#ff3c4c';
      context.clearRect(0, 0, width, height);
      context.beginPath();
      context.moveTo(0, height / 2);
      for (const item of audioData) {
        const y = (item / 255.0) * height;
        context.lineTo(x, y);
        x += sliceWidth;
      }
      context.lineTo(x, height / 2);
      context.stroke();
    }
  }

  renderAudioControls = () => {
    let controls = null;
    let switchButton = null;
    if (this.state.recording) {
      controls = null;
    } else {
      if (this.state.recorded && this.recordedRef) {
        switchButton = <div
          className={classnames({
            'recordButton': true,
            'recording': this.state.recording,
          })}
          onClick={this.onRecordingSwitch}
        >
        { !this.state.recording
          ? <div
              className={classnames({
                'mic': true,
                'aggregatedLeadView': this.props.aggregatedLeadView,
              })}
              onClick={this.state.file ? this.sendFile : this.sendMessage}
            />
          : null }
        </div>

      } else if (!this.state.starting) {
        controls = null;
      }
    }
    if (!this.state.recorded && !this.state.starting) {
      switchButton = <div
        className={classnames({
          'recordButton': true,
          'recording': this.state.recording,
        })}
        onClick={this.onRecordingSwitch}
      >
      { !this.state.recording
        ? <div
            className={classnames({
              'mic': true,
              'aggregatedLeadView': this.props.aggregatedLeadView,
            })}
            onClick={this.state.file ? this.sendFile : this.sendMessage}
          />
        : null }
      </div>
    }

    return (
      <div className='audioControls'>
        {controls}
        {switchButton}
      </div>
    )
  }

  render = () => {
    return (
      <div
        className={classnames({
          'captureAudio': true,
          'recording': this.state.recording,
        })}
        ref={div => this.container = div}
      >
        <audio
          ref={video => this.recordedRef = video}
        />

        {this.renderAudioControls()}
        {this.state.recording ?
          <canvas
            width={this.container ? this.container.clientWidth : 0}
            height="80"
            ref={canvas => this.canvas = canvas}
          /> :null
        }

      </div>
    )
  }
}

export default CaptureAudio;
