export default {
  section: "Settings",
  config: {
    method: "marketing_todos/",
    title: "Modify Marketing Todos",
    objectName: "MarketingTodo",
    disableDelete: true,
    adminOnly: true,
  },
  properties: {
    id: {
      default: false,
      type: "text",
      title: "#",
      preset: true,
      width: "60px",
    },
    description: {
      type: "text",
      title: "Description",
      width: "auto",
    }
  }
};
