import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import { sendRequest, triggerEvent } from '../helpers/global.js';

import appConfig from '../config/';
import UserAvatarView from './UserAvatarView.js';
import SelectInput from './input/SelectInput.js';
import TextInput from './input/TextInput.js';

import '../sass/components/ObjectListElem.scss';

const mapStoreToProps = (store) => {
  return {
    isMobile: store.setup.is_mobile,
  }
};

class ObjectListElem extends React.Component {

  constructor(props) {
    super(props);
    var pageConfig = appConfig[props.configKey];
    var state = {
      object: this.getDefaultObjectState(),
      properties: pageConfig.properties,
      config: pageConfig.config,
    };
    this.state = state;
    this.changeTimeout = null;
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.configKey !== prevProps.configKey) {
      this.setState({
        properties: appConfig[this.props.configKey].properties,
        config: appConfig[this.props.configKey].config,
        object: this.getDefaultObjectState()
      })
    } else if (this.props.object !== prevProps.object) {
      this.setState({object: this.getDefaultObjectState()});
    }
  }

  getDefaultObjectState = () => {
    let object = {};
    const pageConfig = appConfig[this.props.configKey];
    const properties = pageConfig.properties;;
    const keys = Object.keys(properties);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if ((typeof this.props.object[key] !== "undefined") && (this.props.object[key] !== null)) {
        object[key] = this.props.object[key];
      } else {
        if (Array.isArray(properties[key].default)) {
          object[key] = properties[key].default.slice();
        } else if (typeof properties[key].default === "object") {
          object[key] = Object.assign({}, properties[key].default);
        } else {
          object[key] = properties[key].default;
        }
      }
    }
    return object;
  }

  handleEdit = () => {
    this.props.onEdit(this.state.object);
  }

  handleDestroy = () => {
    this.props.onDelete(this.state.object);
  }

  openChatPage = () => {
    const item = this.state.object;
    const tab = window.open();
    if (item.chat_identifier) {
      tab.location = `/chats/${item.chat_identifier}`;
      return;
    }
    sendRequest({
      method: `${this.state.config.method}/${item.id}/chat`,
      type: 'GET',
      success: (data) => {
        tab.location = `/chats/${data.identifier}`;
      },
      error: (data) => {
        tab.close();
      }
    });
  }

  saveChanges = (key, value) => {
    const itemConfig = this.state.properties[key];
    if (itemConfig.method) {
      sendRequest({
        method: itemConfig.method.replace(':id', this.state.object.id),
        type: "PUT",
        data: {
          [key]: value
        },
        success: (data) => {
          triggerEvent('showSnackbar', [{text: 'Data saved', type: 'success'}]);
        },
        error: (data) => {
        }
      });
    }
  }

  renderActiveIcon = () => {
    return (
      <span
        className='material-icons'
        style={{margin: '-5px 0', color: '#2E7D32'}}
      >done</span>
    )
  }

  renderInactiveIcon = () => {
    return (
      <span
        className='material-icons'
        style={{margin: '-5px 0', color: '#cccccc'}}
      >close</span>
    )
  }

  renderCellContent = (key) => {
    let properties = this.state.properties[key];
    if (!properties.width) {
      return null;
    }
    let content = this.state.object[key];
    if (properties.type === "avatar") {
      content = <UserAvatarView
        user={this.state.object}
        size={30}
        style={{margin: '-5px 0'}}
        image={content}
      />
    }
    if (properties.type === "select") {
      if (properties.listEdit) {
        const options = (this.props.options || {})[key] || [];
        content = <SelectInput
          properties={{options, clearable: properties.clearable}}
          object={this.state.object[key]}
          onChange={(k, value) => {
            this.props.onChange(this.state.object.id, key, value);
            this.saveChanges(key, value);
          }}
        />
      } else if (typeof content === 'boolean') {
        content = content ? this.renderActiveIcon() : this.renderInactiveIcon();
      } else {
        const options = (this.props.options || {})[key] || []
        const item = options.find(option => option.id === this.state.object[key]);
        if (item) {
          let displayKey = properties.displayField || "name";
          content = item[displayKey];
        } else {
          content = "";
        }
      }
    }
    if (properties.type === "checkbox") {
      content = content ? this.renderActiveIcon() : this.renderInactiveIcon();
    }
    if (properties.type === "multi") {
      let displayKey = properties.displayField || "name";
      var labels = content.map((elem) => {
        return elem[displayKey];
      });
      content = labels.join(", ");
    }
    if (properties.type === "date" || properties.type === "datetime") {
      if (content) {
        content = moment.unix(content).format(`DD.MM.YYYY${properties.type === "datetime" ? ' HH:mm' : ''}`);
      } else {
        content = '';
      }
    }
    if (properties.type === "page-link") {
      let object = this.state.object[key];
      if (!properties.external) {
        object = object || this.state.object;
      }
      content = object ? <div
        className={object.name ? 'page-link' : 'actionButton'}
        onClick={() => {
          if (properties.external) {
            if (object.name) {
              if (object.url) {
                window.open(object.url, '_blank');
              }
            } else {
              window.open(object, '_blank');
            }
          } else {
            this.props.history.push(properties.page.replace(':id', object.id || ''));
          }
        }}
      >
        {object.name
          ? <span className={object.url || object.id ? 'clickable' : ''}>{object.name}</span>
          : <span className='material-icons'>exit_to_app</span>
        }
      </div> : <div/>
    }
    if (properties.type === "text") {
      content = content || '';
      if (properties.listEdit) {
        content = <TextInput
          properties={{type: properties.type}}
          object={this.state.object[key]}
          onChange={(k, value) => {
            this.props.onChange(this.state.object.id, key, value);
            clearTimeout(this.changeTimeout);
            this.changeTimeout = setTimeout(() => {
              this.saveChanges(key, value);
            }, 500);
          }}
        />
      } else if (properties.onClickList) {
        content = <div
          className='page-link'
          onClick={() => {
            if (properties.onClickList === 'open-edit') {
              this.handleEdit();
            } else if (properties.onClickList === 'open-chat') {
              this.openChatPage();
            }
          }}
        >
          <span className='clickable'>{content}</span>
        </div>
      }
    }
    return content
  }

  render = () => {
    let config = this.state.config;

    let editButton = <div
      className='actionButton'
      onClick={this.handleEdit}
      disabled={this.state.object.deleted}
    >
      <span className='material-icons'>create</span>
    </div>;
    let deleteButton = <div
      className='actionButton'
      onClick={this.handleDestroy}
      disabled={this.state.object.deleted}
    >
      <span className='material-icons'>delete</span>
    </div>;

    if (this.state.object.disable_edit) {
      editButton = <div/>;
      deleteButton = <div/>;
    }
    if (config.disableDelete) {
      deleteButton = null;
    }
    if (config.modifyCondition && !config.modifyCondition(this.state.object)) {
      editButton = null;
      deleteButton = null;
    }

    if (this.props.isMobile) {
      return <table className='objectListElem'>
        <tbody>
          {Object.keys(this.state.properties).map(key => {
            const content = this.renderCellContent(key);
            if (content === null || content === undefined) {
              return null;
            } else {
              return <tr key={key}>
                <td width='125px'>{this.state.properties[key].title}</td>
                <td width='auto'>{content}</td>
              </tr>
            }
          })}
          <tr>
            <td width='125px'>Actions</td>
            <td width='auto' style={{padding: 0}}>
              {editButton}
              {deleteButton}
            </td>
          </tr>
        </tbody>
      </table>
    } else {
      return (
        <tr className='objectListElem'>
          {Object.keys(this.state.properties).map(key => {
            const content = this.renderCellContent(key);
            if (content === null || content === undefined) {
              return null;
            } else {
              return (
                <td
                  key={key}
                  className={content && content.props ? 'control' : ''}
                >{content}</td>
              )
            }
          })}
          {editButton ? <td style={{padding: 0}}>{editButton}</td> : null}
          {deleteButton ? <td style={{padding: 0}}>{deleteButton}</td> : null}
        </tr>
      );
    }
  }
}

export default connect(mapStoreToProps)(withRouter(ObjectListElem))
