import Storage from '../helpers/Storage.js';

const DOC_TYPES = [
  { id: 'medical', name: 'Medical' },
  { id: 'financial', name: 'Financial' },
  { id: 'media', name: 'Media' },
  { id: 'misc', name: 'Misc' },
];

export default {
  section: "Personal Team",
  config: {
    method: "leads/",
    title: "Campaigns",
    objectName: "Lead",
    disableAdd: true,
    disableDelete: true,
    hideSave: true,
    editView: require('../components/editControls/LeadEditView').default,
    search: {
      email: {
        type: "text",
        title: "Fundraiser Email",
      },
      name: {
        type: "text",
        title: "Beneficiary",
      },
      user_id: {
        type: "select",
        title: "Case Supporter",
        request: "collections/users/",
        clearable: true,
        roleCheck: true,
        additionalParam: 'charity_id',
      },
      case_status: {
        type: "select",
        title: "Status",
        request: "leads/case_statuses/",
        clearable: true,
        default: 0
      },
      charity_id: {
        defaultKey: 'charity_ids',
        type: "multi-select",
        title: "Adopting Charity",
        request: "collections/charities/",
        clearable: true,
        showCondition: () => (Storage.getData('user') || {}).role === 'admin',
      },
      id: {
        type: "number",
        title: "Case ID",
      },
    },
  },
  properties: {
    avatar: {
      type: "avatar",
    },
    id: {
      default: false,
      type: "text",
      title: "#",
      preset: true,
      width: "60px",
      onClickList: "open-edit",
    },
    campaign: {
      type: "page-link",
      title: "Campaign ID",
      external: true,
      width: "auto",
      preset: true,
    },
    give_asia_created_at: {
      default: "",
      type: "date",
      title: "Date created",
      preset: true,
      width: "110px",
    },
    updated_at: {
      default: "",
      type: "datetime",
      title: "Modified Time",
      preset: true,
    },
    identifier: {
      type: "none",
    },
    chat_identifier: {
      type: "none",
    },
    agreements_statuses: {
      type: "none",
    },
    notification_alert: {
      type: "none",
    },
    name: {
      type: "text",
      title: "Campaign Title",
    },
    fundraiser_name: {
      type: "text",
      title: "Fundraiser",
      onClickList: "open-chat",
      preset: true,
      width: "auto",
    },
    fundraiser: {
      type: "page-link",
      title: "Fundraiser",
      page: "/fundraisers/:id",
    },
    fundraiser_email: {
      type: "text",
      title: "Email",
      preset: true,
    },
    page_link: {
      type: "page-link",
      title: "Beneficiary",
      page: "/leads/:id",
      width: "auto",
    },
    movement_name: {
      type: "text",
      title: "Movement",
      width: "auto",
      preset: true,
    },
    last_fundraiser_update_at: {
      type: "text",
      title: "Last Fundraiser Update",
      width: "auto",
    },
    country_list: {
      type: "text",
      title: "Country",
      width: "auto",
    },
    country: {
      type: "text",
      title: "Country",
    },
    remarks: {
      type: "textarea",
      title: "Remarks",
    },
    charity_id: {
      type: "select",
      title: "Adopting Charity",
      request: "collections/charities/",
      listEdit: true,
      method: "leads/:id/",
      clearable: true,
      width: "180px",
      preset: true,
    },
    interview_remarks: {
      type: "textarea",
      title: "Interview Remarks",
      listEdit: true,
      method: "leads/:id/",
      width: "180px",
    },
    user_id: {
      type: "select",
      title: "Case Supporter",
      listEdit: true,
      method: "leads/:id/",
      clearable: true,
      width: "180px",
      request: "collections/users/",
      roleCheck: true,
      additionalParam: 'charity_id',
    },
    verifier_id: {
      type: "select",
      title: "Case Manager",
      clearable: true,
      additionalParam: 'charity_id',
      request: "collections/users/",
    },
    brief_story: {
      type: "wysiwyg",
      title: "Story",
      labelTop: true,
      preview: true,
      customToolbar: true,
      imageUploadRequest: "leads/:id/wysiwyg_image",
      disabled: true
    },
    relationship_with_beneficiary: {
      type: "text",
      title: "Relationship",
    },
    medical_condition: {
      type: "text",
      title: "Medical condition",
    },
    time_to_contact: {
      type: "text",
      title: "Preferred 1st Contact Time",
    },
    phone: {
      type: "text",
      title: "Phone",
    },
    status: {
      type: "text",
      title: "Case status",
      width: "120px",
      showCondition: () => false,
    },
    currency: {
      type: "text",
      title: "Currency",
      preset: true,
      simplified: true,
    },
    count_of_donors: {
      type: "text",
      title: "Count of donors",
      preset: true,
    },
    case_status: {
      type: "select",
      title: "Case status",
      options: [
        { id: "pending", name: "Pending" },
        { id: "published", name: "Published" },
        { id: "rejected", name: "Rejected" },
        { id: "deleted", name: "Deleted" },
        { id: "draft", name: "Draft" },
        { id: "activated", name: "Activated" },
        { id: "draft_for_brochure", name: "Draft_for_brochure" },
        { id: "draft_for_stripe_connect", name: "Draft_for_stripe_connect" },
        { id: "finished", name: "Finished" },
        { id: "pending_approval", name: "Pending Approval" },
      ],
    },
    steps: {
      type: "none",
    },
    reject_reason: {
      type: "text",
      title: "Reject reason",
      showCondition: (object) => object.case_status === "rejected",
    },
    delete_reason: {
      type: "text",
      title: "Delete reason",
      showCondition: (object) => object.case_status === "deleted",
    },
    amount_target_value: {
      type: "number",
      title: "Target amount",
      preset: true,
    },
    amount_target: {
      type: "number",
      title: "Fundraising Target",
      preset: true,
    },
    offline_amount: {
      type: "text",
      title: "Offline donation amount",
      preset: true,
    },
    stripe_fee: {
      type: "text",
      preset: true,
      simplified: true,
      title: "Stripe fee",
    },

    ads_spent: {
      type: "text",
      title: "Ads spent",
      preset: true,
    },
    ads_balance: {
      type: "text",
      title: "Ads balance",
      preset: true,
    },
    amount_raised: {
      type: "text",
      preset: true,
      simplified: true,
      title: "Donations amount",
    },
    donation_count: {
      type: "text",
      preset: true,
      simplified: true,
      title: "Donation Count",
    },
    ads_budget: {
      type: "text",
      preset: true,
      simplified: true,
      title: "Facebook Outreach",
      prefix: '-',
    },
    ads_refund: {
      type: "text",
      preset: true,
      simplified: true,
      title: "Facebook Outreach Refund",
    },
    refunded_amount: {
      default: '-',
      type: "text",
      preset: true,
      simplified: true,
      title: "Refunded Amount",
    },
    refunded_donation_count: {
      default: '-',
      type: "text",
      preset: true,
      simplified: true,
      title: "Refunded Donation Count",
    },
    payouts_last_updated_at: {
      type: "date",
      preset: true,
      simplified: true,
      title: "Last updated",
    },
    stripe_fee_value: {
      type: "text",
      preset: true,
      simplified: true,
      title: "Transaction fees",
    },
    payouts: {
      type: "none",
    },
    payout_subtotal: {
      type: "text",
      preset: true,
      simplified: true,
      title: "Payout subtotal",
      prefix: '-',
    },
    balance: {
      type: "text",
      preset: true,
      simplified: true,
      title: "Balance",
    },

    todo: {
      type: "todo-list",
      title: "TODO list",
      disableEdit: true,
      labelTop: true
    },
    documents: {
      default: [],
      type: "documents-list",
      title: "Documents",
      uploadId: "lead_id",
      uploadLink: (object, type) => `/library/${type}/${object.identifier}`,
      uploadLinkTitle: (type) => `${(DOC_TYPES.find(i => i.id === type) || {}).name || ''}Upload Link`,
      types: DOC_TYPES,
    },
    agreement_files: {
      default: [],
      type: "documents-list",
      title: "Agreement Files",
      uploadId: "lead_id",
      uploadLink: (object, type) => `/library/${type}/${object.identifier}`,
      uploadLinkTitle: (type) => `${(DOC_TYPES.find(i => i.id === type) || {}).name || ''}Upload Link`,
      types: [],
      disableUpload: true,
    },

    conversation_files: {
      default: [],
      type: "documents-list",
      title: "Conversation Files",
      uploadId: "lead_id",
      uploadLink: (object, type) => `/library/${type}/${object.identifier}`,
      uploadLinkTitle: (type) => `${(DOC_TYPES.find(i => i.id === type) || {}).name || ''}Upload Link`,
      types: [],
      disableUpload: true,
    },
    facebook_url: {
      type: "text",
      title: "Facebook URL",
    },
    campaign_url: {
      type: "text",
      title: "URL",
    },
    campaign_short_url: {
      type: "text",
      title: "Short URL",
    },
    campaign_banner: {
      type: "image",
      title: "Banner",
    },
    campaign_gallery: {
      default: [],
      type: "documents-list",
      title: "Gallery",
      uploadId: "lead_id",
      uploadLink: object => `/library/${object.identifier}`,
      marketing: true,
      types: [],
    },
    youtube_url: {
      type: "text",
      title: "Youtube URL",
    },
    marketing_todo: {
      type: "todo-list",
      title: "Marketing TODO list",
      disableEdit: true,
      labelTop: true
    },
    changed_by: {
      type: "text",
      title: "Changed By",
    },
    rollback_possibility: {
      type: "text",
      title: "Rollback Possibility",
    },
    beneficiary_name: {
      type: "text",
      title: "Beneficiary name",
    },
    payee_id: {
      type: "select",
      title: "Payee",
      request: "collections/payees/",
      clearable: true,
      additionalParam: 'charity_id',
    },
    payout_reference_number: {
      type: "text",
      title: "Payout reference number",
    }
  }
};
