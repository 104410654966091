export const facebookLogin = () => {
  return new Promise((resolve, reject) => {
    try {
      const requestUserData = () => {
        window.FB.api('/me', {fields: 'name,email'}, response => {
          resolve(response)
        });
      }
      window.FB.getLoginStatus((response) => {
        if (response.status === 'connected') {
          requestUserData();
        } else {
          window.FB.login(response => {
            if (response.status === 'connected') {
              requestUserData();
            } else {
              reject()
            }
          }, {scope: 'public_profile,email'})
        }
      })
    } catch (ex) {
      reject()
    }
  })
}
