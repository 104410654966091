import React from 'react';

import '../../sass/components/input/FileInput.scss';

export default class FileInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      preview: props.object,
    };
    this.input = null;
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (
      prevProps.object !== this.props.object &&
      typeof this.props.object === 'string'
    ) {
      this.setState({preview: this.props.object});
    }
  }

  handleChange = (file) => {
    this.setState({preview: file});
    this.props.onChange(this.props.objectKey, file);
  }

  componentDidMount = () => {
  }

  render = () => {
    const preview = this.state.preview;
    return (
      <div className='fileInput'>
        <input
          type='file'
          accept='*'
          ref={input => this.input = input}
          onChange={e => this.handleChange(e.target.files[0])}
        />
        {preview ?
          preview.name
            ? <span>{preview.name}</span>
            : <a href={preview} target='_blank' rel='noopener noreferrer'>{preview}</a>
        : null}
        {this.props.disabled ? null :
          <button
            className='outline'
            onClick={() => this.input.click()}
          >Select</button>
        }
      </div>
    )
  }
}
