import React from "react";
import connect from "react-redux/es/connect/connect";
import {withRouter} from "react-router-dom";
import {sendRequest, triggerEvent} from "../helpers/global";

import '../sass/components/MarketingTodoElem.scss';

const mapStoreToProps = (store) => {
  return {
    isMobile: store.setup.is_mobile,
  }
};

class MarketingTodoElem extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      description: '',
    };
    this.todoInput = null;
  }

  onChange = (value) => {
    const { object } = this.props;
    if (value === '') {
      return this.setState({edit: false})
    }
    if (object.newItem) {
      sendRequest({
        method: 'marketing_todos',
        type: 'POST',
        data: {
          description: value
        },
        success: (data) => {
          this.props.onChange({...data});
          this.setState({edit: false});
          triggerEvent('showSnackbar', [{text: 'Data saved', type: 'success'}]);
        },
        error: (data) => {}
      });
    } else {
      sendRequest({
        method: `marketing_todos/${object.id}`,
        type: 'PUT',
        data: {
          description: value
        },
        success: () => {
          this.props.onChange({...object, description: value});
          this.setState({edit: false});
          triggerEvent('showSnackbar', [{text: 'Data saved', type: 'success'}]);
        },
        error: (data) => {}
      });
    }
  };

  onDelete = () => {
    const object = this.props.object;
    if (object.newItem && !object.description) {
      if (this.props.onDelete) {
        this.onDeleteConfirm();
      }
      return
    }
    triggerEvent('showConfirmation', [{
      title: `Are you sure want to delete ${object.description}?`,
      confirmText: 'Delete',
      cancelText: 'Cancel',
      callback: confirm => {
        if (confirm && this.props.onDelete) {
          this.onDeleteConfirm();
        }
      }
    }]);
  };

  onDeleteConfirm = () => {
    const { object } = this.props;
    if (object.newItem) {
      this.props.onDelete();
    } else {
      sendRequest({
        method: `marketing_todos/${object.id}`,
        type: 'DELETE',
        success: () => {
          this.props.onDelete(object.id);
          triggerEvent('showSnackbar', [{text: `${object.description} deleted`, type: 'success'}]);
        },
        error: (data) => {}
      });
    }
  };

  renderDelete = () => {
    return (
      <div className='actionButton' onClick={() => this.onDelete()}>
        <span className='material-icons'>delete</span>
      </div>
    )
  };

  render = () => {
    const object = this.props.object;
    const { edit, description} = this.state
    if (edit) {
      return (
        <div  className='marketingTodoElem'>
          <input
            type='text'
            value={description}
            onChange={e => this.setState({description: e.target.value})}
            ref={elem => this.todoInput = elem}
          />
          <div
            className='actionButton'
            onClick={() => this.setState({edit: false})}
          >
            <span className='material-icons'>close</span>
          </div>
          <div
            className='actionButton'
            onClick={() => {
              this.onChange(description);
            }}
          >
            <span className='material-icons'>save</span>
          </div>
        </div>
      )
    } else {
      return (
        <div  className='marketingTodoElem'>
          <div className='listItem'>{object.description}</div>
          <div className='actionButton'
            onClick={() =>
              this.setState({edit: true, description: object.description}, () => {
                this.todoInput.focus();
              })
            }
          >
            <span className='material-icons'>create</span>
          </div>
          {this.renderDelete()}
        </div>
      )
    }
  }
}

export default connect(mapStoreToProps)(withRouter(MarketingTodoElem));
