import React from 'react';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';

import { eventOn, eventOff } from '../../helpers/global';

import '../../sass/components/common/NotificationPopup.scss';

const DEFAULT_SHOW_TIME = 5000;
const REDUCED_SHOW_TIME = 4000;

class NotificationPopup extends React.Component {
  constructor(props) {
    super();
    this.state = {
      show: false,
      title: null,
      text: null,
    };
    this.timeout = null;
  }

  componentDidMount = () => {
    eventOn('showNotification', this.showNotification);
  }

  componentWillUnmount = () => {
    clearTimeout(this.timeout);
    eventOff('showNotification', this.showNotification);
  }

  showNotification = (e) => {
    clearTimeout(this.timeout);
    const { title, text, type = null, link = null } = e.detail[0];
    const showTime = type === 'newMessage' ? REDUCED_SHOW_TIME : DEFAULT_SHOW_TIME;
    this.setState({show: true, title, text, type, link});
    this.timeout = setTimeout(this.onClose, showTime);
  }

  onClose = () => {
    clearTimeout(this.timeout);
    this.setState({show: false});
  }

  render = () => {
    const { show, title, text, type, link } = this.state;
    return (
      <div
        className={classnames({
          'notificationPopup': true,
          'show': show,
          [type]: !!type,
        })}
        onClick={() => link && this.props.history.push(link)}
      >
        <div className='notificationContainer'>
          <div className='notificationTitle' dangerouslySetInnerHTML={{__html: title || ''}}/>
          <div className='notificationText' dangerouslySetInnerHTML={{__html: text || ''}}/>
          {type === 'newMessage' ? <div className='seeMessage'>See message</div> : null}
          <div
            onClick={(e) => {
              e.stopPropagation();
              this.onClose();
            }}
            className='notificationClose'
          />
        </div>
      </div>
    )
  }
}

export default withRouter(NotificationPopup);
