import React from 'react';

import { sendRequest, triggerEvent } from '../helpers/global.js';

import SelectInput from '../components/input/SelectInput.js';

import '../sass/components/UploadFilesView.scss';

const DOC_TYPES = [
  {
    id: 'medical',
    name: 'Medical',
    description: 'Supporting medical documents such as medical reports, lab reports and doctor\'s memo.',
  },
  {
    id: 'financial',
    name: 'Financial',
    description: 'Proof of fundraising goal and your financial status, such as hospital bill and bank statement.',
  },
  {
    id: 'media',
    name: 'Media',
    description: 'Recent pictures/videos of the beneficiary.',
  },
  {
    id: 'misc',
    name: 'Misc',
    description: 'Beneficiary\'s identity document and other relevant supporting documents.',
  },
];

export default class UploadFilesView extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      files: [],
      selectedType: null,
    };
    this.input = null;
  }

  paramType = () => {
    return this.props.match.params.type;
  }
  
  paramIdentifier = () => {
    return this.props.match.params.identifier;
  }

  componentDidMount = () => {
    sendRequest({
      method: `libraries/${this.paramIdentifier()}`,
      type: 'GET',
      success: (data) => {
        this.setState({data});
      },
      error: (data) => {
      }
    });
  }

  uploadFiles = () => {
    let formData = new FormData();
    formData.append('lead_document_type', this.state.selectedType || this.paramType());
    this.state.files.forEach((file, i) => {
      formData.append(`files[${i}]`, file);
    });
    sendRequest({
      method: `libraries/${this.paramIdentifier()}`,
      type: 'POST',
      formData,
      success: (data) => {
        this.setState({files: []});
        triggerEvent('showSnackbar', [{text: 'Files uploaded', type: 'success'}]);
      },
      error: (data) => {
      }
    });
  }

  onDeleteFile = (file, index) => {
    this.setState({files:
      this.state.files.filter(f => f !== file)
    });
  }

  renderAddButton = () => {
    return (
      <>
        <div
          className='addButton'
          onClick={() => this.input.click()}
        >
          <span className='material-icons'>add</span>
        </div>
        <input
          type='file'
          multiple
          ref={input => this.input = input}
          onChange={e => this.setState({files: [
            ...this.state.files,
            ...e.target.files,
          ]})}
        />
      </>
    )
  }

  renderUploadButton = () => {
    const disabled =
      !this.state.files.length
      || (this.paramType() === 'any' && !this.state.selectedType);
    return (
      <button
        className='shadow uploadButton'
        disabled={disabled}
        onClick={this.uploadFiles}
      >Upload</button>
    )
  }

  renderFile = (file, index) => {
    return (
      <div key={index} className='fileItem'>
        <div className='fileName'>{file.name}</div>
        <div
          className='fileAction'
          onClick={() => this.onDeleteFile(file, index)}
        >
          <span className='material-icons'>delete</span>
        </div>
      </div>
    )
  }

  render = () => {
    const data = this.state.data;
    const selectedType = this.paramType() === 'any' ? this.state.selectedType : this.paramType();
    const option = DOC_TYPES.find(i => i.id === selectedType);
    return (
      <div className='uploadFilesView'>
        {data ?
          <div className='uploadContainer'>
            <div className='uploadHeader'>
              Upload files to <b>{data.name}</b> <span>
                {(DOC_TYPES.find(i => i.id === this.paramType()) || {}).name} 
              </span> library
            </div>
            {this.paramType() === 'any' ?
              <SelectInput
                properties={{options: DOC_TYPES}}
                object={this.state.selectedType}
                onChange={(k, value) => this.setState({selectedType: value})}
              />
            : null}
            {option ?
              <div className='typeDescription'>{option.description}</div>
            : null}
            <div className='listContainer'>
              {this.state.files.map(this.renderFile)}
            </div>
            {this.renderAddButton()}
            {this.renderUploadButton()}
          </div>
        : null}
      </div>
    );
  }
}
