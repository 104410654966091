import React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import { sendRequest, triggerEvent } from '../helpers/global.js';

import appConfig from '../config/';
import SelectInput from './input/SelectInput.js';

import '../sass/components/ListElem.scss';

const mapStoreToProps = (store) => {
  return {
    isMobile: store.setup.is_mobile,
  }
};

class LeadListElem extends React.Component {

  constructor(props) {
    super(props);
    var state = {
      object: this.getDefaultObjectState(),
      properties: appConfig[props.configKey].properties,
      config: appConfig[props.configKey].config,
    };
    this.state = state;
    this.changeTimeout = null;
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.configKey !== prevProps.configKey) {
      this.setState({
        properties: appConfig[this.props.configKey].properties,
        config: appConfig[this.props.configKey].config,
        object: this.getDefaultObjectState()
      })
    } else if (this.props.object !== prevProps.object) {
      this.setState({object: this.getDefaultObjectState()});
    }
  }

  getDefaultObjectState = () => {
    let object = {};
    const properties = appConfig[this.props.configKey].properties;
    const keys = Object.keys(properties);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if ((typeof this.props.object[key] !== "undefined") && (this.props.object[key] !== null)) {
        object[key] = this.props.object[key];
      } else {
        if (Array.isArray(properties[key].default)) {
          object[key] = properties[key].default.slice();
        } else if (typeof properties[key].default === "object") {
          object[key] = Object.assign({}, properties[key].default);
        } else {
          object[key] = properties[key].default;
        }
      }
    }
    return object;
  }

  handleEdit = (tab) => {
    this.props.onEdit(this.state.object, tab);
  }

  openChatPage = () => {
    const item = this.state.object;
    const tab = window.open();
    if (item.chat_identifier) {
      tab.location = `/chats/${item.chat_identifier}`;
      return;
    }
    sendRequest({
      method: `${this.state.config.method}/${item.id}/chat`,
      type: 'GET',
      success: (data) => {
        tab.location = `/chats/${data.identifier}`;
      },
      error: (data) => {
        tab.close();
      }
    });
  }

  saveChanges = (key, value) => {
    const itemConfig = this.state.properties[key];
    if (itemConfig.method) {
      sendRequest({
        method: itemConfig.method.replace(':id', this.state.object.id),
        type: "PUT",
        data: {
          [key]: value
        },
        success: (data) => {
          triggerEvent('showSnackbar', [{text: 'Data saved', type: 'success'}]);
        },
        error: (data) => {
        }
      });
    }
  }

  renderSelectInput = (key) => {
    const properties = this.state.properties[key] || {};
    const options = (this.props.options || {})[key] || [];
    return (
      <SelectInput
        properties={{
          options,
          clearable: properties.clearable,
          roleCheck: properties.roleCheck,
          additionalParam: properties.additionalParam,
          request: properties.roleCheck || properties.additionalParam ? properties.request : null
        }}
        parentObject={this.state.object}
        object={this.state.object[key]}
        onChange={(k, value) => {
          this.props.onChange(this.state.object.id, key, value);
          this.saveChanges(key, value);
        }}
      />
    )
  }

  render = () => {
    const { properties } = this.state;
    const { id, campaign, page_link, fundraiser_name, movement_name, last_fundraiser_update_at_sec, country_list, notification_alert, case_status, steps, name } = this.state.object;

    return (
      <div className='listElem card'>
        <div className='header'>
          <a
            className='page-link'
            href={campaign.url}
            target='_blank'
            rel='noopener noreferrer'
          >
            <span className={campaign.url ? 'clickable' : ''}>{ id }</span>
          </a>
          <div
            className='page-link campaignTitle'
            onClick={() => this.handleEdit()}
          >
            <span className={campaign.url ? 'clickable' : ''}>{name}</span>
          </div>
          <span className='caseStatus'>{case_status}</span>
          {steps ? <span className='caseSteps'>{steps}</span> : null}
          <div className={classnames({
            'updateIndicator': true,
            'new': notification_alert.new,
          })}>
            {notification_alert.new
              ? 'New'
              : `Last update ${moment.unix(notification_alert.updated).format('DD.MM.YYYY HH:mm')}`
            }
          </div>

        </div>
        <div className='content'>
          <div className='contentColumn'>
            <div className='infoBlock'>
              <label>{properties.page_link.title}</label>
              <a
                className='infoValue page-link'
                href={properties.page_link.page.replace(':id', page_link.id || '')}
                target='_blank'
                rel='noopener noreferrer'
              >
                <span className='clickable'>{page_link.name}</span>
              </a>
            </div>
            <div className='infoBlock'>
              <label>{properties.fundraiser_name.title}</label>
              <div
                className='infoValue page-link clickable'
                onClick={this.openChatPage}
              >
                <span className='clickable'>{fundraiser_name}</span>
              </div>
            </div>
            <div className='infoBlock'>
              <label>{properties.movement_name.title}</label>
              <div
                className='infoValue'
              >
                <span className=''>{movement_name}</span>
              </div>
            </div>
            <div className='infoBlock'>
              <label>{properties.last_fundraiser_update_at.title}</label>
              <div
                className='infoValue'
              >
                <span className=''>{last_fundraiser_update_at_sec && moment.unix(last_fundraiser_update_at_sec).format('DD.MM.YYYY HH:mm')}</span>
              </div>
            </div>
          </div>
          <div className='contentColumn'>
            <div className='infoBlock'>
              <label>{properties.country_list.title}</label>
              <div className='infoValue'>
                {country_list}
              </div>
            </div>
            <div className='infoBlock'>
              <label>{properties.charity_id.title}</label>
              {this.renderSelectInput('charity_id')}
            </div>
            <div className='infoBlock'>
              <label>{properties.user_id.title}</label>
              {this.renderSelectInput('user_id')}
            </div>
          </div>
          <div className='contentColumn'>
            <div
              className='actionButton'
              onClick={() => this.handleEdit()}
            >
              <span className='material-icons'>edit</span>
            </div>
            <div
              className='actionButton'
              onClick={() => this.handleEdit('step_2')}
            >
              <span className='material-icons'>check_box</span>
            </div>
            <div
              className='actionButton'
              onClick={() => this.handleEdit('step_3')}
            >
              <span className='material-icons'>local_atm</span>
            </div>
            <a
              className='actionButton'
              href={campaign.url}
              target='_blank'
              rel='noopener noreferrer'
            >
              <span className='material-icons'>exit_to_app</span>
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStoreToProps)(withRouter(LeadListElem))
