import React from 'react';

import SelectInput from './input/SelectInput.js';
import CheckboxInput from './input/CheckboxInput.js';
import SwitchInput from './input/SwitchInput.js';
import DateInput from './input/DateInput.js';
import WysiwygInput from './input/WysiwygInput.js';
import TextInput from './input/TextInput.js';
import JsonInput from './input/JsonInput.js';
import FileInput from './input/FileInput.js';
import LinkInput from './input/LinkInput.js';
import PageLink from './input/PageLink.js';
import ImageInput from './input/ImageInput.js';
import DocumentsList from './input/DocumentsList.js';
import LogsList from './input/LogsList.js';
import TodoList from './input/TodoList.js';
import LinksList from './input/LinksList.js';
import PayeesList from './input/PayeesList.js';

import UserAvatarView from './UserAvatarView';

export default class FlexibleInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render = () => {
    var properties = this.props.properties;
    switch (properties.type) {
      case "text":
      case "number":
      case "password":
      case "textarea":
        return <TextInput  properties={properties} {...this.props} />
      case "wysiwyg":
        return <WysiwygInput properties={properties} {...this.props} />
      case "select":
      case "multi-select":
        return <SelectInput properties={properties} {...this.props} />
      case "checkbox":
        return <CheckboxInput properties={properties} {...this.props} />
      case "switch":
        return <SwitchInput properties={properties} {...this.props} />
      case "date":
      case "datetime":
        return <DateInput properties={properties} {...this.props} />
      case "file":
        return <FileInput properties={properties} {...this.props} />
      case "link":
        return <LinkInput properties={properties} {...this.props} />
      case "json":
        return <JsonInput properties={properties} {...this.props} />
      case "avatar":
        return <UserAvatarView properties={properties} {...this.props} />
      case "page-link":
        return <PageLink properties={properties} {...this.props} />
      case "image":
        return <ImageInput properties={properties} {...this.props} />
      case "documents-list":
        return <DocumentsList properties={properties} {...this.props} />
      case "logs-list":
        return <LogsList properties={properties} {...this.props} />
      case "todo-list":
        return <TodoList properties={properties} {...this.props} />
      case "links-list":
        return <LinksList properties={properties} {...this.props} />
      case "payees-list":
        return <PayeesList properties={properties} {...this.props} />
      default:
        return null;
    }
  }
}
