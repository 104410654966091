import React from 'react';
import { connect } from 'react-redux';
import { connect as TwilioConnect, createLocalVideoTrack } from 'twilio-video';
import classnames from 'classnames';

import { sendRequest } from '../helpers/global.js';

import SelectInput from './input/SelectInput.js';

import '../sass/components/VideoRoomView.scss';
import languages from "./common/SpeechLanguageSupport";
import DropdownMenu from './common/DropdownMenu';

const mapStoreToProps = (store) => {
  return {
    user: store.data.user,
    isMobile: store.setup.is_mobile,
  }
};

class VideoRoomView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      token: null,
      micro: true,
      camera: true,
      finished: false,
      roomOpened: false,
      roomName: null,
      fundraiser: null,
      giviki: null,
      lead_id: null,
      recordingStarted: false,
      languageCode: languages[0].id,
      videoInput: null,
      audioInput: null,
      videoInputs: [],
      audioInputs: []
    };
    this.room = null;
    this.roomTimer = null;
  }

  paramIdentifier = () => {
    return this.props.match.params.identifier;
  }

  componentDidMount = () => {
    sendRequest({
      type: 'GET',
      method: 'twilio/video_token',
      success: (data) => {
        this.setState({token: data}, this.initialConnect);
      },
      error: (data) => {
      }
    });
    sendRequest({
      type: 'GET',
      method: `video_chats/${this.paramIdentifier()}`,
      success: (data) => {
        this.setState({
          roomOpened: data.opened,
          roomName: data.room_identifier,
          fundraiser: data.fundraiser,
          giviki: data.giviki,
          lead_id: data.lead_id,
          languageCode: data.language_code
        }, this.initialConnect);
      },
      error: (data) => {
      }
    });
    this.roomTimer = setInterval(this.updateRoomState, 75000);

    navigator.mediaDevices.enumerateDevices().then(devices => {
      const videoInputs = devices.filter(device => device.kind === 'videoinput');
      const audioInputs = devices.filter(device => device.kind === 'audioinput');
      this.setState({
        videoInputs: videoInputs,
        audioInputs: audioInputs,
        videoInput: videoInputs[0].deviceId,
        audioInput: audioInputs[0].deviceId,
      });
    });
  }

  componentWillUnmount = () => {
    this.disconnectRoom();
    clearInterval(this.roomTimer);
  }

  initialConnect = () => {
    if (this.state.roomOpened && this.state.token) {
      this.roomConnect();
    }
  }

  updateRoomState = () => {
    sendRequest({
      type: 'GET',
      method: `video_chats/${this.paramIdentifier()}`,
      noLoad: true,
      success: (data) => {
        if (data.opened !== this.state.roomOpened) {
          this.setState({roomOpened: data.opened});
          if (data.opened) {
            this.roomConnect();
          } else {
            this.disconnectRoom();
          }
        }
      },
      error: (data) => {
      }
    });
  }

  switchRoom = () => {
    if (this.state.roomOpened) {
      sendRequest({
        type: 'PUT',
        method: `video_chats/${this.paramIdentifier()}`,
        data: {
          opened: false,
        },
        success: (data) => {
          this.setState({roomOpened: false});
        },
        error: (data) => {
        }
      });
      this.disconnectRoom();
    } else {
      sendRequest({
        type: 'PUT',
        method: `video_chats/${this.paramIdentifier()}`,
        data: {
          opened: true,
        },
        success: (data) => {
          this.setState({roomOpened: true}, this.roomConnect);
        },
        error: (data) => {
        }
      });
    }
  }

  switchRecording = () => {
    sendRequest({
      type: 'POST',
      method: `video_rooms/${this.state.roomName}/recording_timestamp`,
      data: {
        action: this.state.recordingStarted ? 'stop' : 'start',
        language_code: this.state.languageCode
      },
      success: (data) => {
        this.setState({recordingStarted: !this.state.recordingStarted});
      },
      error: (data) => {
      }
    });
  }

  sendJoinedRoom = () => {
    sendRequest({
      type: 'POST',
      method: `video_rooms/${this.state.roomName}/start_recording`,
      success: (data) => {
      },
      error: (data) => {
      }
    });
  }

  disconnectRoom = () => {
    if (this.room) {
      this.room.localParticipant.tracks.forEach(publication => {
        const track =  publication.track;
        track.detach().forEach(elem => elem.remove());
        track.stop();
        this.room.localParticipant.unpublishTrack(track);
      });
      this.room.disconnect();
    }
  }

  addRemoteTrack = (track) => {
    document.getElementById('media-container').appendChild(track.attach());
    track.on('disabled', () => {
      /* Hide the associated <video> element and show an avatar image. */
    });
    track.on('enabled', () => {
      /* Hide the associated <video> element and show an avatar image. */
    });
  }

  participantConnected = (participant) => {
    participant.tracks.forEach(publication => {
      if (publication.track) {
        this.addRemoteTrack(publication.track);
      }
    });
    participant.on('trackSubscribed', track => {
      this.addRemoteTrack(track);
    });
    participant.on('trackUnsubscribed', track => {
      const attachedElements = track.detach();
      attachedElements.forEach(element => element.remove());
    });
  }

  roomConnect = () => {
    TwilioConnect(this.state.token, {
      name: this.state.roomName,
      audio: true,
      video: false
    }).then(room => {
      this.room = room;
      console.log(`Successfully joined a room ${room.name} as ${room.localParticipant.identity}`);

      createLocalVideoTrack({
        audio: true,
        video: { width: 1024 },
      }).then(track => {
        this.sendJoinedRoom();
        this.room.localParticipant.publishTrack(track);
        document.getElementById('media-container').appendChild(track.attach());
      });

      room.participants.forEach(this.participantConnected);
      room.on('participantConnected', this.participantConnected);
      room.on('disconnected', room => {
        this.disconnectRoom();
      });

    }, error => {
      console.error(`Unable to connect to Room: ${error.message}`);
    });
  }

  switchInput = (type) => {
    const newVal = !this.state[type];
    if (type === 'micro') {
      this.room.localParticipant.audioTracks.forEach(publication => {
        if (newVal) {
          publication.track.enable();
        } else {
          publication.track.disable();
        }
      });
    } else if (type === 'camera') {
      this.room.localParticipant.videoTracks.forEach(publication => {
        if (newVal) {
          publication.track.enable();
        } else {
          publication.track.disable();
        }
      });
    }
    this.setState({[type]: newVal});
  }

  onSelectVideoInput = (value) => {
    this.setState({ videoInput: value });
    return createLocalVideoTrack({ audio: true, video: { deviceId: value } });
  }

  onSelectAudioInput = (value) => {
    this.setState({ audioInput: value });
    return createLocalVideoTrack({ audio: { deviceId: value }, video: true });
  }

  leaveRoom = () => {
    this.disconnectRoom();
    this.setState({finished: true});
  }

  updateLanguageCode = (value) => {
    sendRequest({
      type: 'PUT',
      method: `video_chats/${this.paramIdentifier()}`,
      data: {
        language_code: value,
      },
      success: (data) => {
        this.setState({languageCode: value});
      },
      error: (data) => {
      }
    });
  }

  renderInfo = () => {
    const { fundraiser, giviki } = this.state;
    const text = fundraiser ? fundraiser.name : giviki ? giviki.name : '';
    const link = this.getLink();
    if (!text) {
      return null;
    }
    return (
      <div className='fundraiserInfo'>
        {this.props.user ?
          <span onClick={() => this.props.history.push(link)}>
            {text}
          </span>
        : text}
      </div>
    )
  }

  getLink = () => {
    const { fundraiser, giviki, lead_id } = this.state;
    return fundraiser ? lead_id ? `/leads/${lead_id}` : `/fundraisers/${fundraiser.id}`
      : giviki ? `/givikis/${giviki.id}` : '/';
  }

  renderLeftRoom = () => {
    const { fundraiser } = this.state;
    const leftLink = this.getLink();
    return (
      <div className='videoRoomView'>
        <div className='videoRoomWindow'>
          <div className='videoRoomTitle'>Video room</div>
          <div className='leftRoomMessage'>You left video room. Thanks for participation!</div>
          <button onClick={() => this.props.history.push(leftLink)}>
            {fundraiser ? 'Back to App' : 'Close Room'}
          </button>
        </div>
      </div>
    )
  }

  renderStartMeeting = () => {
    return (
      <div className='videoRoomView'>
        <div className='videoRoomWindow'>
        <div className='videoRoomTitle'>Video room</div>
          {this.props.user ?
            <div className='popupControls languageSelect'>
              <label>Language</label>
              <SelectInput
                object={this.state.languageCode}
                properties={{options: languages, placeholder: 'Conversation language'}}
                onChange={(k, value)  => this.updateLanguageCode(value)}
              />
            </div>
          : null}
          <button onClick={this.switchRoom}>Start Meeting</button>
        </div>
      </div>
    )
  }

  renderActions = () => {
    const { videoInput, audioInput, videoInputs, audioInputs } = this.state;
    return (
      <div className='actions'>
        <div className='action'>
          <div className='actionBar'>
            <div
              className={classnames({
                'actionIcon': true,
                'inactive': !this.state.micro,
                'withDropdownMenu': !this.props.isMobile,
              })}
              onClick={() => this.switchInput('micro')}
            >
              <div
                className={classnames({
                  'icon': true,
                  'mic': this.state.micro,
                  'mic_off': !this.state.micro,
                })}
              />
            </div>
            {this.props.isMobile
              ? null
              : this.renderDropdownMenu(audioInputs, !this.state.micro, audioInput, this.onSelectAudioInput)}
          </div>
          <span>Mic</span>
        </div>
        <div className='action'>
          <div className='actionBar'>
            <div
              className={classnames({
                'actionIcon': true,
                'inactive': !this.state.camera,
                'withDropdownMenu': !this.props.isMobile,
              })}
              onClick={() => this.switchInput('camera')}
            >
              <div
                className={classnames({
                  'icon': true,
                  'camera': this.state.camera,
                  'videocam_off': !this.state.camera,
                })}
              />
            </div>
            {this.props.isMobile
              ? null
              : this.renderDropdownMenu(videoInputs, !this.state.camera, videoInput, this.onSelectVideoInput)}
          </div>
          <span>Camera</span>
        </div>
        <div className='action'>
          <div
            className={classnames({
              'actionIcon': true,
              'active': this.state.recordingStarted,
            })}
            onClick={this.switchRecording}>
            <div className='icon record' />
          </div>
          <span>{this.state.recordingStarted ? 'Stop' : 'Record'}</span>
        </div>
        <div className='action'>
          <div className='actionIcon' onClick={this.leaveRoom}>
            <div className='icon leave' />
          </div>
          <span>Leave</span>
        </div>
      </div>
    )
  }

  renderRecording = () => {
    return (
      <div className='recording'>
        <div className='circle' />
        <span>Recording</span>
      </div>
    )
  }

  renderDropdownMenu = (inputs, isInactive, selectedOption, callback) => {
    const options = inputs.map((option) => ({
      title: option.label,
      onClick: () => callback(option.deviceId),
      selected: option.deviceId === selectedOption,
    }));
    return (
      <div
        className={classnames({
          'expandMore': true,
          'inactive': isInactive,
        })}
      >
        <div className='icon more' />
        <DropdownMenu
          anchorRight
          options={options}
        />
      </div>
    )
  }

  render = () => {
    if (this.state.finished) {
      return this.renderLeftRoom();
    }

    return (
      <div className='videoRoomView'>
        {this.state.recordingStarted ? this.renderRecording() : null}
        {this.renderInfo()}
        {this.state.roomOpened ? null : this.renderStartMeeting()}
        {!this.props.user && !this.state.roomOpened
          ? <div className='controlsView'>
              <div>
                <label className={this.props.user ? 'fixed' : ''}>
                  <span>wait for campaign manager to open this room</span>
                </label>
              </div>
            </div>
          : null}

        <div className='media'>
          <div className='mediaContainer' id='media-container'/>
        </div>
        {this.state.roomOpened && this.state.languageCode ? <>
          <div className='controlsView'>
            {this.renderActions()}
          </div>
          </> : null}
      </div>
    );
  }
}

export default connect(mapStoreToProps)(VideoRoomView);
