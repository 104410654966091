import React from 'react';
import classnames from "classnames";

import { sendRequest, triggerEvent } from '../../helpers/global.js';
import Storage from '../../helpers/Storage.js';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import TabsView from '../common/TabsView';
import LogsList from '../input/LogsList.js';
import ChatView from '../ChatView.js';
import HeaderView from '../../components/HeaderView.js';

import '../../sass/components/editControls/GivikiEditView.scss';

const TABS = [
  {
    key: 'general',
    title: 'General',
    sections: [
      {
        title: 'Checklist',
        fields: [
          'organization_name', 'country_id', 'country_other', 'todo'
        ]
      },
      {
        title: 'Contact',
        fields: [
          'contact_name', 'contact_email', 'contact_phone', 'how_know',
          'how_know_details', 'send_start_agreement', 'call_contact'
        ]
      },
      {
        title: 'Documents',
        fields: [
          'documents',
        ]
      },
      {
        title: 'Verifier',
        fields: [
          'user_id', 'verifier_id', 'remarks',
        ]
      },
    ],
  },
  {
    key: 'organization',
    title: 'Organization',
    sections: [
      {
        fields: [
          'organization_name', 'currency', 'currency_other', 'organization_registered',
          'document_proof', 'registered_on_other_platform', 'other_platform',
          'tax_deduction', 'min_donation', 'giviki_category_ids', 'staff_size'
        ]
      },
    ],
  },
  {
    key: 'brand',
    title: 'Brand',
    sections: [
      {
        fields: [
          'website_subdomain', 'charity_slug', 'logo', 'banner', 'organization_email',
          'organization_phone', 'organization_description', 'organization_website',
          'youtube_url', 'facebook_url', 'twitter_url', 'brand_color_hex'
        ]
      },
    ],
  },
  {
    key: 'account',
    title: 'Account',
    sections: [
      {
        fields: [
          'bank_account_name', 'bank_account_number', 'bank_name', 'bank_code',
          'bank_branch_code', 'bank_swift_code', 'bank_proof_document', 'financial_statement'
        ]
      },
    ],
  },
  {
    key: 'logs',
    title: 'Logs',
  },
];

const mapStoreToProps = (store) => {
  return {
    isMobile: store.setup.is_mobile,
  }
};

class GivikiEditView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: TABS[0].key,
      changes: [],
      files: [],
      countries: [],
      activeKeyboard: false,
      cancelReason: null,
    };
  }

  componentDidMount = () => {
    this.requestUsers();
    this.requestStaffOptions();
    this.requestHowKnowOptions();
    this.requestData();
  }

  componentDidUpdate = (prevProps, prevState) => {
    const curItem = this.props.item || {};
    const prevItem = prevProps.item || {};
    if (curItem.id && curItem.id !== prevItem.id) {
      this.requestData();
    } else if (curItem.country_id && curItem.country_id !== prevItem.country_id) {
      sendRequest({
        method: `collections/countries/${curItem.country_id}/todo`,
        type: 'GET',
        success: (data) => {
          this.props.onValueChange('todo', data || []);
        },
        error: (data) => {
        }
      });
    }
  }

  requestData = () => {
    const item = this.props.item;
    if (!item || !item.id) {
      return;
    }
    sendRequest({
      method: `givikis/${item.id}/changes`,
      type: 'GET',
      success: (data) => {
        this.setState({changes: data});
      },
      error: (data) => {
      }
    });
    sendRequest({
      method: `givikis/${item.id}/conversation_files`,
      type: 'GET',
      success: (data) => {
        this.setState({files: data});
      },
      error: (data) => {
      }
    });
    sendRequest({
      method: `collections/countries`,
      type: 'GET',
      success: (data) => {
        this.setState({countries: data});
      },
      error: (data) => {
      }
    });
  }

  requestUsers = () => {
    sendRequest({
      method: `collections/users`,
      type: 'GET',
      success: (data) => {
        Storage.setData('users', data);
      },
      error: (data) => {
      }
    });
  }

  requestStaffOptions = () => {
    sendRequest({
      method: `giviki_forms/staff_options/`,
      type: 'GET',
      success: (data) => {
        Storage.setData('staffOptions', data);
      },
      error: (data) => {
      }
    });
  }

  requestHowKnowOptions = () => {
    sendRequest({
      method: `giviki_forms/how_know_options/`,
      type: 'GET',
      success: (data) => {
        Storage.setData('howKnowOptions', data);
      },
      error: (data) => {
      }
    });
  }

  changeStatus = (status) => {
    const item = this.props.item;
    if (status === 'pending_approval' && !item['verifier_id']) {
      triggerEvent('showSnackbar', [{text: 'No Case Manager assigned.', type: 'error'}]);
      if (this.state.tab === 'general') {
        this.props.onError({verifier_id: 'This field is required'});
      }
      return;
    }
    sendRequest({
      method: `givikis/${item.id}/status`,
      type: 'PUT',
      data: {
        status: status,
        cancel_reason: this.state.cancelReason
      },
      success: (data) => {
        this.props.onValueChange('status', status);
        triggerEvent('showSnackbar', [{text: `Giviki ${status}`}]);
        if (status === 'pending') {
          this.switchTab('general');
        } else if (['pending_approval', 'approved'].includes(status)) {
          const tabIndex = TABS.findIndex(tab => tab.key === this.state.tab);
          const nextTab = TABS[tabIndex + 1];
          const nextTabKey = nextTab.key;
          this.switchTab(nextTabKey);
        }
      },
      error: (data) => {
      }
    });
  }

  rejectGiviki = () => {
    triggerEvent('showRejectCampaign', [{
      callback: result => {
        if (result) {
          this.setState({ cancelReason: result }, () => this.changeStatus('rejected'));
        }
      },
      campaign: this.props.item,
      type: 'giviki'
    }]);
  }

  onLogout = (unauthorized) => {
    if (!unauthorized) {
      sendRequest({
        type: 'POST',
        method: 'me/logout',
      });
    }
    Storage.setData('user', null);
    localStorage.removeItem('give-campaign-manager:token');
  }

  onShowKeyboard = (value) => {
    this.setState({ activeKeyboard: value });
  }

  switchTab = (tab) => {
    this.setState({ tab });
  }

  renderInput = (key) => {
    const item = this.props.item;
    if (key === 'country_other') {
      const country = this.state.countries.find(i => i.id === this.props.item['country_id']);
      if (!country || country.code !== 'other_country') {
        return null;
      }
    } else if (key === 'call_contact') {
      return (
        <div className='buttonContainer'>
        <button className='outline' onClick={this.makePhoneCall}>
          Call Contact
        </button>
      </div>
      )
    } else if (key === 'send_start_agreement') {
      const haveStarting = (item.agreements_statuses || {}).getting_started;
      if (!haveStarting) {
        return (
          <div className='buttonContainer'>
            <button className='outline' onClick={() => this.onSendAgreement('getting_started')}>
              Send Getting Started agreement
            </button>
          </div>
        )
      }
    } else {
      return this.props.renderInput(key);
    }
  }

  renderTabContent = () => {
    const sections = TABS.find(t => t.key === this.state.tab).sections || [];
    const { changes = [], files = [] } = this.state;
    return (
      <div className={`inputsBlock ${this.state.tab}`}>
        {sections.map((section, index) =>
          <div key={index} className='blockSection card'>
            {section.title ?
              <div className='sectionTitle'>{section.title}</div>
            : null}
            {(section.fields || []).map(key => <div key={key}>{this.renderInput(key)}</div>)}
          </div>
        )}

        {this.state.tab === 'logs' && changes.length ?
          <div className='blockSection card'>
            <LogsList object={changes}/>
          </div>
        : null}

        {this.state.tab === 'general' && files.length ?
          <div className='fieldContainer'>
            <label>Conversation files</label>
            <div className='fileList'>
              {files.map(file =>
                <div key={file.id} className='fileItem'>
                  <span onClick={() => window.open(file.file_url, '_blank')}>
                    {file.title}
                  </span>
                </div>
              )}
            </div>
          </div>
        : null}

      </div>
    )
  }

  renderApproveButton = () => {
    const status = this.props.item.status;
    if (status === 'pending_approval') {
      return (
        <button className='completeStep' onClick={() => this.changeStatus('approved')}>
          Approve
        </button>
      )
    } else if (status === 'pending') {
      return (
        <button className='completeStep' onClick={() => this.changeStatus('pending_approval')}>
          Submit for Approval
        </button>
      )
    }
  }

  renderCancelButton = () => {
    const status = this.props.item.status;
    if (status === 'rejected') {
      return (
        <button
          className='outline outline1 outlineReject'
          onClick={() => this.changeStatus('pending')}
        >Re-open</button>
      )
    } else if (status === 'pending_approval') {
      return (
        <button
          className='outline outline1 outlineReject'
          onClick={() => this.changeStatus('pending')}
        >Decline</button>
      )
    } else if (status === 'approved') {
      return (
        <button
          className='outline outline1 outlineReject'
          onClick={() => this.changeStatus('pending')}
        >Un-publish</button>
      )
    } else {
      return (
        <button
          className='outline outline1 outlineReject'
          onClick={() => this.rejectGiviki()}
        >Reject</button>
      )
    }
  }

  makePhoneCall = () => {
    const item = this.props.item;
    triggerEvent('showPhoneCall', [{
      phone: item.contact_phone,
    }]);
  }

  onSendAgreement = (type) => {
    const item = this.props.item;
    triggerEvent('openSendAgreementPopup', [{
      id: item.id,
      type: type,
      email: item.contact_email,
      entity: 'givikis'
    }])
  }

  render = () => {
    const item = this.props.item;
    const header = item && item.organization_name;
    return (
      <div className='givikiEditView'>
        <HeaderView
          onLogout={this.onLogout}
          header={header}
          showBackLink={true}
          tagsContent={this.props.item.status}
        />
        <TabsView
          tabs={TABS}
          tab={this.state.tab}
          onTabChange={this.switchTab}
          compact
        />
        {this.renderTabContent()}
        <div
            className={classnames({
              'chatSidebar': true,
              'closed': !this.state.showChat,
              'sidebarWithActiveKeyboard': this.state.activeKeyboard
            })}
        >
          <ChatView
            embedded
            identifier={item.chat_identifier}
            onShowKeyboard={this.onShowKeyboard}
          />
        </div>
        {this.props.isMobile ?
            <div className='bottomTabs'>
              {this.state.activeKeyboard
                ? null
                : <TabsView
                    tabs={[
                      { key: 'case', title: 'Case' },
                      { key: 'chat', title: 'Chat' },
                    ]}
                    tab={this.state.bottomTab}
                    onTabChange={tab => this.setState({bottomTab: tab, showChat: tab === 'chat'})}
                    compact
                  />}
            </div>
        : null}

        <div className="right-btn">
          <div className='buttonContainer right'>
            {this.renderApproveButton()}
            {this.renderCancelButton()}
          </div>
        </div>

      </div>
    )
  }
}

export default connect(mapStoreToProps)(withRouter(GivikiEditView));
