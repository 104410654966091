import React from 'react';
import classnames from 'classnames';
import update from 'immutability-helper';

import { sendRequest } from '../helpers/global.js';
import { isIOS, eventOn } from '../helpers/global.js';
import givikiConfig from '../givikiConfig.js';

import GivikiFormInput from './GivikiFormInput.js';
import { withRouter } from 'react-router-dom';

import '../sass/components/GivikiFormView.scss';

class GivikiFormView extends React.Component {

  constructor(props) {
    super(props);
    let data = {};
    const stepKeys = Object.keys(givikiConfig);
    stepKeys.forEach(key => data[key] = {});
    this.state = {
      showIntroduction: true,
      givikiId: null,
      data,
      errors: {},
      stepKeys,
      step: stepKeys[0],
      formComplete: false,
      stepErrors: {}
    };
  }

  componentDidMount = () => {
    sendRequest({
      method: 'giviki_forms',
      type: 'POST',
      success: (data) => {
        this.setState({givikiId: data.id});
      },
      error: (data) => {
      }
    });
    this.emitMessage({
      success: true,
      data: 'iframe loaded'
    });
  }

  checkRequiredFields = () => {
    const { data, step } = this.state;
    let stepError = {}
    Object.keys(givikiConfig[step]).forEach((res) => {
      if (givikiConfig[step][res].required) {
        const checkFieldValue = data[step][res] !== undefined && data[step][res] !== ''
        const requiredCondition = !(res && (givikiConfig[step][res].check ? givikiConfig[step][res].check(data) :
          givikiConfig[step][res].showCondition ?
            givikiConfig[step][res].showCondition(data) ?
              checkFieldValue : true
            :
            checkFieldValue))
        if (requiredCondition && 'agreement_accepted' === res) {
          stepError[res] = 'Please, confirm this'
        } else if (requiredCondition) {
          stepError[res] = 'This field is required'
        }}
      }
    )
    return Object.keys(stepError).length ? stepError : null
  }

  emitMessage = (message) => {
    window.parent.postMessage(message, "*");
  }

  saveData = (lastStep) => {
    const errors = this.checkRequiredFields()
    if (errors) {
      return this.setState({errors})
    }
    const {data, step, stepKeys} = this.state;
    const current = stepKeys.indexOf(step);

    let formData = new FormData();
    Object.keys(data[step]).forEach(key => {
      if (typeof data[step][key] === 'object' && data[step][key].constructor.name !== 'File') {
        formData.append(key, JSON.stringify(data[step][key]));
      } else {
        formData.append(key, data[step][key]);
      }
    });

    this.setState({errors: {}});
    sendRequest({
      method: `giviki_forms/${this.state.givikiId}`,
      type: 'PUT',
      formData,
      success: (data) => {
        this.emitMessage('data submitted');
        if (lastStep) {
          this.setState({formComplete: true});
          this.emitMessage('form completed');
        } else {
          this.setState({step: stepKeys[current + 1]}, () => {
            document.querySelector('.adminContent').scrollTo({ top: 0, behavior: 'smooth' });
          })
        }
      },
      error: (data) => {
        if (data.errors) {
          this.setState({errors: data.errors});
        }
      }
    });
  }

  handleValueChange = (key, value, extended) => {
    const { data, step } = this.state;
    let change = {
      [step]: {
        [key]: {$set: value},
      }
    };
    if (step === 'organization' && key === 'country_id') {
      change[step]['show_other_country'] = {
        $set: (extended || {}).code === 'other_country'
      };
    }
    this.setState({data: update(data, change)})
  }

  renderStepper = () => {
    const { step, stepKeys } = this.state;
    const percent = this.state.formComplete ? 100 : stepKeys.indexOf(step) / stepKeys.length * 100;
    return (
      <div className='stepperContainer'>
        <div className='stepperIndicator' style={{width: `${percent}%`}}/>
      </div>
    )
  }

  renderField = (key, field) => {
    const { data, errors, step } = this.state;
    if (givikiConfig[step][key].showCondition) {
      if (!givikiConfig[step][key].showCondition(data)) {
        return null;
      }
    }
    return (
      <GivikiFormInput
        key={key}
        field={field}
        fieldKey={key}
        data={data}
        step={step}
        error={errors[key]}
        onChange={this.handleValueChange}
      />
    )
  }

  renderNextButton = () => {
    const { step, stepKeys } = this.state;
    const current = stepKeys.indexOf(step);
    const lastStep = current >= stepKeys.length - 1;
    return (
      <div
        className={classnames({
          'nextButton': true,
        })}
        onClick={() => {
          this.saveData(lastStep);
        }}
      >{lastStep ? 'Submit' : 'Next'}</div>
    )
  }

  renderIntroduction = () => {
    return (
      <div className='givikiFormView'>
        <div className='givikiIntroduction'>
          <div className='givikiImage'/>
          <div className='introductionContent'>
            Thanks for your interest in having your very own branded donation
            and fundraising website powered by giviki.<br/>
            Giviki is a product offered
            by <a href='https://give.asia/' target='_blank' rel='noopener noreferrer'>www.GIVE.asia</a> to
            charities and nonprofit organisations who want to provide their donors
            and fundraisers with a more consistent giving experience.
          </div>
          <div className='introductionTips'>
            Please have the proof of the following ready before signing up:
            1) Registration certificate of your nonprofit organisation in English.
            2) Bank account statement under your organisation name.
            3) Latest financial statement or annual report
            <span>Takes 7+ mins</span>
          </div>
          <button onClick={() => this.setState({showIntroduction: false})}>Start</button>
        </div>
      </div>
    )
  }

  renderHeader = () => {
    const { step, stepKeys } = this.state;
    const current = stepKeys.indexOf(step);
    return (
      <div className='givikiHeader'>
        <div
          className={classnames({
            'headerButton': true,
            'disabled': this.state.formComplete,
          })}
          onClick={() => {
            if (this.state.formComplete) {
              return;
            }
            if (current > 0) {
              this.setState({step: stepKeys[current - 1]}, () => {
                document.querySelector('.adminContent').scrollTo({ top: 0, behavior: 'smooth' });
              });
            } else {
              this.setState({showIntroduction: true});
            }
          }}
        >
          <span className='material-icons'>keyboard_backspace</span>
        </div>
        {this.renderStepper()}
        <div
          className='headerButton'
          onClick={() => this.emitMessage('form close')}
        >
          <span className='material-icons'>close</span>
        </div>
      </div>
    )
  }

  render = () => {
    if (this.state.showIntroduction) {
      return this.renderIntroduction();
    }
    const fields = givikiConfig[this.state.step] || {};
    return (
      <div className='givikiFormView'>
        {this.renderHeader()}
        {this.state.formComplete
          ? <div className='completeMessage'>
            Thank you registering your charity with GIVE.asia. We will review your submission and get back to you within 3 working days.<br/>
            If there is any questions, please browse our FAQ page <a href='https://help.give.asia/article/313-charity-staff-faqs' target='_blank' rel='noopener noreferrer'>https://help.give.asia/article/313-charity-staff-faqs </a> or contact our customer support at <a href="mailto:support@give.asia.com">support@give.asia</a>
          </div>
          : <div className='fieldsContainer'>
            {Object.keys(fields).map(key => this.renderField(key, fields[key]))}
            {this.renderNextButton()}
          </div>
        }
      </div>
    );
  }
}

export default withRouter(GivikiFormView);
