import React from 'react';

import '../../sass/components/input/Spinner.scss';

class Spinner extends React.Component {
  constructor(props) {
   super();
 }

  render() {
    return (
      <div className="rdw-spinner">
        <div className="rdw-bounce1" />
        <div className="rdw-bounce2" />
        <div className="rdw-bounce3" />
      </div>
    )
  }
}

export default Spinner;
