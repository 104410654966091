import React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import { eventOn, eventOff, sendRequest } from '../../helpers/global.js';
import Storage from '../../helpers/Storage.js';

import '../../sass/components/common/NotificationsButton.scss';

const mapStoreToProps = (store) => {
  return {
    notifications: store.data.notifications || {
      notifications: [],
      unread_count: 0,
    },
  }
};

class NotificationsButton extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showNotifications: false,
    };
    this.container = null;
  }

  componentDidMount = () => {
    eventOn('click', this.onDocumentClick, document);

    sendRequest({
      type: 'GET',
      method: 'notifications',
      noLoad: true,
      success: (data) => {
        Storage.setData('notifications', data);
        if (this.props.location.pathname === '/notifications') {
          this.checkUndread();
        }
      },
      error: (error) => {
      }
    });
  }

  componentWillUnmount = () => {
    eventOff('click', this.onDocumentClick, document);
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.notifications.unread_count !== prevProps.notifications.unread_count) {
      if (this.state.showNotifications || this.props.location.pathname === '/notifications') {
        this.checkUndread();
      }
    }
  }

  onDocumentClick = (e) => {
    if (!this.state.showNotifications) {
      return;
    }
    let contained = false;
    let node = e.target.parentNode;
    let depth = 0;
    while (node !== null && depth < 5) {
      if (node === this.container) {
        contained = true;
        break;
      }
      node = node.parentNode;
      depth++;
    }
    if (!contained) {
      this.setState({showNotifications: false});
    }
  }

  checkUndread = () => {
    sendRequest({
      type: 'POST',
      method: 'notifications/check',
      noLoad: true,
      success: (data) => {
        Storage.setData('notifications', {
          notifications: this.props.notifications.notifications.map(item => ({
            ...item,
            checked: true,
          })),
          unread_count: 0,
        });
      }
    });
  }

  onNotificationsClick = () => {
    if (!this.state.showNotifications && this.props.notifications.notifications) {
      this.checkUndread();
    }
    this.setState({showNotifications: !this.state.showNotifications});
  }

  renderNotificationItem = (item, index) => {
    return (
      <div
        key={item.id}
        className={
          classnames({
            'notificationItem': true,
            'unread': !item.checked,
          })
        }
        onClick={() => {
          if (item.path) {
            this.props.history.push(item.path);
          }
        }}
      >
        <div className='notificationContent'>
          <div className='notificationBody' dangerouslySetInnerHTML={{__html: item.title}}/>
          <div className='notificationDate'>{moment.unix(item.created_at).fromNow()}</div>
        </div>
      </div>
    )
  }

  render = () => {
    let notifications = this.props.notifications;
    return (
      <div className='navigationNotifications' ref={elem => this.container = elem}>
        <div className='notificationsIcon' onClick={this.onNotificationsClick}>
          <i className="material-icons">notifications_none</i>
          {notifications.unread_count ?
            <div className='notificationsCount'>{notifications.unread_count}</div>
          : null}
        </div>
        {this.state.showNotifications ?
          <div className='notificationsBlock'>
            <div className='notificationsList'>
              <div className='notificationsHeader'>Last notifications</div>
              {notifications.notifications.length > 0
                ? <div className='notificationsContainer'>
                    {notifications.notifications.slice(0, 5).map(this.renderNotificationItem)}
                    <div
                      className='notificationsLink'
                      onClick={() => {
                        this.props.history.push('/notifications');
                        this.setState({showNotifications: false});
                      }}
                    >Show all notifications</div>
                  </div>
                : <div className='notificationsPlaceholder'>No notifications yet</div>
              }
            </div>
          </div>
        : null}
      </div>
    )
  }

}

export default connect(mapStoreToProps)(withRouter(NotificationsButton));
