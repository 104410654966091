export default {
  section: "Settings",
  config: {
    method: "users/",
    title: "Users",
    objectName: "User",
    adminOnly: true,
    search: {
      email: {
        title: "Email",
        type: "text",
      },
    },
  },
  properties: {
    avatar: {
      type: "avatar",
      width: "50px",
    },
    id: {
      default: false,
      type: "text",
      title: "#",
      preset: true,
      width: "60px",
    },
    created_at: {
      default: "",
      type: "datetime",
      title: "Created time",
      preset: true,
    },
    updated_at: {
      default: "",
      type: "datetime",
      title: "Modified Time",
      preset: true,
    },
    image_url: {
      type: "none",
    },
    image: {
      default: "",
      type: "image",
      title: "Image",
    },
    email: {
      default: "",
      type: "text",
      title: "Email",
      width: "auto",
      validation: "users/validate",
    },
    name: {
      default: "",
      type: "text",
      title: "Name",
      width: "auto",
    },
    password: {
      type: "password",
      title: "Password",
    },
    phone: {
      default: "",
      type: "text",
      title: "Phone",
    },
    charity_ids: {
      type: "multi-select",
      title: "Charities",
      request: "collections/charities/",
    },
    approved: {
      type: "checkbox",
      title: "Approved",
      width: "80px",
    },
    role: {
      default: "manager",
      type: "select",
      title: "Role",
      options: [
        { id: 'manager', name: 'Manager' },
        { id: 'admin', name: 'Admin' },
        { id: 'case_supporter', name: 'Case Supporter' },
      ],
      width: "auto"
    },

  }
};
