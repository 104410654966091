import React from 'react';
import AwesomeSlider from 'react-awesome-slider';

import classnames from 'classnames';
import CSSTransition from 'react-transition-group/CSSTransition';

import { eventOn, eventOff } from '../../helpers/global';

import '../../sass/components/common/CarouselPopup.scss';
import 'react-awesome-slider/dist/styles.css';

class CarouselPopup extends React.Component {
  constructor(props) {
    super();
    this.state = {
      hidden: !props.show,
      show: false,
      imageIndex: null,
      images:  props.images || []
    };
  }

  componentDidMount = () => {
    if (this.props.global) {
      eventOn('openCarouselPopup', this.openCarouselPopup);
    }
  }

  componentWillUnmount = () => {
    if (this.props.global) {
      eventOff('openCarouselPopup', this.openCarouselPopup);
    }
  }

  openCarouselPopup = (e) => {
    const { imageId, images } = e.detail[0];
    const imageIndex = images.findIndex(img => img.id === imageId)
    this.setState({
      hidden: false,
      show: true,
      imageIndex,
      images
    });
  }

  onOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      this.onClose();
    }
  }

  onClose = () => {
    if (this.props.global) {
      this.setState({show: false});
    } else if (this.props.onClose) {
      this.props.onClose();
    }
  }

  renderButtonContent = (type) => {
    return (
        <p className="buttonSlider">{type}</p>
    )
  }

  render = () => {
    const { show, imageIndex, images } = this.props.global ? this.state : this.props;

    return (
      <div
        className={classnames({
          'carouselFramePopup': true,
          'hidden': this.state.hidden,
        })}
      >
        <CSSTransition
          in={show}
          timeout={350}
          classNames='fade'
          onEnter={() => this.setState({hidden: false})}
          onExited={() => this.setState({hidden: true})}
        >
          <div
            onClick={this.onOverlayClick}
            className='overlay'
          >
            <div className='popupWindow'>
              {imageIndex !== null ?
                <AwesomeSlider
                    selected={imageIndex}
                    organicArrows={false}
                    bullets={false}
                    buttonContentRight={this.renderButtonContent('>')}
                    buttonContentLeft={this.renderButtonContent('<')}
                >
                  {images.map((object, index) =>
                      <div key={index} style={{backgroundColor: 'block', display: 'contents'}}>
                        <div className='popupTitle'>{object.name}</div>
                        <img alt={object.name} src={object.view_url}/>
                      </div>
                  )}
                </AwesomeSlider>
              : null }
              <div className='closeButton' onClick={this.onClose}/>
            </div>
          </div>
        </CSSTransition>
      </div>
    )
  }
}

export default CarouselPopup;
