import React from 'react';
import classnames from 'classnames';
import CSSTransition from 'react-transition-group/CSSTransition';

import { eventOn, eventOff } from '../../helpers/global';

import '../../sass/components/common/UploadingPopup.scss';

class UploadingPopup extends React.Component {
  constructor(props) {
    super();
    this.state = {
      hidden: !props.show,
      show: false,
      status: null,
    };
  }

  componentDidMount = () => {
    if (this.props.global) {
      eventOn('showUploadingPopup', this.showUploadingPopup);
      eventOn('hideUploadingPopup', this.hideUploadingPopup);
    }
  }

  componentWillUnmount = () => {
    if (this.props.global) {
      eventOff('showUploadingPopup', this.showUploadingPopup);
      eventOff('hideUploadingPopup', this.hideUploadingPopup);
    }
  }

  showUploadingPopup = (e) => {
    const { status } = e.detail[0];
    this.setState({
      hidden: false,
      show: true,
      status,
    });
  }

  hideUploadingPopup = () => {
    this.setState({ show: false });
  }

  onOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      this.setState({show: false});
    }
  }

  getIcon = (status) => {
    if (status === 'pending') {
      return '/images/icons/icon_loading.svg';
    } else if (status === 'success') {
      return '/images/icons/icon_success.svg';
    } else if (status === 'failed') {
      return '/images/icons/icon_failed.svg';
    }
  }

  renderMessage = (status) => {
    if (status === 'pending') {
      return 'Uploading';
    } else if (status === 'success') {
      return 'Upload successful';
    } else if (status === 'failed') {
      return 'Upload failed, please try again';
    }
  }

  render = () => {
    const { show, status } = this.props.global ? this.state : this.props;
    return (
      <div
        className={classnames({
          'uploadingPopup': true,
          'hidden': this.state.hidden,
        })}
      >
        <CSSTransition
          in={show}
          timeout={350}
          classNames='fade'
          onEnter={() => this.setState({hidden: false})}
          onExited={() => this.setState({hidden: true})}
        >
          <div
            onClick={this.onOverlayClick}
            className='overlay'
          >
            <div className='popupWindow'>
              <div className='contentContainer'>
                <img
                  className={`uploadingIcon ${status}`}
                  src={this.getIcon(status)}
                  alt='uploading'
                />
                <div className='popupMessage'>{this.renderMessage(status)}</div>
              </div>
            </div>
          </div>
        </CSSTransition>
      </div>
    )
  }
}

export default UploadingPopup;
