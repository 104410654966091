import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import moment from 'moment';
import ActionCable from 'actioncable';
import { withRouter } from 'react-router-dom';
import 'balloon-css';

import Storage from '../helpers/Storage.js';
import { WSUrl, sendRequest, triggerEvent } from '../helpers/global.js';

import TextInput from '../components/input/TextInput.js';
import CaptureAudio from './common/CaptureAudio';

import '../sass/components/ChatView.scss';

const mapStoreToProps = (store) => {
  return {
    user: store.data.user,
    isMobile: store.setup.is_mobile,
    participantData: store.data.participantData,
  }
};

class ChatView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      userData: this.getUserData(),
      errors: {},
      fundraiser: null,
      giviki: null,
      lead_id: null,
      giviki_id: null,
      phone: null,
      messages: [],
      message: '',
      file: null,
      search: '',
      matches: [],
      currentMatch: 0,
      selected: [],
      parent_identifier: null,
      record: false,
      audio: null,
      audioFromCapture: null,
      recording: false,
      height: 44,
      activeKeyboard: false,
    };
    this.cable = null;
    this.subscription = null;
    this.fileInput = null;
    this.pingTimer = null;
  }

  paramIdentifier = () => {
    return this.props.embedded ? this.props.identifier : this.props.match.params.identifier;
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.embedded && (this.props.identifier !== prevProps.identifier)) {
      this.disconnectChannel();
      this.connectToChat();
    }
    if (prevState.message !== this.state.message) {
      const textarea = document.createElement('textarea');
      textarea.classList.add('tmp');
      textarea.value = this.state.message;
      this.container.appendChild(textarea);
      const height = Math.min(205, textarea.scrollHeight);
      this.container.removeChild(textarea);
      if (this.state.height !== height) {
        this.setState({height});
      }
    }

    if (prevState.activeKeyboard !== this.state.activeKeyboard) {
      this.props.onShowKeyboard && this.props.onShowKeyboard(this.state.activeKeyboard);
    }
  }

  componentDidMount = () => {
    if (this.state.userData.ready) {
      this.connectToChat();
    } else {
      this.requestMessages();
      this.requestNewMessages();
      this.connectChannel();
    }
  }

  componentWillUnmount = () => {
    this.disconnectChannel();
  }

  getUserData = () => {
    if (this.props.user) {
      return {
        email: this.props.user.email,
        name: this.props.user.name,
        ready: true,
      }
    } else if (this.props.participantData) {
      return {
        email: this.props.participantData.email,
        name: this.props.participantData.name,
        ready: true,
      }
    } else {
      return {
        email: '',
        name: '',
        ready: false,
      }
    }
  }

  scrollMessages = () => {
    const messages = document.querySelector('.messages');
    messages.scrollTo({
      top: messages.scrollHeight,
      behavior: 'smooth'
    });
  }

  requestMessages = () => {
    sendRequest({
      type: 'GET',
      method: `chats/${this.paramIdentifier()}`,
      success: (data) => {
        this.setState({
          messages: data.messages,
          fundraiser: data.fundraiser,
          giviki: data.giviki,
          lead_id: data.lead_id,
          giviki_id: data.giviki_id,
          phone: data.phone,
          parent_identifier: data.parent_identifier,
          userData: {
            ...this.state.userData,
            email: data.fundraiser.email,
            name: data.fundraiser.name,
          }
        },
          this.scrollMessages
        );
      },
      error: (data) => {
      }
    });
  }

  connectToChat = () => {
    if (!this.paramIdentifier()) {
      return;
    }
    sendRequest({
      type: 'GET',
      method: `chats/${this.paramIdentifier()}/participant`,
      data: {
        name: this.state.userData.name,
        email: this.state.userData.email,
      },
      success: (data) => {
        this.setState(
          {
            identifier: data.identifier,
            userData: {
              ...this.state.userData,
              ready: true,
            }
          },
          () => {
            this.requestMessages();
            this.requestNewMessages();
            this.connectChannel();
            if (this.props.onRegisterParticipant) {
              this.props.onRegisterParticipant(data.identifier);
            }
          }
        );
      },
      error: (data) => {
        this.setState({errors: data});
      }
    });
  };

  requestNewMessages = () => {
    sendRequest({
      type: 'GET',
      method: `chat_participants/${this.state.identifier}/new_messages`,
      success: (data) => {
        if (this.props.onLoadNewMessages) {
          this.props.onLoadNewMessages(data);
        }
        if (data.length) {
          triggerEvent('setHeaderTitle', [{title: 'You have new message'}]);
          this.readMessages();
        }
      },
      error: (data) => {
        this.setState({errors: data});
      }
    });
  };

  readMessages = () => {
    if (this.props.keepUnread) {
      return;
    }
    sendRequest({
      type: 'POST',
      method: `chat_participants/${this.state.identifier}/read_messages`,
      success: (data) => {
        if (this.props.onReadMessages) {
          this.props.onReadMessages();
        }
      },
      error: (data) => {
        this.setState({errors: data});
      }
    });
  };

  disconnectChannel = () => {
    clearInterval(this.pingTimer);
    this.pingTimer = null;
    if (this.cable) {
      if (this.subscription) {
        this.subscription.unsubscribe();
        this.subscription = null;
      }
      this.cable.disconnect();
      this.cable = null;
    }
  }

  connectChannel = () => {
    this.disconnectChannel();
    this.cable = ActionCable.createConsumer(WSUrl());
    this.subscription = this.cable.subscriptions.create({
      channel: 'ChatChannel',
      identifier: this.paramIdentifier(),
    }, {
      connected: () => {
        console.log(`Connected to chat with ${this.paramIdentifier()}`);
        this.pingTimer = setInterval(() => {
          if (this.subscription) {
            this.subscription.ping_online();
          }
        }, 30 * 1000);
      },
      disconnected: () => {
        clearInterval(this.pingTimer);
        this.pingTimer = null;
      },
      received: (data) => {
        if (data.action === 'create') {
          if (this.props.embedded && this.props.onMessageAdded) {
            this.props.onMessageAdded(data);
          }
          this.setState({
            messages: [
              ...this.state.messages,
              data.message,
            ]
          }, this.scrollMessages);
        } else if (data.action === 'delete') {
          this.setState({
            messages: this.state.messages.filter(
              m => data.message_ids.indexOf(m.id) < 0
            ),
          })
        }
      },
      ping_online: () => {
        return this.subscription.perform('ping_online', {
          chat_participant: this.state.identifier,
        });
      }
    });
  }

  onDataEnter = () => {
    let errors = {};
    if (!this.state.userData.name) {
      errors.name = 'Please enter name';
    }
    if (!this.state.userData.email) {
      errors.email = 'Please enter email';
    }

    if (Object.keys(errors).length) {
      this.setState({errors});
    } else {
      this.setState({errors: {}}, this.connectToChat);
      Storage.setData('participantData', {
        name: this.state.userData.name,
        email: this.state.userData.email,
      });
    }
  }

  onChangeAudioHandler = (val) => {
    this.setState({audioFromCapture: val})
  }

  onChangeVoiceRecodringHandler = (val) => {
    this.setState({recording: val})
  }

  sendMessage = () => {
    if ( this.state.audioFromCapture ) {
      this.sendVoiceMessage()
    } else if (this.state.message) {
      this.sendTextMessage()
    } else {
      return
    }
  }

  sendTextMessage = () => {
    if (!this.state.message || !this.paramIdentifier()) {
      return;
    }
    sendRequest({
      type: 'POST',
      method: `chats/${this.paramIdentifier()}/message`,
      data: {
        chat_participant: this.state.identifier,
        content: this.state.message,
      },
      success: (data) => {
        this.setState({message: ''});
      },
      error: (data) => {
      }
    });
  }

  sendVoiceMessage = async () => {
    if (!this.state.audioFromCapture) {
      return;
    }

    const blob = await fetch(this.state.audioFromCapture).then(r => r.blob());
    let file = new File([blob], 'voice.mp3', {type: 'audio/mp3'});
    let formData = new FormData();

    formData.append('chat_participant', this.state.identifier);
    formData.append('voice', file);

    sendRequest({
      type: 'POST',
      method: `chats/${this.paramIdentifier()}/voice_message`,
      data: formData,
      success: (data) => {
        this.setState({audioFromCapture: null});
      },
      error: (data) => {
      }
    });
  }

  sendFile = (e) => {
    let formData = new FormData();
    formData.append('chat_participant', this.state.identifier);
    formData.append('file', this.state.file);
    sendRequest({
      type: 'POST',
      method: `chats/${this.paramIdentifier()}/document`,
      formData,
      success: (data) => {
        this.setState({file: null});
        this.fileInput.value = '';
      },
      error: (data) => {
      }
    });
  }

  onSearchChange = (search) => {
    const matches = search ?
      this.state.messages
        .filter(m =>
          (m.content && m.content.toLowerCase().match(search.toLowerCase()))
          || (m.file && m.file.name && m.file.name.toLowerCase().match(search.toLowerCase()))
        )
        .map(m => m.id)
    : [];
    this.setState({search, matches, currentMatch: 0});
    if (matches.length) {
      setTimeout(() => {
        this.scrollIntoMessage(matches[0]);
      }, 75);
    }
  }

  switchMatch = (change) => {
    if (!this.state.matches.length) {
      return;
    }
    let currentMatch = (this.state.currentMatch + change) % this.state.matches.length;
    if (currentMatch < 0) {
      currentMatch += this.state.matches.length;
    }
    this.setState({currentMatch});
    this.scrollIntoMessage(this.state.matches[currentMatch])
  }

  scrollIntoMessage = (id) => {
    const elem = document.querySelector(`[data-id="${id}"]`);
    if (elem) {
      elem.scrollIntoView({behavior: 'smooth', block: 'center'});
    }
  }

  attachFile = () => {
    if (this.state.audioFromCapture !== null) {
      return;
    }
    if (this.state.file) {
      this.setState({file: null});
      this.fileInput.value = '';
    }
    this.fileInput.click();
  }

  makePhoneCall = () => {
    triggerEvent('showPhoneCall', [{
      phone: this.state.phone,
      data:
        this.state.lead_id ? { lead_id: this.state.lead_id } :
        this.state.giviki_id ? { giviki_id: this.state.giviki_id } :
        {},
      hidePhone: true,
    }]);
  }

  createVideoRoom = () => {
    const parent_identifier = this.state.parent_identifier;
    const chat_parent_type = this.state.giviki ? 'givikis' : 'leads';
    const tab = window.open();
    let method = `aggregated_${chat_parent_type}/${parent_identifier}/video_chat`;
    if (!this.props.aggregatedLead && chat_parent_type !== 'givikis') {
      method = `leads/${this.props.match.params.id}/video_chat`;
    } else if (!this.props.aggregatedGiviki && chat_parent_type !== 'leads') {
      method = `givikis/${this.props.match.params.id}/video_chat`;
    }
    sendRequest({
      method: method,
      type: 'GET',
      success: (data) => {
        tab.location = `/video_rooms/${data.identifier}`;
      },
      error: (data) => {
        tab.close();
      }
    });
  }

  onDeleteMessages = () => {
    triggerEvent('showConfirmation', [{
      title: `Are you sure want to delete ${this.state.selected.length} message(s)?`,
      confirmText: 'Delete',
      cancelText: 'Cancel',
      callback: confirm => {
        if (confirm) {
          sendRequest({
            method: `chats/${this.paramIdentifier()}/messages`,
            type: 'DELETE',
            data: {
              message_ids: this.state.selected,
            },
            success: (data) => {
              this.setState({
                messages: this.state.messages.filter(
                  m => this.state.selected.indexOf(m.id) < 0
                ),
                selected: [],
              });
            },
            error: (data) => {
            }
          });
        }
      }
    }]);
  }

  renderPicture = (image, alt, onClick, className) => {
    return (
      <picture onClick={onClick} className={className}>
        <source type="image/webp" srcSet={`${image}.webp, ${image}@2x.webp 2x`} />
        <source type="image/png" srcSet={`${image}@2x.png 2x`} />
        <img src={`${image}.png`} alt={`${alt}`} />
      </picture>
    )
  }

  renderTextInput = (key, title) => {
    return (
      <div className='inputContainer'>
        <label>{title}</label>
        <TextInput
          properties={{type: 'text'}}
          object={this.state.userData[key]}
          onChange={(k, value) =>
            this.setState({
              userData: {...this.state.userData, [key]: value}
            })
          }
        />
        {this.state.errors[key] ?
          <div className='inputError'>{this.state.errors[key]}</div>
        : null}
      </div>
    )
  }

  renderMediaText = (file, index) => {
    if (file.media_text) {
      if (file.show_text) {
        return (
          <>
            <div
              className='fileLink'
              onClick={(e) => {
                e.stopPropagation();
                this.showMediaText(index, 'show_text')
              }}
            >
              Hide original transcription
            </div>
            <div>
              {file.media_text}
            </div>
            {this.renderEnglishMediaText(file, index)}
          </>)
      }
      else {
        return (
          <div
            className='fileLink'
            onClick={(e) => {
              e.stopPropagation();
              this.showMediaText(index, 'show_text')
            }}
          >
            Show original transcription
          </div>
        )
      }
    }
    else {return null}
  }

  renderEnglishMediaText = (file, index) => {
    if (file.english_media_text) {
      if (file.show_english_text) {
        return (<>
          <div
            className='fileLink'
            onClick={(e) => {
              e.stopPropagation();
              this.showMediaText(index, 'show_english_text')
            }}
          >
            Hide english transcription
          </div>
          <div>
            {file.english_media_text}
          </div>
        </>)
      }
      else {
        return (<div
          className='fileLink'
          onClick={(e) => {
            e.stopPropagation();
            this.showMediaText(index, 'show_english_text')
          }}
        >
          Show english transcription
        </div>)}
    }
    else {return null}
  }

  renderDataForm = () => {
    return (
      <div className='dataForm'>
        <div className='formTitle'>Confirm your name and email address to start chatting</div>
        {this.renderTextInput('name', 'Name')}
        {this.renderTextInput('email', 'Email')}
        <button onClick={this.onDataEnter}>Confirm</button>
      </div>
    )
  }

  showMediaText = (index, type) => {
    let messages = [...this.state.messages];
    let message = {...messages[index]};
    const show_text = message.file[type] ? null : true
    message.file[type] = show_text;
    messages[index] = message;
    this.setState({messages});
  }

  renderChatMessage = (message, index) => {
    const author = message.author || {};
    const urlRegex = RegExp('((?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?)', 'ig');
    const text = message.content ?
      message.content
        .replace(/\n/g, '<br/>')
        .replace(urlRegex, '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>')
    : null;
    const file = message.file || null;

    const owned = message.system ? false : this.props.user ?
      (this.props.user.id === author.user_id || this.props.user.email === author.email)
      : author.email === this.state.userData.email;
    const selectIndex = this.state.selected.indexOf(message.id);
    return (
      <div className='message' key={index.toString()}>
        <div className='messageInfo'>
          {message.system
            ? <div
                className={classnames({
                  'messageAuthor': true,
                  'ownedMessageAuthor': owned,
                  'system': message.system,
                })}
              >
              <span>GIVE.asia</span>
            </div>
            : author.email
              ? <div className='messageAuthor'> {author.name} / {author.email}</div>
              : <div
                  className={classnames({
                    'messageAuthor': true,
                    'ownedMessageAuthor': owned,
                    'system': message.system,
                  })}
                >
                  { owned ?
                    <span>You</span>
                    : <div className="authorContainer">
                        <div className="authorItem">
                          <div className='authorAvatar' style={{backgroundImage: `url(${author.image_url})`}}/>
                        </div>
                        <div className="authorItem">
                          <div className='authorName'>
                            <span>{author.name}</span>
                          </div>
                        </div>
                    </div>
                  }
                </div>
          }
        </div>

        <div
          key={index}
          data-id={message.id}
          className='messageContainer'
        >
          <div
            className={classnames({
              'messageItem': true,
              'owned': owned,
              'system': message.system,
              'searched': this.state.matches.indexOf(message.id) > -1,
              'focused': this.state.matches[this.state.currentMatch] === message.id,
              'selected': selectIndex > -1,
            })}
          >
            {text ?
              <div className='messageContent' dangerouslySetInnerHTML={{__html: text}}/>
            : null}

            {file ?
              file['url'] && file['url'].includes('voice_message') ?
              <audio ref="" controls="controls" src={file.url}></audio> :
              <>
                {this.renderMediaText(file, index)}
                <div
                  className='fileLink'
                  onClick={(e) => {
                    e.stopPropagation();
                    triggerEvent('openFramePopup', [{
                      title: file.name,
                      src: file.view_url
                    }])
                  }}
                >{file.name}</div>
              </>
            : null}
          </div>
        </div>
        <div
          className={classnames({
            'messageTime': true,
            'ownedMessageTime': owned,
            'system': message.system,
          })}
        >
          {moment.unix(message.created_at).format('DD-MMM-YYYY HH:mm')}
        </div>
      </div>
    )
  }

  renderMessageInput = () => {
    const fundraiserId = this.state.fundraiser ? this.state.fundraiser.id : null;
    const userId = this.props.user ? this.props.user.id : null;
    const from_aggregated = !fundraiserId && !userId;
    return (
      <div className='messageInput'>
        <div
          className='messageTextarea'
          ref={elem => this.container = elem}
          style={{height: `${this.state.height}px`}}
        >
          {this.renderMessageTextArea()}
          {this.state.file || this.state.audioFromCapture ?
            <div
              className='removeButton'
              onClick={() => {
                this.setState({file: null});
                this.setState({audioFromCapture: null});
                this.fileInput.value = '';
              }}
            >
              <span className='material-icons'>close</span>
            </div>
          : null}
        </div>
        <div className='messageTextarea'>
        <div className='actionsColumn'>
        <CaptureAudio
          onChangeAudio={this.onChangeAudioHandler}
          onChangeVoiceRecodring={this.onChangeVoiceRecodringHandler}
          disabled={this.state.file !== null || this.state.audioFromCapture !== null}
          aggregatedLeadView={this.props.aggregatedLead}
        />
        { !this.state.recording ?
            fundraiserId !== userId || from_aggregated ?
          <div
            className='anyUser'
            aria-label="Start video chat"
            data-balloon-pos="up-left"
          >
            <div
              className='icon videocam'
              onClick={this.createVideoRoom}
            />
          </div> :
          <div
            className='anyUser'
            aria-label="Attach File"
            data-balloon-pos="up-left"
          >
            <div
              className='icon attachFile'
              onClick={this.attachFile}
            />
          </div>
          : null
        }

        { !this.state.recording && (fundraiserId !== userId || from_aggregated) ?
          <div
            className='caseManagerChatAction'
            aria-label="Attach File"
            data-balloon-pos="up-left"
          >
            <div
              className='icon attachFile'
              onClick={this.attachFile}
            />
          </div>
          : null }

        <input
          type='file'
          onChange={e => this.setState({file: e.target.files[0]})}
          ref={input => this.fileInput = input}
        />
        </div>
        <div className='actionsColumn'>
          <div
            className={classnames({
              'sendButton': true,
              'active': this.state.file || this.state.audioFromCapture  || this.state.message,
            })}
            onMouseDown={(e) => e.preventDefault()}
            onClick={this.state.file ? this.sendFile : this.sendMessage}
          >
            <span>Send</span>
            <div className='icon send' />
          </div>
        </div>
        </div>
      </div>
    )
  }

  renderMessageTextArea = () => {
    const fundraiserId = this.state.fundraiser ? this.state.fundraiser.id : null;
    const userId = this.props.user ? this.props.user.id : null;
    if (this.state.file) {
      return <div className='fileInfo'>{this.state.file.name}</div>;
    } else if (this.state.audioFromCapture) {
      return (
        <div className='voiceAudio'>
          <audio ref="" controls="controls" src={this.state.audioFromCapture}></audio>
        </div>
      );
    } else {
      return (
        <textarea
          placeholder="Type a message"
          disabled={this.state.audioFromCapture}
          value={this.state.message}
          className={classnames({
            'customScrollBarArea': true,
            'caseManagerArea': fundraiserId !== userId,
            'fundraiserArea': fundraiserId === userId,
          })}
          onChange={e => this.setState({message: e.target.value})}
          ref={input => this.input = input}
          onFocus={() => this.props.isMobile && this.setState({activeKeyboard: true})}
          onBlur={() => this.props.isMobile && this.setState({activeKeyboard: false})}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              e.preventDefault();
              e.stopPropagation();
              if (e.shiftKey) {
                this.setState({message: this.state.message + '\n'});
              } else {
                this.sendMessage();
              }
            }
          }}
        />
      );
    }
  }

  renderSearch = () => {
    const { search, matches, currentMatch } = this.state;

    return (
      <div className='searchContainer'>

        <div className='searchInput'>
          <div className='searchIcon'>
            {this.props.aggregatedLead
              ? <div className='icon search' />
              : this.renderPicture('/images/magnifier', 'magnifier', null, 'magnifier')}
          </div>

          <input
            placeholder={this.props.aggregatedLead ? 'Search messages' : 'Search'}
            type='text'
            value={search}
            onChange={e => this.onSearchChange(e.target.value)}
          />

          <div className='searchControls'>
            {search ?
              <div
                className={classnames({
                  'searchSwitch': true,
                  'disabled': !matches.length,
                })}
                onClick={() => this.switchMatch(-1)}
              >
                <span className='material-icons'>arrow_left</span>
              </div> : null
            }

            {search ?
              <div
                className={classnames({
                  'searchSwitch': true,
                  'disabled': !matches.length,
                })}
                onClick={() => this.switchMatch(1)}
              >
                <span className='material-icons'>arrow_right</span>
              </div> : null
            }
          </div>

          <div className='resultLabel'>
            {search ? matches.length ? `${currentMatch + 1} of ${matches.length}` : 'No results': null}
          </div>
        </div>
      </div>
    )
  }

  renderChat = () => {
    return (
      <div className='chatContainer card'>

        <div className='header'>
          {this.props.embedded && this.props.onClose ?
            <div className='searchIcon1'>
              <div className='backIcon' onClick={this.props.onClose}>
                <span className='material-icons'>keyboard_backspace</span>
              </div>
            </div>
          : null}

          <div className='headerSearch'>
            {this.renderSearch()}
          </div>
        </div>
        <div
          className={classnames({
            'messages': true,
            'customScrollBar': true,
          })}
        >
          {this.state.messages.map(this.renderChatMessage)}
        </div>
        {this.state.userData.ready ? this.renderMessageInput() : this.renderDataForm()}
      </div>
    )
  }

  render = () => {
    return (
      <div
        className={classnames({
          'chatView': true,
          'embedded': this.props.embedded,
          'chatLists': this.props.chatLists,
          'aggregatedLead': this.props.aggregatedLead,
        })}
      >
        {this.renderChat()}
      </div>
    );
  }
}

export default connect(mapStoreToProps)(withRouter(ChatView));
