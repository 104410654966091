import React from 'react';
import { sendRequest } from '../../helpers/global';

import SelectInput from './SelectInput.js';
import Spinner from './Spinner.js';

import '../../sass/components/input/WysiwygInput.scss';

class ImageUploader extends React.Component {
  constructor(props) {
   super();
   this.onChangeImage = null;

   this.state = {
     show: false,
     imgSrc: null,
     uploadedImages: [],
     showImageLoading: false,
   };
 }

  addImageFromState = () => {
    this.setState({show: false, imgSrc: null});
    this.props.onChangeImage(this.state.imgSrc);
  };

  doCollapse = () => {
    this.setState({show: false, imgSrc: null});
  }

  selectImage = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      this.uploadImage(event.target.files[0]);
    }
  }

  uploadImage = (file) => {
    this.uploadImageCallBack(file)
    .then(({ data }) => {
      this.setState({
        showImageLoading: false,
        imgSrc: data.link || data.url,
      });
    })
  }

  uploadImageCallBack = (file) => {
    this.setState({ showImageLoading: true })
    let uploadedImages = this.state.uploadedImages;

    const imageObject = {
      file: file,
      localSrc: URL.createObjectURL(file),
    }

    uploadedImages.push(imageObject);

    this.setState({ uploadedImages: uploadedImages })

    let wysiwygImage = new File([file], file.name);
    let formData = new FormData();
    formData.append('file', wysiwygImage);
    return new Promise(
      (resolve, reject) => {
        sendRequest({
          method: this.props.imageUploadRequest,
          type: 'POST',
          data: formData,
          success: (data) => {
            resolve({ data: { link: data } });
          },
          error: (data) => {
          }
        });
      }
    );
  }

  showTab = () => {
    this.setState({show: true});
  }

  renderModal = () => {
    const videoFormats = ['mp3', 'mp4', 'webm']

    return (
      <div className='rdw-image-modal'>
        <SelectInput
          properties={{options: this.props.documents, preview: this.props.preview}}
          placeholder='Select from existing'
          onChange={(k, value) =>
            this.setState({
              imgSrc: this.props.documents.find(el => el.id === value).file_url,
            })
          }
        />

        <div onClick={this.fileUploadClick}>
          <div className='rdw-image-modal-upload-option'>
            <label
              htmlFor="file"
              className="rdw-image-modal-upload-option-label"
            >
              {this.state.imgSrc ? (
                videoFormats.includes(this.state.imgSrc.replace(/\?.*$/, '').split('.').pop())
                ? <iframe title={this.state.imgSrc} src={this.state.imgSrc} />
                : <img
                    src={this.state.imgSrc}
                    alt={this.state.imgSrc}
                    className="rdw-image-modal-upload-option-image-preview"
                  />
              ) : (
                this.state.imgSrc ||
                <span>Click to upload</span>
              )}

              {this.state.showImageLoading ? (
                <div className="rdw-image-modal-spinner">
                  <Spinner />
                </div>
              ) : (
                undefined
              )}

            </label>
          </div>
          <input
            type="file"
            id="file"
            onChange={this.selectImage}
            className="rdw-image-modal-upload-option-input"
          />
        </div>

        <span className="rdw-image-modal-btn-section">
          <button
            className="rdw-image-modal-btn"
            onClick={this.addImageFromState}
            disabled={
              !this.state.imgSrc
            }
          >
            <span>Add</span>
          </button>
          <button className="rdw-image-modal-btn" onClick={this.doCollapse}>
            <span>Cancel</span>
          </button>
        </span>

      </div>
    );
  };

  render() {
    const { expanded } = this.props;
    return (
      <div
        aria-haspopup="true"
        aria-expanded={expanded}
        aria-label="rdw-color-picker"
      >
        <div
          className='rdw-option-wrapper'
          onClick={this.showTab}
        >
        <img
          src='/images/imageIcon.svg'
          alt="imageIcon"
        />
        </div>
        {this.state.show ? this.renderModal() : null}
      </div>
    );
  }
}

export default ImageUploader;
