import React from 'react';
import classnames from 'classnames';
import CSSTransition from 'react-transition-group/CSSTransition';
import TextInput from '../input/TextInput.js';
import SelectInput from '../input/SelectInput.js';

import { eventOn, eventOff } from '../../helpers/global';

import '../../sass/components/common/RejectCampaignPopup.scss';

class RejectCampaignPopup extends React.Component {
  constructor(props) {
    super();
    this.state = {
      hidden: !props.show,
      show: false,
      callback: null,
      type: null,
      cancelReason: null,
      selectedReason: null,
      errors: {},
      campaign: {}
    };
  }

  componentDidMount = () => {
    if (this.props.global) {
      eventOn('showRejectCampaign', this.showRejectCampaign);
    }
  }

  componentWillUnmount = () => {
    if (this.props.global) {
      eventOff('showRejectCampaign', this.showRejectCampaign);
    }
  }

  showRejectCampaign = (e) => {
    const { callback = () => {}, campaign, type } = e.detail[0];
    this.setState({
      hidden: false,
      show: true,
      callback,
      campaign,
      type
    });
  }

  onConfirm = () => {
    if (this.state.cancelReason) {
      this.state.callback(this.state.cancelReason);
      this.setState({ show: false, cancelReason: null, selectedReason: null });
    } else {
      this.setState({ errors: { cancelReason: 'Cannot be blank' } });
    }
  }

  updateSelectedReason = (value) => {
     this.setState({
       selectedReason: value,
       cancelReason : value === 'Others' ? null : value
    });
  }

  renderRejectCampaignView = () => {
    const rejectionReasonsData = this.state.campaign && this.state.campaign.rejection_reasons ? this.state.campaign.rejection_reasons : [];
    const campaignType = this.state.type === 'lead' ? 'campaign' : 'charity';
    const userType = this.state.type === 'lead' ? 'Fundraiser' : 'Charity staff';
    let rejectionReasons = rejectionReasonsData.map(reason => ({ id: reason.content, name: reason.content }));
    rejectionReasons.push({ id: 'Others', name: 'Others' });
    return (
      <div className='popupWindow'>
        <div className='closeButton' onClick={() => this.setState({ show: false })}/>
        <div className='contentContainer'>
          <div className='rejectCampaignView'>
            <div className='rejectHeader'>Reason for rejecting {campaignType}</div>
            <div className='rejectMessage'>
              {userType} will get a rejection email and will see this reason on the email
            </div>
            {this.state.type === 'giviki'
              ? null
              : <SelectInput
                  object={this.state.selectedReason}
                  properties={{options: rejectionReasons, placeholder: ''}}
                  onChange={(k, value)  => this.updateSelectedReason(value)}
                />}
            {this.state.selectedReason === 'Others' || this.state.type === 'giviki'
              ? <TextInput
                  placeholder={`Sorry, we had to reject your ${campaignType} because we do not have enough information from you to verify your ${campaignType}.`}
                  properties={{type: 'textarea'}}
                  object={this.state.cancelReason}
                  onChange={(k, value) => this.setState({ cancelReason: value })}
                />
              : null}
            {this.state.errors && this.state.errors['cancelReason'] ?
              <div className='inputError'>{this.state.errors['cancelReason']}</div>
            : null}
          </div>
        </div>
        <div className='popupControls'>
        <div
          onClick={() => this.onConfirm()}
          className='popupControl'
        >Reject and send email to {userType}</div>
        </div>
      </div>
    )
  }

  render = () => {
    const { show } = this.props.global ? this.state : this.props;
    return (
      <div
        className={classnames({
          'rejectCampaignPopup': true,
          'hidden': this.state.hidden,
        })}
      >
        <CSSTransition
          in={show}
          timeout={350}
          classNames='fade'
          onEnter={() => this.setState({ hidden: false })}
          onExited={() => this.setState({ hidden: true })}
        >
          <div className='overlay'>
            {this.renderRejectCampaignView()}
          </div>
        </CSSTransition>
      </div>
    )
  }
}

export default RejectCampaignPopup;
