import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import { withRouter } from 'react-router-dom';

import { sendRequest, triggerEvent } from '../../helpers/global.js';

import SelectInput from './SelectInput.js';

import '../../sass/components/input/PayoutsList.scss';

const STATUSES = [
  { id: 'pending', name: 'Pending' },
  { id: 'rejected', name: 'Rejected' },
  { id: 'approved', name: 'Approved' },
]

const mapStoreToProps = (store) => {
  return {
    isMobile: store.setup.is_mobile,
  }
};

class PayoutsList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount = () => {
  }

  updateItemStatus = (item, status) => {
    let method;
    if (status === 'approved') {
      method = 'approve';
    } else if (status === 'rejected') {
      method = 'reject';
    } else {
      return;
    }
    sendRequest({
      method: `payouts/${item.id}/${method}`,
      type: 'POST',
      success: (data) => {
        triggerEvent('showSnackbar', [{text: 'Payout status updated', type: 'success'}]);
        const index = this.props.object.findIndex(i => i.id === item.id);
        const object = update(this.props.object, {
          [index]: {
            status: {$set: status}
          }
        });
        this.props.onChange(this.props.objectKey, object);
      },
      error: (data) => {
      }
    });
  }

  renderStatusSelect = (item) => {
    return (
      <SelectInput
        object={item.status}
        properties={{options: STATUSES}}
        disabled={item.status !== 'pending'}
        onChange={(k, value) => this.updateItemStatus(item, value)}
      />
    )
  }

  renderItemMobile = (item) => {
    const documents = item.documents || [];
    return (
      <table key={item.id} className={item.status}>
        <tbody>
          <tr>
            <td>Payout #</td>
            <td>{item.id}</td>
          </tr>
          <tr>
            <td>Amount</td>
            <td>{item.amount}</td>
          </tr>
          <tr>
            <td>Status</td>
            <td className='control'>{this.renderStatusSelect(item)}</td>
          </tr>
          <tr>
            <td>Create Date</td>
            <td>{moment.unix(item.created_at).format('MM.DD.YYYY')}</td>
          </tr>
          <tr>
            <td>Payee</td>
            <td>{item.payee}</td>
          </tr>
          <tr>
            <td>Country</td>
            <td>{item.country}</td>
          </tr>
          <tr>
            <td>SWIFT code</td>
            <td>{item.swift_code}</td>
          </tr>
          <tr>
            <td>Bank Account</td>
            <td>{item.account_number}</td>
          </tr>
          <tr>
            <td>Supporting Docs</td>
            <td>
              {documents.map(doc =>
                <a key={doc.id} href={doc.file_url} target='_blank' rel='noopener noreferrer'>{doc.name}</a>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    )
  }

  renderItem = (item) => {
    const documents = item.documents || [];
    return (
      <tr key={item.id} className={item.status}>
        <td>{item.id}</td>
        <td>{item.amount}</td>
        <td className='control'>{this.renderStatusSelect(item)}</td>
        <td>{moment.unix(item.created_at).format('MM.DD.YYYY')}</td>
        <td>{item.payee}</td>
        <td>{item.country}</td>
        <td>{item.swift_code}</td>
        <td>{item.account_number}</td>
        <td>
          {documents.map(doc =>
            <a key={doc.id} href={doc.file_url} target='_blank' rel='noopener noreferrer'>{doc.name}</a>
          )}
        </td>
      </tr>
    )
  }

  render = () => {
    const object = this.props.object || [];
    return (
      <div className='payoutsList'>
        {this.props.isMobile
          ? object.map(this.renderItemMobile)
          : 
          <table>
            <thead>
              <tr>
                <th width='85px'>Payout #</th>
                <th width='85px'>Amount</th>
                <th width='140px'>Status</th>
                <th width='100px'>Create Date</th>
                <th>Payee</th>
                <th>Country</th>
                <th>SWIFT code</th>
                <th>Bank Account</th>
                <th>Supporting Documents</th>
              </tr>
            </thead>
            <tbody>
              {object.map(this.renderItem)}
            </tbody>
          </table>
        }
      </div>
    )
  }
}

export default connect(mapStoreToProps)(withRouter(PayoutsList));
