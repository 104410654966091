import React from 'react';
import classnames from 'classnames';

import TextInput from './TextInput.js';
import CheckboxInput from './CheckboxInput.js';
import ProgressBar from '../common/ProgressBar';

import '../../sass/components/input/TodoList.scss';

export default class TodoList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleChange = (value) => {
    this.props.onChange(this.props.objectKey, value);
  }

  addItem = () => {
    this.handleChange([
      ...this.props.object || [],
      { content: '', checked: false },
    ])
  }

  updateItem = (index, key, value) => {
    const object = this.props.object || [];
    if (index > -1) {
      let updated = object.slice();
      updated.splice(index, 1, {
        ...object[index],
        [key]: value
      });
      this.handleChange(updated);
    }
  }

  deleteItem = (index) => {
    const object = this.props.object || [];
    if (index > -1) {
      let updated = object.slice();
      updated.splice(index, 1);
      this.handleChange(updated);
    }
  }

  renderListItem = (item, index) => {
    const properties = this.props.properties;
    return (
      <div key={index} className='listItem'>
        {properties.disableCheck ? null :
          <CheckboxInput
            object={item.checked}
            onChange={(k, value) => this.updateItem(index, 'checked', value)}
            disabled={properties.aggregated}
            aggregatedLeadView={this.props.aggregatedLeadView}
          />
        }
        <TextInput
          properties={{type: 'text'}}
          object={item.content}
          disabled={properties.disableEdit}
          onChange={(k, value) => this.updateItem(index, 'content', value)}
          aggregatedLeadView={this.props.aggregatedLeadView}
        />
        {properties.disableEdit ? null :
          <div
            className='removeButton'
            onClick={() => this.deleteItem(index)}
          >
            <span className='material-icons'>close</span>
          </div>
        }
      </div>
    )
  }

  render = () => {
    const object = this.props.object || [];
    const checkedCount = object.filter(val => val.checked).length;
    const progress = (checkedCount / object.length) * 100;
    return (
      <div
        className={classnames({
         'todoList': true,
         'aggregatedLeadView': this.props.aggregatedLeadView,
       })}
      >
        {this.props.aggregatedLeadView
          ? <>
              <div className='verifiedValue'>{checkedCount}/{object.length} verified</div>
              <div className='verifiedProgress'>
                <ProgressBar completed={progress} />
              </div>
            </>
          : null}
        <div className='listContainer'>
          {object.map(this.renderListItem)}
        </div>
        {this.props.properties.disableEdit ? null :
          <button
            className='outline'
            onClick={this.addItem}
          >Add</button>
        }
      </div>
    )
  }

}
