import React from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import classnames from 'classnames';
import Storage from '../../helpers/Storage.js';

import { triggerEvent, sendRequest } from '../../helpers/global';

import '../../sass/components/input/SelectInput.scss';

class SelectInput extends React.Component {
  constructor(props) {
    super();
    this.state = {
      options: props.properties.options || [],
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    const properties = this.props.properties;
    const parentObject = this.props.parentObject;
    const additionalParam = properties.additionalParam;
    if (prevProps.properties.options !== this.props.properties.options) {
      this.setState({options: this.props.properties.options});
    }
    if (parentObject && parentObject[additionalParam] !== prevProps.parentObject[additionalParam]) {
      this.loadData();
    }
  }

  handleChange = (value) => {
    const properties = this.props.properties;
    const options = this.state.options;
    const isMulti = properties.type === 'multi-select';
    if (isMulti && properties.limit && value.length > properties.limit) {
      return;
    }
    const extended = properties.type === 'multi-select'
      ? (value || []).map(v => options.find(i => i.id == v)) // eslint-disable-line eqeqeq
      : options.find(i => i.id == value); // eslint-disable-line eqeqeq
    if (this.props.onChange) {
      this.props.onChange(this.props.objectKey, value, extended);
    }
  }

  componentDidMount = () => {
    this.loadData();
  }

  loadData = () => {
    const properties = this.props.properties;
    const data = { role_check: properties.roleCheck };
    if (this.props.parentObject && properties.additionalParam) {
      data[properties.additionalParam] = this.props.parentObject[properties.additionalParam];
    }
    if (properties.request && !properties.async) {
      triggerEvent('addLoad');
      sendRequest({
        type: 'GET',
        method: properties.request,
        data: data,
        success: (data) => {
          triggerEvent('removeLoad');
          this.setState({options: data});
        },
        error: (error) => {
          triggerEvent('removeLoad');
        }
      });
    }
  }

  loadOptions = (inputValue, callback) => {
    const properties = this.props.properties;
    if (properties.request) {
      sendRequest({
        type: 'GET',
        method: properties.request,
        data: {
          name: inputValue
        },
        success: (data) => {
          this.setState({options: data});
          callback(
            data.map(i => ({ value: i.id, label: i.name }))
          );
        },
        error: (error) => {
          callback([]);
        }
      });
    } else {
      callback([]);
    }
  };

  render = () => {
    let object = this.props.object;
    const properties = this.props.properties;
    const preview = properties.preview;
    const isMulti = properties.type === 'multi-select';

    const options = (Storage.getData(properties.storageKey) || this.state.options).map(i => ({
      value: i.id,
      label: preview ? <div><img src={i.thumbnail_url} height="15px" width="15px" alt='selectedImage'/> {i.name} </div> : i.name
    }));
    if (properties.type === 'multi-select' && typeof object === 'string') {
      object = object.split(',');
    }
    const value = isMulti
      ? (object || []).map(v => options.find(i => i.value == v)) // eslint-disable-line eqeqeq
      : options.find(i => i.value == object); // eslint-disable-line eqeqeq
    const selectProps = {
      value: object === undefined ? undefined : preview ? value : value || null,
      onChange: e => {
        if (isMulti) {
          this.handleChange(e ? e.map(i => i.value) : []);
        } else {
          this.handleChange(e ? e.value : null);
        }
      },
      onFocus: () => this.setState({focus: true}),
      onBlur: () => this.setState({focus: false}),
      isMulti: isMulti,
      isDisabled: this.props.disabled,
      className: 'reactSelect',
      classNamePrefix: 'reactSelect',
      isClearable: properties.clearable,
      placeholder: properties.placeholder || 'Select...',
      noOptionsMessage: inputValue => 'No options',
    }
    return (
      <div
        className={classnames({
          'selectInput': true,
          'readOnly': this.props.disabled,
        })}
      >
        {properties.async
          ? <AsyncSelect
            cacheOptions
            defaultOptions
            loadOptions={this.loadOptions}
            {...selectProps}
          />
          : <Select
            options={options}
            {...selectProps}
          />
        }
      </div>
    )
  }
}

export default SelectInput;
