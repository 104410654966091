import moment from 'moment';

export default {
  section: "Notifications",
  config: {
    method: 'statistics/',
    title: 'Statistics',
    search: {
      from: {
        title: 'From',
        type: 'date',
        default: moment().unix()
      },
      to: {
        title: 'To',
        type: 'date',
        default: moment().unix()
      },
      charity_id: {
        title: 'Adopting Charity',
        type: 'select',
        request: 'collections/charities/',
        clearable: true
      },
      country_id: {
        title: 'Giviki Country',
        type: 'select',
        request: 'collections/countries/',
        clearable: true
      }
    }
  },
  properties: {
    summary: {
      type: "list",
      title: "Summary info",
      options: {
        distribution: []
      }
    }
  }
};
