import React from 'react';
import { withRouter } from 'react-router-dom';
import { sendRequest } from '../helpers/global.js';
import MarketingTodoElem from './MarketingTodoElem.js'

import '../sass/components/MarketingTodoList.scss';

class MarketingTodoList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      marketingTodos: [],
    };
  }

  componentDidMount = () => {
    sendRequest({
      method: `marketing_todos`,
      type: 'GET',
      success: (data) => {
        this.setState({marketingTodos: data.objects.reverse()});
      },
      error: (data) => {}
    });
  }

  handleChange = (value) => {
    this.props.onChange(this.props.objectKey, value);
  }

  addItem = () => {
    const { marketingTodos } = this.state
    this.setState({marketingTodos: [...marketingTodos, {description: '', newItem: true}]});
  }

  render = () => {
    const { marketingTodos } = this.state
    return (
      <div className='marketingTodoList'>
        <div className='card'>
          <div className='todoList'>
            {marketingTodos.map((todo, index) => (
              <MarketingTodoElem
                key={index}
                object={todo}
                onChange={(updated) => {
                  this.setState({marketingTodos: marketingTodos.map((t, i) => i === index ? updated : t)})
                }}
                onDelete={() => {
                  this.setState({marketingTodos: marketingTodos.filter((t, i) => i !== index)})
                }}
              />))}
            <button className='outline' onClick={this.addItem}>Add</button>
          </div>
        </div>
      </div>
    );
  }
}

export default (withRouter(MarketingTodoList))
