import React from 'react';

import '../../sass/components/common/ProgressBar.scss';

class ProgressBar extends React.PureComponent {

  render = () => {
    const { completed } = this.props;
    return (
      <div className='progressBar'>
        <div className='filler' style={{width: `${completed}%`}} />
      </div>
    );
  }

}

export default ProgressBar;
