export default {
  section: "Personal Team",
  config: {
    method: "documents/",
    title: "Search Documents",
    objectName: "Document",
  },
  properties: {
    id: {
      default: false,
      type: "text",
      title: "#",
      preset: true,
      width: "60px",
    },
    created_at_formatted: {
      type: "datetime",
      title: "Creation time",
      width: "150px",
    },
    lead_list: {
      type: "page-link",
      page: "/leads/:id",
      title: "Lead",
      width: "auto",
    },
    file_list: {
      type: "page-link",
      external: true,
      title: "File",
      width: "auto",
    },
    lead_document_type: {
      type: "select",
      title: "Folder",
      options: [
        { id: 'medical', name: 'Medical' },
        { id: 'financial', name: 'Financial' },
        { id: 'media', name: 'Media' },
        { id: 'misc', name: 'Misc' },
      ],
      width: "190px",
    },

  }
};
